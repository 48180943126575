<!--
 * @Author: mayu
 * @Date: 2022 1-18
 * @LastEditTime: 2024-03-19 14:22:34
 * @LastEditors: mayu.ex 15163353598@163.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\unreadMessage.vue
-->

<template>
  <div class="boxInfo clearfix" v-if="userInfo">
    <div class="bigBox">
      <div v-show="this.$store.state.user.userInfo.account.type == 'main' || (this.$store.state.user.userInfo.account.type !== 'main' && showActiveBox)"
           class="everyOne" @click="gotoDetail('1')">
        <p>待审核订单</p>
        <p>{{ this.unreadMsgObj.ddsh }}</p>
      </div>
      <div v-show="this.$store.state.user.userInfo.account.type == 'main' || (this.$store.state.user.userInfo.account.type !== 'main' && showActiveBox)"
           class="everyOne" @click="gotoDetail('2')">
        <p>待出库订单</p>
        <p>{{ this.unreadMsgObj.xsck }}</p>
      </div>
      <div v-show="this.$store.state.user.userInfo.account.type == 'main' || (this.$store.state.user.userInfo.account.type !== 'main' && showActiveDBox)"
           class="everyOne" @click="gotoDetail('3')">
        <p>待入库订单</p>
        <p>{{ this.unreadMsgObj.cgrk }}</p>
      </div>
      <div v-show="this.$store.state.user.userInfo.account.type == 'main'  || (this.$store.state.user.userInfo.account.type !== 'main' && showCk)" class="everyOne"
           @click="gotoDetail('4')">
        <p>退货待出库</p>
        <p>{{ this.unreadMsgObj.return_wait_outbound }}</p>
      </div>
      <div v-show="this.$store.state.user.userInfo.account.type == 'main'  || (this.$store.state.user.userInfo.account.type !== 'main' && showRR)" class="everyOne"
           @click="gotoDetail('5')">
        <p>退货待入库</p>
        <p>{{ this.unreadMsgObj.return_wait_inbound }}</p>
      </div>
      <div v-show="this.$store.state.user.userInfo.account.type == 'main' || (this.$store.state.user.userInfo.account.type !== 'main' && showWenQ)" class="everyOne"
           @click="gotoDetail('6')">
        <p>待作答问卷</p>
        <p>{{ unreadMsgObj.questionNum }}</p>
      </div>
      <div class="everyOne" @click="gotoDetail('7')">
        <p>未读公告</p>
        <p>{{ unreadMsgObj.noticeNum }}</p>
      </div>
      <div class="everyOne" @click="gotoDetail('8')">
        <p>待办</p>
        <p>{{ unreadMsgObj.taskNum }}</p>
      </div>
      <div class="everyOne" @click="gotoDetail('9')">
        <p>未读通知</p>
        <p>{{ unreadMsgObj.msgNum }}</p>
      </div>
    </div>
    <div class="icon">
      待处理
    </div>
  </div>
</template>

<script>
import Util from "@/utils/utils";
import pie from "./Pie";
import {
  homepageNormalSalesReport,
  homepageSalesReport,
  reportCustSales,
  homepageInventoryReport, menuList
} from "@/utils/api.js";
import moment from "moment";
import { getnotRead, getUnTreatNum } from "@/views/home/api";


moment.locale("zh-cn");
export default {
  name: "unreadMessage",
  data() {
    return {
      MenuList: [],
      showActiveBox: false,
      showActiveDBox:false,
      showRR: false,
      showCk: false,
      showWenQ: false,
      unreadMsgObj: {//未读数据
        msgNum: 0,
        noticeNum: 0,
        questionNum: 0,
        taskNum: 0,
        cgrk: 0,
        ddsh: 0,
        xsck: 0,
        return_wait_inbound: 0,
        return_wait_outbound: 0
      }
    };
  },
  props: {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    }
  },
  watch: {},
  mounted() {
    if (this.userInfo) {
      this.getGetUnTreatNum();
      this.getDmsInfo();
    }
    //获取导航
    if (this.userInfo) {
      this.getMenuList()
    }
  },
  methods: {
    getMenuList() {
      //调用接口
      let params = { pageNo: 1, pageSize: 20, loginPlant: "WEBNEW" };
      menuList(params)
        .then(res => {
          if (res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            this.MenuList = res.data.list;
            if(this.MenuList.length > 0) {
              this.showRR = Util.isHas("退货入库审核", this.MenuList);
              this.showActiveBox = Util.isHas("销售订单（审核出库）", this.MenuList);
              this.showActiveDBox=Util.isHas("海信订单汇总（入库）", this.MenuList);
              this.showCk = Util.isHas("退货明细", this.MenuList);
              this.showWenQ = Util.isHas("调研问卷", this.MenuList);
            }

          }
        })
        .catch(error => {
          console.log(error);
        });
    },


    // 跳转页面
    gotoDetail(num) {
      // 待审核订单
      if (num == 1) {
        this.$router.push({
          path: "/channelOrder/orderList",
          query: {
            filter: "salesOrderStatus:" + "po_submitted",
            onlyUserParam: true
          }

        });
      }
      // 待出库订单
      if (num == 2) {
        this.$router.push({
          path: "/channelOrder/orderList",
          query: {
            filter: "salesOrderStatus:" + "examined@@so_submitted@@draft",
            onlyUserParam: true
          }
        });
      }
      // 待入库订单
      if (num == 3) {
        this.$router.push({
          path: "/cispurchaseProgress",
          query: {
            sorce: "index",
            filter: "orderDate:" + "",
            onlyUserParam: true
          }
        });
      }
      // 退货待出库
      if (num == 4) {
        this.$router.push({
          path: "/channelReturns/distributorReturnList"
        });
      }
      // 退货待入库
      if (num == 5) {
        this.$router.push({
          // path: "/channelReturns/agentWarehousingList"
          path:"salesReturn/returnReceiptList",
          query: {
            // 默认多个筛选用@@拼接
            filter: "soListReturnStatus:" + "ready-shipped@@partially-operate",
            onlyUserParam: true
          }
        });
      }
      // 待作答问卷
      if (num == 6) {
        this.$router.push({
          path: "/questionnaire/toBeAswered"
        });
      }
      // 未读公告
      if (num == 7) {
        this.$router.push({
          path: "/center/announcement",
          query: {
            filter: "status:" + "0",
            onlyUserParam: true
          }
        });
      }
      // 待办
      if (num == 8) {
        this.$router.push({
          path: "/center/todoList"
        });
      }
      // 未读通知
      if (num == 9) {
        this.$router.push({
          path: "/center/notice"
        });
      }

    },
    //dms未读消息
    getDmsInfo() {

      let data = {
        userAccount: this.userInfo.account.account,
        module: "cgrk,ddsh,xsck,return_wait_outbound,return_wait_inbound"

      };
      getnotRead(data).then(res => {
        let reaData = JSON.parse(res.data);
        if (reaData.code == 0) {
          this.unreadMsgObj.cgrk = reaData.data.cgrk || 0,
            this.unreadMsgObj.ddsh = reaData.data.ddsh || 0,
            this.unreadMsgObj.xsck = reaData.data.xsck || 0,
            this.unreadMsgObj.return_wait_inbound = reaData.data.return_wait_inbound || 0,
            this.unreadMsgObj.return_wait_outbound = reaData.data.return_wait_outbound || 0;
        } else {
          this.unreadMsgObj.cgrk = 0,
            this.unreadMsgObj.ddsh = 0,
            this.unreadMsgObj.xsck = 0,
            this.unreadMsgObj.return_wait_inbound = 0,
            this.unreadMsgObj.return_wait_outbound = 0;
        }
      });
    },
    // cis未读消息
    getGetUnTreatNum() {
      let params = { pageNo: 1, pageSize: 20 };
      getUnTreatNum(params)
        .then((res) => {
          this.unreadMsgObj.msgNum = res.data.msgNum;
          this.unreadMsgObj.noticeNum = res.data.noticeNum;
          this.unreadMsgObj.questionNum = res.data.questionNum;
          this.unreadMsgObj.taskNum = res.data.taskNum;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>
<style lang="less" scoped>
.boxInfo {
  width: 1188px;
  height: 86px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;

  .bigBox {
    margin-left: 34px;
    display: flex;

    .everyOne {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 54px;

      p:first-child {
        font-size: 14px;
        color: #262626;
      }

      p:last-child {
        font-weight: 600;
        color: #F34F3E;
        margin-top: 4px;

      }
    }

    .everyOne:hover {
      background: #FFF4F4;
      border-radius: 6px;
      cursor: pointer;
    }
  }

  .icon {
    width: 62px;
    height: 22px;
    background: #FFF4F4;
    border-radius: 0px 10px 0px 10px;
    position: absolute;
    top: 0;
    right: 0;
    color: #D9161C;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
