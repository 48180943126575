<!--
 * @Author: sqk
 * @Date: 2020-08-28 15:56:22
 * @LastEditTime: 2022-01-05 17:24:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\InfoItem.vue
-->

<template>
  <div class="infoItemSwiper swiper-container" ref="mySwiper">
    <div class="swiper-wrapper">
      <div
        class="swiper-slide"
        v-for="(item, index) in data"
        :key="index"
        :data-id="item.id"
        :data-typeCode="item.typeCode"
        :data-sourceId="item.sourceId"
        :data-type="item.type"
        :data-typeName="item.typeName"
        
        :class="item.status == 1 ? 'active' : ''"
      >
        <i
          class="title"
          v-show="type == 1 ?item.plate_name : item.title"
        >{{type == 1 ?item.plate_name : item.title}}</i>
        <span
          :title="type == 1 ?item.title : item.content"
          class="info-txt"
        >{{ type == 1 ?item.title : item.content }}</span>
        <b class="info-date">
          {{
          $util.dateFmt(
          new Date(item.createdDate || item.createdate),
          "yyyy-MM-dd"
          )
          }}
        </b>
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <!-- <div class="swiper-pagination"></div> -->
    <!--箭头。如果放置在swiper-container外面，需要自定义样式。-->
    <!-- <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>-->
  </div>
</template>

<script>
export default {
  name: "infoItem",
  data() {
    return {
      mySwiper: "",
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    type: {
      type: Number, //待办2 通知3 公告1
      default: 0
    }
  },
  watch: {
    data: function() {
      if (this.mySwiper) {
        // this.mySwiper.destroy(false);
        this.mySwiper.destroy(false);
      }

      this.initSwiper();
    }
  },
  //定义swiper对象
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    //向上滚动轮播初始化
    initSwiper() {
      let this_ = this;
      this.$nextTick(() => {
        this.mySwiper = new this.$Swiper(this.$refs.mySwiper, {
          on: {
            // reachEnd: () => {
            //   //轮播结束
            //   // alert(this_.mySwiper.activeIndex);
            //   // this_.$emit("infoChange", this.type);
            // },
            slideChangeTransitionEnd() {
              if (this_.mySwiper.activeIndex == this_.data.length) {
                //判断轮播到最后一个 回调
                this_.$emit("infoChange", this_.type, this_.data.length);
              }
            },
            slideChange: function() {
            },
            click: () => {
              // let ckItem =this_.data[mySwiper.clickedSlide.getAttribute("data-idx")];
              let id = this_.mySwiper.clickedSlide.getAttribute("data-id");
              let sourceId = this_.mySwiper.clickedSlide.getAttribute(
                "data-sourceId"
              );
              let infoType = this_.mySwiper.clickedSlide.getAttribute(
                "data-type"
              );
               let typeName = this_.mySwiper.clickedSlide.getAttribute(
                "data-typeName"
              );
              let typeCode =  this_.mySwiper.clickedSlide.getAttribute(
                "data-typeCode"
              );
              
              this_.$emit("click", this.type, id, sourceId, infoType,typeName,typeCode);
              // alert("你点了Swiper");
            }
          },
          autoplay: {
            //自动轮播
            delay: 1500,
            disableOnInteraction: false
            // autoplayDisableOnInteraction : false,
            // stopOnLastSlide:true
          },
          loop: this.data.length > 4 ? true : false, // 循环模式选项
          // loop: false, // 循环模式选项
          direction: "vertical",
          onlyExternal: true,
          // noSwiping : true,
          // simulateTouch: false,//鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
          slidesPerView: 4,
          spaceBetween: 4,
          // preventLinksPropagation:false,//防止冒泡。
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true //修改swiper的父元素时，自动初始化swiper
          //         // 如果需要分页器
          // pagination: {
          //   el: '.swiper-pagination',
          // },
          // navigation: {
          //       nextEl: '.swiper-button-next',
          //       prevEl: '.swiper-button-prev',
          //     },
        });
        this_.mySwiper.el.onmouseover = function() {
          //鼠标放上暂停轮播
          this_.mySwiper.autoplay.stop();
          //判断轮播到最后一个 回调
          this_.$emit("infoChangeHover", false);
        };
        this_.mySwiper.el.onmouseleave = function() {
          this_.mySwiper.autoplay.start();
          this_.$emit("infoChangeHover", true);
          if(this_.data.length < 5){
            this_.$emit("infoChange", this_.type, this_.data.length, true);
          }
        };
      });
    }
  }
};
</script>
<style lang="less" scoped>
.infoItemSwiper {
  width: calc(100% - 48px);
  height: 102px;
  position: relative;
  margin: 0px 24px 0 24px;
  .swiper-wrapper {
    // padding: 55px 17px 0 15px;
    .swiper-slide {
      width: 100%;
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      color: #777;
      cursor: pointer;
      display: flex;
      align-items: center;
      &.active {
        color: #aaa;
      }
      .title {
        font-size: 12px;
        // font-family: MicrosoftYaHei;
        color: #d9161c;
        line-height: 18px;
        height: 18px;
        background: #ffefef;
        text-align: center;
        padding: 0 5px;
        float: left;
        margin-right: 8px;
      }
      .info-txt {
        // display: flex;
        flex: 1;
        padding-right: 16px;
        // width: 520px;
        float: left;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.2;
      }
      .info-date {
        text-align: right;
        float: right;
        font-weight: 400;
        margin-left: 16px;
      }
      &:hover {
        color: #00aaa6;
        // text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      }
    }
    li + li {
      margin-top: 12px;
    }

    li:first-child {
      // color: rgba(38, 38, 38, 1);
    }
  }
  &.swiper-container {
    // width: 100%;
    // height: 100%;
    .swiper-slide {
      // height: 28px;
      // width: 50%;
    }
  }
}
</style>
