<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2020-11-16 18:26:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div ref="pie" style="width:100%;height:100%"></div>
</template>

<script>
// import util from "./util";
export default {
  name: "pie",
  data() {
    return {};
  },
  props: ['data'],
  watch: {
    data: function() {
      this.initEcharts();
    }
  },
  mounted() {
    this.initEcharts();
    // window.addEventListener("resize", this.initEcharts);
  },
  methods: {
    // 初始化echarts图表
    initEcharts() {
      let pie = this.$echarts.init(this.$refs.pie);
      let data = this.data.series||[];
      var timeOut = setTimeout(() => {
        let option = this.chartInt(data);
        pie.setOption(option);
        // pie.resize();
        clearTimeout(timeOut);
      }, 1);
    },
    // 配置options
    chartInt(data) {
      let option = {
        title: [
          {
            text: this.data.total,
            textStyle: {
              color: "#333333",
              fontSize: 18,
              fontWeight:400
            },
            textAlign:"center",
            x: "80",
            y: "30%"
          },
          {
            text: "库存总数",
            textStyle: {
              color: "#777",
              fontSize: 12,
              fontWeight:400
            },
            textAlign:"center",
            x: "80",
            y: "48%"
          },

        ],
        // color: [   //按顺序设置渐变颜色
        //   {
        //     type: 'linear',
        //     x: 0,
        //     y: 0,
        //     x2: 0,
        //     y2: 1,
        //     colorStops: [{
        //       offset: 0, color: '#00ACFF'
        //     }, {
        //       offset: 1, color: '#0074FF'
        //     }],
        //     global: false // 缺省为 false
        //   },
        //   {
        //     type: 'linear',
        //     x: 0,
        //     y: 0,
        //     x2: 0,
        //     y2: 1,
        //     colorStops: [{
        //       offset: 0, color: '#B262FF'
        //     }, {
        //       offset: 1, color: '#7B33FF'
        //     }],
        //     global: false
        //   },
        //   {
        //     type: 'linear',
        //     x: 0,
        //     y: 0,
        //     x2: 0,
        //     y2: 1,
        //     colorStops: [{
        //       offset: 0, color: '#DCFEFB'
        //     }, {
        //       offset: 1, color: '#B7FCF6'
        //     }],
        //     global: false
        //   },
        //   {
        //     type: 'linear',
        //     x: 0,
        //     y: 0,
        //     x2: 0,
        //     y2: 1,
        //     colorStops: [{
        //       offset: 0, color: '#B5AEFF'
        //     }, {
        //       offset: 1, color: '#7E76FF'
        //     }],
        //     global: false
        //   },
        //   {
        //     type: 'linear',
        //     x: 0,
        //     y: 0,
        //     x2: 0,
        //     y2: 1,
        //     colorStops: [{
        //       offset: 0, color: '#ABF1DA'
        //     }, {
        //       offset: 1, color: '#73DEB3'
        //     }],
        //     global: false
        //   }
        // ],
        legend: {
          icon: "circle",
          // left: "50%",
          y: "center", //延Y轴居中
          // x: '10%', //居右显示
          orient: "vertical",
          color: "#ffffff",
          right: 0, //右边距0
          // top: this.$util.setFont(0),
          // icon: "pie",
          itemWidth: 8,  // 设置宽度
          itemHeight: 8, // 设置高度
          // itemGap: this.$util.setFont(12), // 设置间距
          itemGap: 10, // 设置间距
          // data: data.legendData,
          formatter: function(name) {
            var target;
            for (let i = 0, l = data.length; i < l; i++) {
              if (data[i].name == name) {
                target = data[i].value;
              }
            }
            var str = "{a|" + name + "}{b|" + (target?target:0) + "}";
            return str;
          },
          textStyle: {
            rich: {
              a: {
                color: "#777",
                fontSize: 12,
                width:58,
                padding: [0, 20, 0, 2] //间距
              },
              b: {
                color: "#777",
                fontSize: 12,

              }
            }
          }
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        textStyle: {
          fontFamily: "DIGIB"
        },
        label: {
          show: true,
          position: "center"
        },
        series: [
          {
            name: "库存总数",
            type: "pie",
            radius: ["62%", "90%"],
            center: ["25%", "50%"],
            // minAngle: 30,
            clockWise: false,
            // startAngle:210, //起始角度
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: false
              }
            },

            labelLine: {
              show: false
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              },
              normal: {
                color: function(params) {
                  //自定义颜色
                  // var colorList = ["#5AD8A6", "#5B8FF9", "#5D7092", "#F6BD16", "#E8684A", "#6DC8EC"];
                  if(params.data&&params.data.color){
                    return params.data.color;
                  }else{
                    return '';
                  }
                }
              }
            },
            data: data
          }
        ]
      };
      return option;
    }
  }
};
</script>

