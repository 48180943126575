<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2021-06-08 09:18:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div class="zone-box">
      <h1>{{data.title}}</h1>
      <!-- <router-link
        :to="{ path: data.path === '' ? '/' : userInfo ? data.path : '/login'}"
      > -->
       <router-link
        :to="path" 
      >
        <div class="img-box">
            <img :src="data.url">
            <!-- <img src="@/assets/home/zone-bg.png" alt /> -->
        </div>
      </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import util from "./util";
export default {
  name: "zone-box",
  data() {
    return {
      zoneFlag:false
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    },
    ...mapState({
      //处理后的筛选数据list
      menuList: state => state.user.menuList,
    }),
    path(){
      let path = '';
      if(this.userInfo){
        for(let i = 0;i< this.menuList.length;i++) {
          if(this.menuList[i].sourceName === '商品分类') {
            path = this.data.path ? this.data.path : '/';
          }
        }
      }else{
        path = '/login';
      }
      return path
    }
  },
  watch: {
    data(){
    }
  },
  mounted() {
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.zone-box {
    width: 1188px;
    margin: 40px auto;
    h1{
        height: 32px;
        font-size: 24px;
        // font-family: MicrosoftYaHei;
        color: #262626;
        line-height: 32px;
        text-align: left;
    }
    .img-box{
        margin-top:18px;
        width: 100%;
        height: 500px;
        border-radius: 10px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
            transition: all .5s;
        }
        &:hover{
          img{
            transform: scale(1.06);
          }
        }
    }
}
</style>

