/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2021-01-14 14:47:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
  stepIconobj: {
    minDate: "2020-12-07",
    auditedOpinion: null,
    orderStatus: "待排发货计划",
    orderHeader: {
      payType: "暂无",
      orderData: 1607330811658,
      orderCode: "BHO2000039619",
      orderStatus: "待排发货计划",
      orderStatusCode: "UNCHKED",
      orderSourceId: 8402,
      auditedOpinion: null,
      orderAmt: 1000,
      isFenXiao: "N",
      agentName: "",
      agentCode: "",
      agentAuditor: null,
      agentAuditedDate: "",
      agentAuditedOpinion: null,
      agentCheck: null,
      isAdvanceMoney: null,
      distributionBuy: 0,
      customerName: "青岛宇恒电器有限公司",
      customerCode: "2011383",
      rejectReson: null,
      weekName: "N+2(2020/12/21-2020/12/27)",
      orgId: 1047,
      orgCode: "6734",
      orgName: "冰箱青岛",
      orderType: "应急订单",
      orderSource: "后台下单",
      orderTypeCode: "common",
      orderTypeId: 8301,
      fullName: "青岛宇恒电器有限公司",
      deliveryType: "配送",
      deliveryTypeCode: "02",
      deliveryTypeId: 502001,
      address: "山东省 青岛市 李沧区 海信大厦13",
      contact: "海哥133",
      contactPhone: "15684105331",
      address2: "山东省 青岛市 李沧区 振华路街道办事处 海信大厦13",
      purchaseCode: null,
      expireDate: "2021-01-01 23:59:59",
      isRebate: "Y",
      showYq: true,
      canCancel: "N",
      orderAdvanceAmt: 0,
    },
    erpList: [],
    isAllowAdvancDeliver: "0",
    orderLines: [
      {
        id: 15555642613,
        itemCode: "10",
        b2bSalesOrderCode: "BHO2000039619",
        sapOrderCode: null,
        productId: 3466478,
        zzprdmodel: "BCD-628WTET/Q流光金",
        color: "流光金",
        matkx: null,
        batch: "H",
        priceGroupName: "常规机",
        priceGroupId: null,
        priceId: null,
        makeUpTypeId: null,
        makeUpType: null,
        makeUpTypeCode: null,
        rebate: 0,
        rebates: null,
        basePrice: 100,
        fixedDiscount: 0,
        advancePrice: null,
        waitPrice: null,
        rewardPrice: null,
        fixedDiscountId: null,
        discount: 0,
        deduct: 0,
        policyCode: null,
        policyId: null,
        policyDeduct: null,
        policyDiscount: null,
        policyBillPrice: null,
        billPrice: 100,
        qty: 10,
        erpQty: 0,
        cancelQty: 0,
        hqPickQty: 0,
        hqHoldQty: 0,
        hqWaitQty: 0,
        branchPickQty: 0,
        branchHoldQty: 0,
        branchWaitQty: 10,
        reverseCode: null,
        totalMoney: null,
        customerName: "青岛宇恒电器有限公司",
        expireDate: null,
        creator: "chenweimin1",
        createdDate: "2020-12-07 16:46:51",
        status: "待审核",
        loadVolume: 1.532,
        engineerDetailId: null,
        signQty: null,
        noCheckQty: null,
        waitSend: null,
        waitCheckQty: null,
        planProductQty: null,
        rejectQty: null,
        timeOutQty: 0,
        matklId: null,
        promiseUnCommit: null,
        img: "1300101/3466478/180-180/3466478_1.jpg",
        advancePayAmt: null,
        matnr: null,
        brand: "海信",
        policyType: null,
        invoicePrice: null,
        limitedQuantityAvaiable: null,
        applyQuantity: null,
        quantityPurchased: null,
        externId: null,
        externNo: null,
        externStatus: null,
        remark: null,
        externNo2: null,
        setProductId: null,
        deliverCode: null,
        deliverFinishQty: null,
        deiverItemDtoList: null,
        matklCode: "1300101",
        branchId: 1047,
        b2bName: "BCD-628WTET/Q流光金",
        policyLatitude: null,
        defaultImg: "/assets/new/images/product/1300101.jpg",
        orgCode: "6734",
        sapCode: null,
        sapPosnr: null,
        purchaseType: 3,
        cancelDate: null,
        cancelAccount: null,
        cancelReason: null,
        cancelStatus: null,
        cancelStatusName: null,
        nwFlag: null,
        canCancel: "N",
        statusCode: "UNCHKED",
        branchCheckDate: null,
        epOrderCode: null,
        status2: "待排发货计划",
      },
    ],
    nowStatuses: {},
    expireDate: "2021-01-01",
    maxDate: "2020-12-21",
    statusInfos: [
      {
        status: "UNCHKED",
        id: null,
        date: "2020-12-07",
        time: null,
        type: null,
      },
    ],
    logisticsStatus: {},
    showYq: true,
    sapOrderItems: {},
  },

  tcolumns: [
    {
      title: "采购订单编码",
      dataIndex: "orgCode",
      key: "orgCode",
      sorter: true,
      scopedSlots: { customRender: "jump" },
      ellipsis: true,
      // customCell: () => {
      //   return {
      //     style: {
      //       'max-width': '50px',
      //     },
      //   };
      // },
    },
    {
      title: "订单创建日期",
      dataIndex: "orgName",
      key: "orgName",
      sorter: true,
      scopedSlots: { customRender: "jump" },
      ellipsis: true,
      // customCell: (aaa,bbb,ccc) => {
      //   console.log(456464897)
      //   console.log(aaa)
      //   console.log(bbb)
      //   console.log(ccc)
      //   return {
      //     style: {
      //       'width': '300px',
      //     },
      //   };
      // },
      // customHeaderCell: (aaa,bbb) => {
      //   console.log(456464898)
      //   console.log(aaa)
      //   console.log(bbb)
      //   return {
      //     style: {
      //       'width': '300px',
      //     },
      //   };
      // },
    },
    {
      title: "下单商家编码",
      dataIndex: "userCode",
      key: "userCode",
      sorter: true,
      ellipsis: true,
      // width: "auto",
      //       customCell: (aaa,bbb,ccc) => {
      //   console.log(456464897)
      //   console.log(aaa)
      //   console.log(bbb)
      //   console.log(ccc)
      //   return {
      //     style: {
      //       'width': '300px',
      //     },
      //   };
      // },
      // customHeaderCell: (aaa,bbb) => {
      //   console.log(456464898)
      //   console.log(aaa)
      //   console.log(bbb)
      //   return {
      //     style: {
      //       'width': '300px',
      //     },
      //   };
      // },
    },
    {
      title: "下单商家名称",
      dataIndex: "userName",
      key: "userName",
      ellipsis: true,
      // width: 300,
    },
    {
      title: "订单类型",
      dataIndex: "openDate",
      key: "openDate",
      ellipsis: true,
      // width: 100,
      // editoptions: {value: {'1': '提报人口径', '2': '门店汇总口径'}}
    },
    {
      title: "订单状态",
      dataIndex: "inTime",
      key: "inTime",
      ellipsis: true,
      // width: 200,
    },
    {
      title: "供应商",
      dataIndex: "outTime",
      key: "outTime",
      ellipsis: true,
      // width: 100,
    },
    {
      title: "物料组",
      dataIndex: "inPosition",
      key: "inPosition",
      ellipsis: true,
      // width: 160,
    },
    {
      title: "型号",
      dataIndex: "outPosition",
      key: "outPosition",
      ellipsis: true,
      // width: 160,
    },
    {
      title: "下单数量",
      dataIndex: "inMem",
      key: "inMem",
      ellipsis: true,
      // width: 100,
    },

    {
      title: "金额",
      dataIndex: "outMem",
      key: "outMem",
      ellipsis: true,
      // width: 100,
    },
    // {
    //   title: "按钮",
    //   key: "btnGroup",
    //   scopedSlots: { customRender: "btnGroup" },
    //   width: 160,
    // },
  ],

  tcolumns2: [
    {
      title: "采购订单编码",
      dataIndex: "orgCode",
      key: "orgCode",
      sorter: true,
      scopedSlots: { customRender: "jump" },
      width: 150,
      ellipsis: true,
    },
    {
      title: "订单创建日期",
      dataIndex: "orgName",
      key: "orgName",
      sorter: true,
      scopedSlots: { customRender: "jump" },
      width: 200,
    },
    {
      title: "下单商家编码",
      dataIndex: "userCode",
      key: "userCode",
      sorter: true,
      width: 150,
      ellipsis: true,
    },
    {
      title: "下单商家名称",
      dataIndex: "userName",
      key: "userName",
      ellipsis: true,
      width: 100,
    },
    {
      title: "订单类型",
      dataIndex: "openDate",
      key: "openDate",
      ellipsis: true,
      width: 100,
      // editoptions: {value: {'1': '提报人口径', '2': '门店汇总口径'}}
    },
    {
      title: "订单状态",
      dataIndex: "inTime",
      key: "inTime",
      ellipsis: true,
      width: 200,
    },
    {
      title: "供应商",
      dataIndex: "outTime",
      key: "outTime",
      ellipsis: true,
      width: 100,
    },
    {
      title: "物料组",
      dataIndex: "inPosition",
      key: "inPosition",
      ellipsis: true,
      width: 160,
    },
    {
      title: "型号",
      dataIndex: "outPosition",
      key: "outPosition",
      ellipsis: true,
      width: 160,
    },
    {
      title: "下单数量",
      dataIndex: "inMem",
      key: "inMem",
      ellipsis: true,
      width: 100,
    },

    {
      title: "金额",
      dataIndex: "outMem",
      key: "outMem",
      ellipsis: true,
      width: 100,
    },
    {
      title: "金额",
      dataIndex: "aa",
      key: "aa",
      ellipsis: true,
      width: 100,
    },
    {
      title: "政府编码",
      dataIndex: "bb",
      key: "bb",
      ellipsis: true,
      width: 100,
    },
    {
      title: "要求",
      dataIndex: "cc",
      key: "cc",
      ellipsis: true,
      width: 100,
    },
    {
      title: "收货地址",
      dataIndex: "dd",
      key: "dd",
      ellipsis: true,
      width: 100,
    },
  ],

  tableData: [
    {
      key: "1",
      orgCode: "111",
      orgName: "2020-08-14 12:33:24",
      userCode:
        "订单编码1订单编码1订单编码1订单编码1订单编码1订单编码1订单编码1订单编码1订单编码1订单编码1",
      inTime: "我就五个字测一下",
      userName: null,
      openDate: null,
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
      utMem: "我就五个字测一下",
      inMem: "我就五个字测一下",
      outPosition: "我就五个字测一下",
      inPosition: "我就测一下",
      outTime: "我就五个字测一下",
    },
    {
      key: "2",
      orgCode: "222",
      orgName: "2020-08-14 12:33:25",
      userCode: "订单编码2",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "3",
      orgCode: "股份青",
      orgName: "2020-08-14 12:33:26",
      userCode: "订单编码3",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "4",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
      // btnGroup:'按钮组'
    },
    {
      key: "5",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
      btnGroup: [
        {
          type: 0,
          title: "编辑",
        },
        {
          type: 1,
          title: "删除",
        },
      ],
    },
    {
      key: "5",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "6",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "7",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "8",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "9",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "10",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "11",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "12",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "13",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "14",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "15",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "16",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "17",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "18",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "19",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "20",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "21",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "22",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "23",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "24",
      orgCode: "股份青岛",
      orgName: "物料组",
      userCode: "订单编码",
      orderType: "订单类型",
      status: "订单状态",
      fkStatus: "付款状态",
      orderAmt: "订单金额",
      waitBalance: "预占用金额",
      weekName: "要求到货周次",
      createdDate: "下单日期",
    },
    {
      key: "25",
      orgCode: "合计",
      outMem: "123456",
    },
  ],
};
