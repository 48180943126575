/*
 * @Author: sqk
 * @Date: 2020-08-13 09:22:01
 * @LastEditTime: 2021-01-08 13:49:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\util.js
 */

export default {
  infoData: {
    ggData: [
      {
        id: "1",
        title: "第一条公告滚动第一条公告滚动第一条公告滚动",
        date: "2020-08-03",
      },
      {
        id: "2",
        title: "公告滚动公告滚动公告滚动公告滚动公告滚动",
        date: "2020-08-03",
      },
      {
        id: "3",
        title: "公告滚动公告滚动公告滚动公告滚动",
        date: "2020-08-03",
      },
      {
        id: "4",
        title: "公告滚动公告滚动公告滚动公告滚动公告滚动",
        date: "2020-08-03",
      },
    ],
    dbData: [
      {
        id: "1",
        title: "第一条待办滚动第一条待办滚动",
        date: "2020-08-03",
      },
      {
        id: "2",
        title: "待办滚动待办滚动待办滚动待办滚动",
        date: "2020-08-03",
      },
      {
        id: "3",
        title: "待办滚动待办滚动待办滚动待办滚动待办滚动",
        date: "2020-08-03",
      },
      {
        id: "4",
        title: "待办滚动待办滚动",
        date: "2020-08-03",
      },
    ],
    tzData: [
      {
        id: "1",
        title: "第一条通知滚动第一条通知滚动",
        date: "2020-08-03",
      },
      {
        id: "2",
        title: "通知滚动通知滚动通知滚动",
        date: "2020-08-03",
      },
      {
        id: "3",
        title: "通知滚动通知滚动通知滚动",
        date: "2020-08-03",
      },
      {
        id: "4",
        title: "通知滚动通知滚动",
        date: "2020-08-03",
      },
    ],
  },
  pieChartData: [
    {
      value: 1000,
      name: "电视",
    },
    {
      value: 700,
      name: "冰箱",
    },
    {
      value: 390,
      name: "冰柜",
    },
    {
      value: 800,
      name: "激光",
    },
    {
      value: 590,
      name: "空调",
    },
    {
      value: 360,
      name: "洗衣机",
    },
  ],
  bannerList: [
    {
      imgName: require("@/assets/home/banner1.png"),
    },
    // {
    //   imgName: require("@/assets/home/banner2.png"),
    // },
    {
      imgName: require("@/assets/home/banner22.png"),
    },
    {
      imgName: require("@/assets/home/banner3.png"),
    },
    // {
    //   imgName: require("@/assets/home/banner4.png"),
    // },
    {
      imgName: require("@/assets/home/banner5.png"),
    },
  ],
  dataList: [
    {
      bannerUrl: require("@/assets/home/dianShi.png"),
      cardList: [
        {
          url: require("@/assets/home/1.jpg"),
          type: "1",
          info1: "80英寸激光/11111111111/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/2.jpg"),
          type: "2",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/3.jpg"),
          type: "3",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/4.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/5.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/6.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/7.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/8.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
      ],
    },
    {
      bannerUrl: require("@/assets/home/dianShi.png"),
      cardList: [
        {
          url: require("@/assets/home/1.jpg"),
          type: "1",
          info1: "80英寸激光/2222222222/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/2.jpg"),
          type: "2",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/3.jpg"),
          type: "3",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/4.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/5.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/6.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/7.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/8.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
      ],
    },
    {
      bannerUrl: require("@/assets/home/dianShi.png"),
      cardList: [
        {
          url: require("@/assets/home/1.jpg"),
          type: "1",
          info1: "80英寸激光/3333333333/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/2.jpg"),
          type: "2",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/3.jpg"),
          type: "3",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/4.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/5.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/6.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/7.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/8.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
      ],
    },
    {
      bannerUrl: require("@/assets/home/dianShi2.png"),
      cardList: [
        {
          url: require("@/assets/home/8.jpg"),
          type: "0",
          info1: "80英寸激光/4444444444/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/6.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/5.jpg"),
          type: "3",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/7.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/1.jpg"),
          type: "1",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/2.jpg"),
          type: "3",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/3.jpg"),
          type: "2",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/4.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
      ],
    },
    {
      bannerUrl: require("@/assets/home/dianShi.png"),
      cardList: [
        {
          url: require("@/assets/home/3.jpg"),
          type: "0",
          info1: "80英寸激光/555555555/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/1.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/5.jpg"),
          type: "3",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/7.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/2.jpg"),
          type: "1",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/8.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/4.jpg"),
          type: "2",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
        {
          url: require("@/assets/home/3.jpg"),
          type: "0",
          info1: "80英寸激光/4K超画质/杜比音效/分辨率高/画质好画质好画质好",
          info2: "4K高清HDR / 真四核64位高性能4K高清HDR / 真四核64位高性能",
          amount: "3999元起",
        },
      ],
    },
  ],

  marketList: {
    totalPages: 3,
    pageSize: 10,
    totalRows: 23,
    list: [
      {
        url: require("@/assets/home/activeNavItem1.png"),
        id: 14170505732,
        activityCode: "CP201910924",
        activityName: "海信洗衣机 双旦礼遇",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "认购",
        startDate: 1601000000000,
        startDateDesc: "2018-01-01 00:00:00",
        endDate: 1612108800000,
        endDateDesc: "2020-10-28 18:22:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            xl:20,
            id: 14170505738,
            activityId: 14170505732,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 9,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP201910924",
            activityName: "copy_995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:22:00",
            endDateT: 1603880520000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "XQG80-U1201FG(F)香槟金",
            productCode: "LED78XT920X3DUC",
            img: "1310101/1001389/180-180/1001389_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "悬浮画框设计、前置",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14170505738,
            activityId: 14170505732,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 9,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109244",
            activityName: "copy_995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:22:00",
            endDateT: 1603880520000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "XPOT78(S)象牙白",
            productCode: "LED78XT920X3DUC",
            img: "1310101/14085859950/650-650/14085859950_2.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "洗的干净 容量大",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14170505738,
            activityId: 14170505732,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 9,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109244",
            activityName: "copy_995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:22:00",
            endDateT: 1603880520000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "P01FG(O)雅致黄",
            productCode: "LED78XT920X3DUC",
            img: "1310101/13967565152/180-180/13967565152_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "静音 能飞",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        url: require("@/assets/home/activeNavItem2.png"),
        id: 14170505699,
        activityCode: "CP2019109243",
        activityName: "液晶电视 年终礼遇",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "认购",
        startDate: 1600000000000,
        startDateDesc: "2018-01-01 00:00:00",
        endDate: 1611763200000,
        endDateDesc: "2020-10-28 18:16:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14170505708,
            activityId: 14170505699,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 58,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109243",
            activityName: "995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:16:00",
            endDateT: 1603880160000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED78XT920X3DUC",
            productCode: "LED78XT920X3DUC",
            img: "1100101/1194442/180-180/1194442_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "全球首款全视野 零框感电视 全面屏设计BM区《3.5mm 蛾眼仿生屏H3画质处理芯片 U+超画质引擎 杜比全景声 剑脊式滤音舱 3+32GB 远近场双语音",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14170505708,
            activityId: 14170505699,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 58,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109243",
            activityName: "995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:16:00",
            endDateT: 1603880160000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED48A41222",
            productCode: "LED78X3DUC",
            img: "1100101/490879524/180-180/490879524_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "4K、曲面、悬浮画框设计、前置音吧、黄金仰角",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            xl:18,
            id: 14170505708,
            activityId: 14170505699,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 58,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109243",
            activityName: "995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:16:00",
            endDateT: 1603880160000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED2089C",
            productCode: "LED78XT920X3DUC",
            img: "1100101/724949374/180-180/724949374_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "U+超画质引擎 杜比全景声 剑脊式滤音舱 3+32GB 远近场双语音",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14170505708,
            activityId: 14170505699,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 58,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109243",
            activityName: "995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:16:00",
            endDateT: 1603880160000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "CP2019109243",
            productCode: "LED78XT920X3DUC",
            img: "1100101/907740235/180-180/907740235_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "全面屏设计BM区 3.5mm 蛾眼仿生屏H3画质处理芯片",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14170505708,
            activityId: 14170505699,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 58,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109243",
            activityName: "995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:16:00",
            endDateT: 1603880160000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "PCD55-AOT",
            productCode: "LED78XT920X3DUC",
            img: "1100101/490879524/180-180/490879524_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "4K、曲面、悬浮画框设计、前置音吧、黄金仰角",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        url: require("@/assets/home/activeNavItem3.png"),
        id: 14170505758,
        activityCode: "CP2019109246",
        activityName: "容声洗衣机 年终大促销",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "认购",
        startDate: 1611037600000,
        startDateDesc: "2018-01-01 00:00:00",
        endDate: 1614937600000,
        endDateDesc: "2020-10-28 18:50:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            xl:20,
            id: 14170505738,
            activityId: 14170505732,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 9,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109246",
            activityName: "copy_995115_测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-10-28 18:22:00",
            endDateT: 1603880520000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "XPOT78(S)象牙白",
            productCode: "LED78XT920X3DUC",
            img: "1310101/14085859950/650-650/14085859950_2.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "洗的干净 容量大",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        url: require("@/assets/home/activeNavItem4.png"),
        id: 796173398,
        activityCode: "CP2019109248",
        activityName: "2.5大一匹 超省电立体空调",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "认购",
        startDate: 1611937600000,
        startDateDesc: "2018-01-01 00:00:00",
        endDate: 1614937600000,
        endDateDesc: "2020-11-10 00:00:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14170505624,
            activityId: 796173398,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019109246",
            activityName: "测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-11-10 00:00:00",
            endDateT: 1604937600000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "KFR-35GW/L",
            productCode: "LED78XT920X3DUC",
            img: "1200301/1000764/180-180/1000764_2.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "会吹风",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14170505624,
            activityId: 796173398,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "123",
            activityName: "测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-11-10 00:00:00",
            endDateT: 1604937600000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "壁挂1.5匹变频空调",
            productCode: "LED78XT920X3DUC",
            img: "1200301/1000754/650-650/1000754_1.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "经典雅致 爱尚生活",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        id: 14173618531,
        activityCode: "CP202070003",
        activityName: "copy_copy_活动10151449",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "认购",
        startDate: 1514736000000,
        startDateDesc: "2018-01-01 00:00:00",
        endDate: 1604937600000,
        endDateDesc: "2020-11-10 00:00:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14173618534,
            activityId: 14173618531,
            productInfoId: 3451028,
            productInfoZzprdmodel: "LED50K300U",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 100.0,
            billPrice: 99.0,
            qty: 10,
            purchaseLimitQty: 2,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: "备注1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202070003",
            activityName: "copy_copy_活动10151449",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-11-10 00:00:00",
            endDateT: 1604937600000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "5555555555555",
            productCode: "LED50K300U",
            img: "1100101/3451028/180-180/3451028_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "红黑",
            status: "2",
            importInfo: "4K、智能、VIDAA3、十四核处理器",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        id: 14173618523,
        activityCode: "CP202070001",
        activityName: "copy_活动10151449",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "认购",
        startDate: 1595661300000,
        startDateDesc: "2020-07-25 15:15:00",
        endDate: 1605161640000,
        endDateDesc: "2020-11-12 14:14:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14173618526,
            activityId: 14173618523,
            productInfoId: 3451028,
            productInfoZzprdmodel: "LED50K300U",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 100.0,
            billPrice: 99.0,
            qty: 10,
            purchaseLimitQty: 2,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: "备注1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202070001",
            activityName: "copy_活动10151449",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2020-07-25 15:15:00",
            startDateT: 1595661300000,
            endDate: "2020-11-12 14:14:00",
            endDateT: 1605161640000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "6666666666666",
            productCode: "LED50K300U",
            img: "1100101/3451028/180-180/3451028_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "红黑",
            status: "2",
            importInfo: "4K、智能、VIDAA3、十四核处理器",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        id: 14172732598,
        activityCode: "CP202040002",
        activityName: "信商直播5",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1586225040000,
        startDateDesc: "2020-04-07 10:04:00",
        endDate: 1609380120000,
        endDateDesc: "2020-12-31 10:02:00",
        pause: "Y",
        pauseDesc: "暂停",
        productDtoList: [
          {
            id: 14172732601,
            activityId: 14172732598,
            productInfoId: 3451028,
            productInfoZzprdmodel: "LED50K300U",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 8000.0,
            billPrice: 8000.0,
            qty: 200,
            purchaseLimitQty: 200,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202040002",
            activityName: "信商直播5",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-04-07 10:04:00",
            startDateT: 1586225040000,
            endDate: "2020-12-31 10:02:00",
            endDateT: 1609380120000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "777777777777777",
            productCode: "LED50K300U",
            img: "1100101/3451028/180-180/3451028_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "红黑",
            status: "2",
            importInfo: "4K、智能、VIDAA3、十四核处理器",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "Y",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        id: 14173686210,
        activityCode: "CP202070007",
        activityName: "测试返利",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1596012060000,
        startDateDesc: "2020-07-29 16:41:00",
        endDate: 1609404000000,
        endDateDesc: "2020-12-31 16:40:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14173686215,
            activityId: 14173686210,
            productInfoId: 3451028,
            productInfoZzprdmodel: "LED50K300U",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 4300.0,
            billPrice: 3500.0,
            qty: 500,
            purchaseLimitQty: 100,
            purchaseQty: 10,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202070007",
            activityName: "测试返利",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-07-29 16:41:00",
            startDateT: 1596012060000,
            endDate: "2020-12-31 16:40:00",
            endDateT: 1609404000000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "8888888888888",
            productCode: "LED50K300U",
            img: "1100101/3451028/180-180/3451028_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "红黑",
            status: "2",
            importInfo: "4K、智能、VIDAA3、十四核处理器",
            priceId: 0,
            rebate: 50,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516003,
            makeUpTypeName: "正常补",
            makeUpTypeCode: "3",
          },
        ],
      },
      {
        id: 14171991511,
        activityCode: "CP202020018",
        activityName: "copy_分销直采测试",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1581486720000,
        startDateDesc: "2020-02-12 13:52:00",
        endDate: 1609424520000,
        endDateDesc: "2020-12-31 22:22:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14171991513,
            activityId: 14171991511,
            productInfoId: 3461656,
            productInfoZzprdmodel: "LTS120BFA-W0白",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 9000.0,
            billPrice: 1.11,
            qty: 1000,
            purchaseLimitQty: 1000,
            purchaseQty: 164,
            buyQty: null,
            totalMoney: null,
            remark: "分销直采测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020018",
            activityName: "copy_分销直采测试",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-12 13:52:00",
            startDateT: 1581486720000,
            endDate: "2020-12-31 22:22:00",
            endDateT: 1609424520000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "9999999999999",
            productCode: "LTS120BFA-W0白",
            img: null,
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "W0白",
            status: "2",
            importInfo: "99999999999999",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14171991514,
            activityId: 14171991511,
            productInfoId: 533799307,
            productInfoZzprdmodel: "100L6-B0深黑",
            priceGroupId: 2,
            priceGroupName: "特价机",
            standardPrice: 8000.0,
            billPrice: 2.22,
            qty: 1000,
            purchaseLimitQty: 1000,
            purchaseQty: 142,
            buyQty: null,
            totalMoney: null,
            remark: "测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020018",
            activityName: "copy_分销直采测试",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-12 13:52:00",
            startDateT: 1581486720000,
            endDate: "2020-12-31 22:22:00",
            endDateT: 1609424520000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "9a99a9a9a9a9a",
            productCode: "100L6-B0深黑",
            img: "1100106/533799307/180-180/533799307_0.jpg",
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "B0深黑",
            status: "2",
            importInfo: "9a99a9a9a9a9a",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
      {
        id: 14171931837,
        activityCode: "CP202020002",
        activityName: "分销直采测试",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1581486720000,
        startDateDesc: "2020-02-12 13:52:00",
        endDate: 1609424520000,
        endDateDesc: "2020-12-31 22:22:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14171931839,
            activityId: 14171931837,
            productInfoId: 3461656,
            productInfoZzprdmodel: "LTS120BFA-W0白",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 9000.0,
            billPrice: 1.0,
            qty: 100000,
            purchaseLimitQty: 100000,
            purchaseQty: 27005,
            buyQty: null,
            totalMoney: null,
            remark: "分销直采测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020002",
            activityName: "分销直采测试",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-12 13:52:00",
            startDateT: 1581486720000,
            endDate: "2020-12-31 22:22:00",
            endDateT: 1609424520000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "10000000000000000",
            productCode: "LTS120BFA-W0白",
            img: null,
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "W0白",
            status: "2",
            importInfo: "10000000000000000",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
          {
            id: 14171931840,
            activityId: 14171931837,
            productInfoId: 533799307,
            productInfoZzprdmodel: "100L6-B0深黑",
            priceGroupId: 2,
            priceGroupName: "特价机",
            standardPrice: 8000.0,
            billPrice: 1.0,
            qty: 100000,
            purchaseLimitQty: 100000,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: "测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020002",
            activityName: "分销直采测试",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-12 13:52:00",
            startDateT: 1581486720000,
            endDate: "2020-12-31 22:22:00",
            endDateT: 1609424520000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "XXXXXXXXXXXXXXXXXX",
            productCode: "100L6-B0深黑",
            img: "1100106/533799307/180-180/533799307_0.jpg",
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "B0深黑",
            status: "2",
            importInfo: "XXXXXXXXXXXXXXXXXX",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
          },
        ],
      },
    ],
    currentPage: 1,
  },
  marketList2: {
    totalPages: 2,
    pageSize: 10,
    totalRows: 20,
    list: [
      {
        id: 14170722588,
        activityCode: "CP2019110031",
        activityName: "抢购测试2",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1574825220000,
        startDateDesc: "2019-11-27 11:27:00",
        endDate: 1609428360000,
        endDateDesc: "2020-12-31 23:26:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14170722590,
            activityId: 14170722588,
            productInfoId: 485572311,
            productInfoZzprdmodel: "LED50N5700U",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 1112.0,
            billPrice: 1111.0,
            qty: 1111,
            purchaseLimitQty: 111,
            purchaseQty: 2,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110031",
            activityName: "抢购测试2",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2019-11-27 11:27:00",
            startDateT: 1574825220000,
            endDate: "2020-12-31 23:26:00",
            endDateT: 1609428360000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED50N5700U",
            productCode: "LED50N5700U",
            img: "1100101/485572311/180-180/485572311_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: null,
            status: "2",
            importInfo: "4K、智能",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "1",
            volume: "0",
            alreadyOrderNum: 0,
          },
          {
            id: 14170722591,
            activityId: 14170722588,
            productInfoId: 3459718,
            productInfoZzprdmodel: "VS780000M",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 1311.0,
            billPrice: 111.0,
            qty: 11111,
            purchaseLimitQty: 11,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110031",
            activityName: "抢购测试2",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2019-11-27 11:27:00",
            startDateT: 1574825220000,
            endDate: "2020-12-31 23:26:00",
            endDateT: 1609428360000,
            matklName: "附加产品",
            matklId: 20160119012,
            matklCode: "1100105",
            productName: "VS780000M",
            productCode: "VS780000M",
            img: "1100105/3459718/180-180/3459718_0.jpg",
            defaultImg: "assets/new/images/product/1100105.jpg",
            color: "B2亚光黑",
            status: "2",
            importInfo: "音响",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "1200",
            volume: "0",
            alreadyOrderNum: 0,
          },
        ],
      },
      {
        id: 796173398,
        activityCode: "123",
        activityName: "测试活动1",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "认购",
        startDate: 1514736000000,
        startDateDesc: "2018-01-01 00:00:00",
        endDate: 1604937600000,
        endDateDesc: "2020-11-10 00:00:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14170505624,
            activityId: 796173398,
            productInfoId: 3456204,
            productInfoZzprdmodel: "LED78XT920X3DUC",
            priceGroupId: 1,
            priceGroupName: "买断机",
            standardPrice: 1.0,
            billPrice: 1.0,
            qty: 1000,
            purchaseLimitQty: 500,
            purchaseQty: 28,
            buyQty: null,
            totalMoney: null,
            remark: "1",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "123",
            activityName: "测试活动1",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "认购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2020-11-10 00:00:00",
            endDateT: 1604937600000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED78XT920X3DUC",
            productCode: "LED78XT920X3DUC",
            img: "1100101/3456204/180-180/3456204_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0黑",
            status: "2",
            importInfo: "4K、曲面、悬浮画框设计、前置音吧、黄金仰角",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "0",
            volume: "1.176",
            alreadyOrderNum: -2,
          },
        ],
      },
      {
        id: 14170722574,
        activityCode: "CP2019110030",
        activityName: "测试抢购",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1514736000000,
        startDateDesc: "2018-01-01 00:00:00",
        endDate: 1625059260000,
        endDateDesc: "2021-06-30 21:21:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14170722580,
            activityId: 14170722574,
            productInfoId: 485572311,
            productInfoZzprdmodel: "LED50N5700U",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 1111.0,
            billPrice: 1000.0,
            qty: 111,
            purchaseLimitQty: 11,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110030",
            activityName: "测试抢购",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2021-06-30 21:21:00",
            endDateT: 1625059260000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED50N5700U",
            productCode: "LED50N5700U",
            img: "1100101/485572311/180-180/485572311_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: null,
            status: "2",
            importInfo: "4K、智能",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "111",
            volume: "0",
            alreadyOrderNum: 0,
          },
          {
            id: 14170722579,
            activityId: 14170722574,
            productInfoId: 3459718,
            productInfoZzprdmodel: "VS780000M",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 1112.0,
            billPrice: 1212.0,
            qty: 1221,
            purchaseLimitQty: 122,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110030",
            activityName: "测试抢购",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2021-06-30 21:21:00",
            endDateT: 1625059260000,
            matklName: "附加产品",
            matklId: 20160119012,
            matklCode: "1100105",
            productName: "VS780000M",
            productCode: "VS780000M",
            img: "1100105/3459718/180-180/3459718_0.jpg",
            defaultImg: "assets/new/images/product/1100105.jpg",
            color: "B2亚光黑",
            status: "2",
            importInfo: "音响",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "-100",
            volume: "0",
            alreadyOrderNum: 0,
          },
          {
            id: 14170722581,
            activityId: 14170722574,
            productInfoId: 352662228,
            productInfoZzprdmodel: "LED65NU8800U",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 6000.0,
            billPrice: 5000.0,
            qty: 1000,
            purchaseLimitQty: 50,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110030",
            activityName: "测试抢购",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2021-06-30 21:21:00",
            endDateT: 1625059260000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED65NU8800U",
            productCode: "LED65NU8800U",
            img: "1100101/352662228/180-180/352662228_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "DU玫瑰金",
            status: "2",
            importInfo: "VIDAA、4K、智能",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "1000",
            volume: "0.276",
            alreadyOrderNum: 0,
          },
          {
            id: 14170722582,
            activityId: 14170722574,
            productInfoId: 3451028,
            productInfoZzprdmodel: "LED50K300U",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 500.0,
            billPrice: 300.0,
            qty: 1000,
            purchaseLimitQty: 10,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110030",
            activityName: "测试抢购",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2018-01-01 00:00:00",
            startDateT: 1514736000000,
            endDate: "2021-06-30 21:21:00",
            endDateT: 1625059260000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED50K300U",
            productCode: "LED50K300U",
            img: "1100101/3451028/180-180/3451028_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "红黑",
            status: "2",
            importInfo: "4K、智能、VIDAA3、十四核处理器",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "200",
            volume: "0.094",
            alreadyOrderNum: -6,
          },
        ],
      },
      {
        id: 14170745822,
        activityCode: "CP2019110046",
        activityName: "制造分销订单",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1574997540000,
        startDateDesc: "2019-11-29 11:19:00",
        endDate: 1609426440000,
        endDateDesc: "2020-12-31 22:54:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14170745824,
            activityId: 14170745822,
            productInfoId: 3455093,
            productInfoZzprdmodel: "PX520",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 111.0,
            billPrice: 11.0,
            qty: 111,
            purchaseLimitQty: 111,
            purchaseQty: 4,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110046",
            activityName: "制造分销订单",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2019-11-29 11:19:00",
            startDateT: 1574997540000,
            endDate: "2020-12-31 22:54:00",
            endDateT: 1609426440000,
            matklName: "IBOX",
            matklId: 20160119010,
            matklCode: "1100103",
            productName: "PX520",
            productCode: "PX520",
            img: "1100103/3455093/180-180/3455093_0.jpg",
            defaultImg: "assets/new/images/product/1100103.jpg",
            color: null,
            status: "2",
            importInfo: "智能盒子",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "100",
            volume: "0.002",
            alreadyOrderNum: 0,
          },
          {
            id: 14170745825,
            activityId: 14170745822,
            productInfoId: 3452716,
            productInfoZzprdmodel: "PX1900-B0黑",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 111.0,
            billPrice: 11.0,
            qty: 111,
            purchaseLimitQty: 111,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110046",
            activityName: "制造分销订单",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2019-11-29 11:19:00",
            startDateT: 1574997540000,
            endDate: "2020-12-31 22:54:00",
            endDateT: 1609426440000,
            matklName: "IBOX",
            matklId: 20160119010,
            matklCode: "1100103",
            productName: "PX1900-B0黑",
            productCode: "PX1900-B0黑",
            img: "1100103/3452716/180-180/3452716_0.jpg",
            defaultImg: "assets/new/images/product/1100103.jpg",
            color: "B0黑",
            status: "2",
            importInfo:
              '"硬解H.265，看大片不卡顿、HDMI 2.0接口支持4K点对点输出，更完美地支持4K分辨率，深度优化了 游戏、应用、视频、学习四大特色功能，四核CPU+四核GPU，让电视极速流畅"',
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "100",
            volume: "0.003",
            alreadyOrderNum: 0,
          },
          {
            id: 14170745826,
            activityId: 14170745822,
            productInfoId: 3459728,
            productInfoZzprdmodel: "PX530 PLUS-D4玫瑰金",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 111.0,
            billPrice: 11.0,
            qty: 111,
            purchaseLimitQty: 111,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110046",
            activityName: "制造分销订单",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2019-11-29 11:19:00",
            startDateT: 1574997540000,
            endDate: "2020-12-31 22:54:00",
            endDateT: 1609426440000,
            matklName: "IBOX",
            matklId: 20160119010,
            matklCode: "1100103",
            productName: "PX530 PLUS-D4玫瑰金",
            productCode: "PX530 PLUS-D4玫瑰金",
            img: "1100103/3459728/180-180/3459728_0.jpg",
            defaultImg: "assets/new/images/product/1100103.jpg",
            color: "D4玫瑰金",
            status: "2",
            importInfo:
              '"硬解H.265，看大片不卡顿、HDMI 2.0接口支持4K点对点输出，更完美地支持4K分辨率，深度优化了 游戏、应用、视频、学习四大特色功能，四核CPU+四核GPU，让电视极速流畅"',
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "100",
            volume: "0.002",
            alreadyOrderNum: 0,
          },
          {
            id: 14170745827,
            activityId: 14170745822,
            productInfoId: 3450702,
            productInfoZzprdmodel: "PX530-D4玫瑰金",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 111.0,
            billPrice: 11.0,
            qty: 1111,
            purchaseLimitQty: 111,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP2019110046",
            activityName: "制造分销订单",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2019-11-29 11:19:00",
            startDateT: 1574997540000,
            endDate: "2020-12-31 22:54:00",
            endDateT: 1609426440000,
            matklName: "IBOX",
            matklId: 20160119010,
            matklCode: "1100103",
            productName: "PX530-D4玫瑰金",
            productCode: "PX530-D4玫瑰金",
            img: "1100103/3450702/180-180/3450702_0.jpg",
            defaultImg: "assets/new/images/product/1100103.jpg",
            color: "D4玫瑰金",
            status: "2",
            importInfo:
              '"硬解H.265，看大片不卡顿、HDMI 2.0接口支持4K点对点输出，更完美地支持4K分辨率，深度优化了 游戏、应用、视频、学习四大特色功能，四核CPU+四核GPU，让电视极速流畅"',
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "100",
            volume: "0.002",
            alreadyOrderNum: 0,
          },
        ],
      },
      {
        id: 14172058914,
        activityCode: "CP202020033",
        activityName: "测试分销直采余额不足",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1582519560000,
        startDateDesc: "2020-02-24 12:46:00",
        endDate: 1616138100000,
        endDateDesc: "2021-03-19 15:15:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14172058917,
            activityId: 14172058914,
            productInfoId: 3461656,
            productInfoZzprdmodel: "LTS120BFA-W0白",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 9000.0,
            billPrice: 9.99999999e8,
            qty: 1000,
            purchaseLimitQty: 1000,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: "分销直采测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020033",
            activityName: "测试分销直采余额不足",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-03-19 15:15:00",
            endDateT: 1616138100000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "LTS120BFA-W0白",
            productCode: "LTS120BFA-W0白",
            img: null,
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "W0白",
            status: "2",
            importInfo: null,
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "-999990999",
            volume: "0",
            alreadyOrderNum: 0,
          },
        ],
      },
      {
        id: 14172058931,
        activityCode: "CP202020034",
        activityName: "copy_测试分销直采余额不足",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1582519560000,
        startDateDesc: "2020-02-24 12:46:00",
        endDate: 1614518460000,
        endDateDesc: "2021-02-28 21:21:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14172058934,
            activityId: 14172058931,
            productInfoId: 3461656,
            productInfoZzprdmodel: "LTS120BFA-W0白",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 9000.0,
            billPrice: 999999.0,
            qty: 1000,
            purchaseLimitQty: 1000,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: "分销直采测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020034",
            activityName: "copy_测试分销直采余额不足",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-02-28 21:21:00",
            endDateT: 1614518460000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "LTS120BFA-W0白",
            productCode: "LTS120BFA-W0白",
            img: null,
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "W0白",
            status: "2",
            importInfo: null,
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "-990999",
            volume: "0",
            alreadyOrderNum: 0,
          },
        ],
      },
      {
        id: 14172041956,
        activityCode: "CP202020023",
        activityName: "分销直采测试2",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1582519560000,
        startDateDesc: "2020-02-24 12:46:00",
        endDate: 1614514800000,
        endDateDesc: "2021-02-28 20:20:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14172041967,
            activityId: 14172041956,
            productInfoId: 285905851,
            productInfoZzprdmodel: "LED43K300YU",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 55.0,
            billPrice: 4.23,
            qty: 6666,
            purchaseLimitQty: 4444,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020023",
            activityName: "分销直采测试2",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-02-28 20:20:00",
            endDateT: 1614514800000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED43K300YU",
            productCode: "LED43K300YU",
            img: "1100101/285905851/180-180/285905851_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0深黑",
            status: "2",
            importInfo: "4K、智能、VIDAA3、十四核处理器",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "50.769999999999999573674358543939888477325439453125",
            volume: "0.109",
            alreadyOrderNum: 0,
          },
          {
            id: 14172041965,
            activityId: 14172041956,
            productInfoId: 3461661,
            productInfoZzprdmodel: "LED50MU8600UC",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 55.0,
            billPrice: 6.44,
            qty: 6666,
            purchaseLimitQty: 4444,
            purchaseQty: 3,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020023",
            activityName: "分销直采测试2",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-02-28 20:20:00",
            endDateT: 1614514800000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED50MU8600UC",
            productCode: "LED50MU8600UC",
            img: "1100101/3461661/180-180/3461661_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "D5香槟金",
            status: "2",
            importInfo:
              "4K、ULED、曲面、臻彩高色域、蛾眼放生技术、前置音吧、金属边框、PCM背板",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "48.55999999999999960920149533194489777088165283203125",
            volume: "0.191",
            alreadyOrderNum: 0,
          },
          {
            id: 14172041966,
            activityId: 14172041956,
            productInfoId: 97999252,
            productInfoZzprdmodel: "LED55EC550UA",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 55.0,
            billPrice: 3.45,
            qty: 6666,
            purchaseLimitQty: 4444,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020023",
            activityName: "分销直采测试2",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-02-28 20:20:00",
            endDateT: 1614514800000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED55EC550UA",
            productCode: "LED55EC550UA",
            img: "1100101/97999252/180-180/97999252_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "A11泰坦灰",
            status: "2",
            importInfo: "4K、智能、VIDAA",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "51.54999999999999982236431605997495353221893310546875",
            volume: "0.191",
            alreadyOrderNum: 0,
          },
          {
            id: 14172041968,
            activityId: 14172041956,
            productInfoId: 683912907,
            productInfoZzprdmodel: "LED43H2600",
            priceGroupId: 50,
            priceGroupName: "降类机",
            standardPrice: 66.0,
            billPrice: 1.45,
            qty: 6666,
            purchaseLimitQty: 4413,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020023",
            activityName: "分销直采测试2",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-02-28 20:20:00",
            endDateT: 1614514800000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED43H2600",
            productCode: "LED43H2600",
            img: "1100101/683912907/180-180/683912907_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "B0深黑",
            status: "2",
            importInfo: "2D智能电视",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "64.5500000000000000444089209850062616169452667236328125",
            volume: "0.107",
            alreadyOrderNum: 0,
          },
          {
            id: 14172041969,
            activityId: 14172041956,
            productInfoId: 14189155047,
            productInfoZzprdmodel: "LED34K23U",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 2.34,
            billPrice: 1.11,
            qty: 344,
            purchaseLimitQty: 344,
            purchaseQty: 1,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020023",
            activityName: "分销直采测试2",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-02-28 20:20:00",
            endDateT: 1614514800000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED34K23U",
            productCode: "LED34K23U",
            img: null,
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: null,
            status: "2",
            importInfo: null,
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "1.2299999999999997601918266809661872684955596923828125",
            volume: "0",
            alreadyOrderNum: 0,
          },
        ],
      },
      {
        id: 14173686210,
        activityCode: "CP202070007",
        activityName: "测试返利",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1596012060000,
        startDateDesc: "2020-07-29 16:41:00",
        endDate: 1609404000000,
        endDateDesc: "2020-12-31 16:40:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14173686215,
            activityId: 14173686210,
            productInfoId: 3451028,
            productInfoZzprdmodel: "LED50K300U",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 4300.0,
            billPrice: 3500.0,
            qty: 500,
            purchaseLimitQty: 100,
            purchaseQty: 18,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202070007",
            activityName: "测试返利",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-07-29 16:41:00",
            startDateT: 1596012060000,
            endDate: "2020-12-31 16:40:00",
            endDateT: 1609404000000,
            matklName: "电视",
            matklId: 20160119008,
            matklCode: "1100101",
            productName: "LED50K300U",
            productCode: "LED50K300U",
            img: "1100101/3451028/180-180/3451028_0.jpg",
            defaultImg: "assets/new/images/product/1100101.jpg",
            color: "红黑",
            status: "2",
            importInfo: "4K、智能、VIDAA3、十四核处理器",
            priceId: 0,
            rebate: 50,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516003,
            makeUpTypeName: "正常补",
            makeUpTypeCode: "3",
            productInfo: null,
            brand: "海信",
            deduct: "800",
            volume: "0.094",
            alreadyOrderNum: -6,
          },
        ],
      },
      {
        id: 14171977285,
        activityCode: "CP202020006",
        activityName: "测试没维护商品价格",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1582013220000,
        startDateDesc: "2020-02-18 16:07:00",
        endDate: 1889417640000,
        endDateDesc: "2029-11-15 14:14:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14171977287,
            activityId: 14171977285,
            productInfoId: 3453818,
            productInfoZzprdmodel: "LTS100BHA-W8亮银白",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 1111.0,
            billPrice: 1236.0,
            qty: 1000,
            purchaseLimitQty: 1000,
            purchaseQty: 6,
            buyQty: null,
            totalMoney: null,
            remark: null,
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020006",
            activityName: "测试没维护商品价格",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-18 16:07:00",
            startDateT: 1582013220000,
            endDate: "2029-11-15 14:14:00",
            endDateT: 1889417640000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "LTS100BHA-W8亮银白",
            productCode: "LTS100BHA-W8亮银白",
            img: "1100106/3453818/180-180/3453818_0.jpg",
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "W8亮银白",
            status: "2",
            importInfo:
              "100吋高清巨幕，超值享受更震撼的家庭影院、反射成像，自然光不刺眼，更护眼更舒适、激光光源，能看到的色彩更丰富、搭载VIDAA系统，海量看电影电视剧",
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "-125",
            volume: "0",
            alreadyOrderNum: 0,
          },
        ],
      },
      {
        id: 14171991520,
        activityCode: "CP202020019",
        activityName: "copy_copy_分销直采测试",
        fwOrgId: 154,
        fwOrgName: "股份青岛",
        activityType: "抢购",
        startDate: 1582519560000,
        startDateDesc: "2020-02-24 12:46:00",
        endDate: 1616138100000,
        endDateDesc: "2021-03-19 15:15:00",
        pause: "N",
        pauseDesc: "正常",
        productDtoList: [
          {
            id: 14171991522,
            activityId: 14171991520,
            productInfoId: 3461656,
            productInfoZzprdmodel: "LTS120BFA-W0白",
            priceGroupId: 3,
            priceGroupName: "常规机",
            standardPrice: 9000.0,
            billPrice: 1.11,
            qty: 1000,
            purchaseLimitQty: 1000,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: "分销直采测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020019",
            activityName: "copy_copy_分销直采测试",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-03-19 15:15:00",
            endDateT: 1616138100000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "LTS120BFA-W0白",
            productCode: "LTS120BFA-W0白",
            img: null,
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "W0白",
            status: "2",
            importInfo: null,
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "8998.8899999999999999023003738329862244427204132080078125",
            volume: "0",
            alreadyOrderNum: 0,
          },
          {
            id: 14171991523,
            activityId: 14171991520,
            productInfoId: 533799307,
            productInfoZzprdmodel: "100L6-B0深黑",
            priceGroupId: 2,
            priceGroupName: "特价机",
            standardPrice: 8000.0,
            billPrice: 2.22,
            qty: 1000,
            purchaseLimitQty: 1000,
            purchaseQty: 0,
            buyQty: null,
            totalMoney: null,
            remark: "测试",
            redundant1: null,
            redundant2: null,
            redundant3: null,
            activityCode: "CP202020019",
            activityName: "copy_copy_分销直采测试",
            fwOrgId: 154,
            fwOrgCode: "2601",
            fwOrgName: "股份青岛",
            activityType: "抢购",
            startDate: "2020-02-24 12:46:00",
            startDateT: 1582519560000,
            endDate: "2021-03-19 15:15:00",
            endDateT: 1616138100000,
            matklName: "激光影院",
            matklId: 20160119013,
            matklCode: "1100106",
            productName: "100L6-B0深黑",
            productCode: "100L6-B0深黑",
            img: "1100106/533799307/180-180/533799307_0.jpg",
            defaultImg: "assets/new/images/product/1100106.jpg",
            color: "B0深黑",
            status: "2",
            importInfo: null,
            priceId: 0,
            rebate: 0,
            rebates: null,
            subTotal: null,
            canBuy: "Y",
            pause: "N",
            orderStatus: 1,
            makeUpTypeId: 516001,
            makeUpTypeName: "不补",
            makeUpTypeCode: "1",
            productInfo: null,
            brand: "海信",
            deduct: "7997.779999999999999804600747665972448885440826416015625",
            volume: "0.138",
            alreadyOrderNum: 0,
          },
        ],
      },
    ],
    currentPage: 1,
  },
  zoneObj:{
    title:'璀璨·成套家电专区',
    url:require("@/assets/home/5.jpg"),
    path:'/product'
  }
};
