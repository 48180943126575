<!--
 * @Author: sqk
 * @Date: 2020-08-31 16:53:13
 * @LastEditTime: 2021-05-17 14:43:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\LimitShop.vue
-->



<template>
  <div class="limit-item swiper-box" :class="{'reduce' : data.length < 4}">
    <div class="swiper-container" ref="mySwiper" v-if="data.length > 3">
      <div class="swiper-wrapper">
        <slot />
      </div>
      <!-- 如果需要分页器:style="'border-top:1px solid' + borderColorFun(index)" -->
      <!-- <div class="swiper-pagination"></div> -->
      <!--箭头。如果放置在swiper-container外面，需要自定义样式。-->
      <div
        class="swiper-button-prev animated fadeInRight"
        ref="prevEl"
        v-if="data && data.length > 4"
      ></div>
      <div
        class="swiper-button-next animated fadeInLeft"
        ref="nextEl"
        v-if="data && data.length > 4"
      ></div>
    </div>
    <div>
      <slot v-if="data.length < 4" />
      <div
        :key="data[0].activityCode + 'bk'"
        class="swiper-slide animated fadeInRight view-card one"
        v-if="data.length < 4"
        :style="{ animationDelay: (data.length + 1) * .1 + 's' }"
        @click="toPath('bk')"
      >
        <div class="card-item-box box-shadow">
          <div class="card-in-box">
            <div class="img-box">
              <div class="img-box-in">
                <!-- <img src="@/assets/home/active1.png" /> -->
                <img :src="imgObj.hotImg" />
                <!-- <h-img :imgUrl="imgObj.hotImg" :errorImg="'/assets/new/images/product/' +data[0].matklCode + '.jpg'" isUseBasePath></h-img> -->
              </div>
            </div>
            <p class="top">爆款好物</p>
            <p class="btm">告别选择困难症</p>
            <div class="word-btn">爆款推荐</div>
          </div>
        </div>
      </div>
      <div
        :key="data[0].activityCode + 'xp'"
        class="swiper-slide animated fadeInRight view-card two"
        v-if="data.length < 3"
        :style="{ animationDelay: (data.length + 2) * .1 + 's' }"
        @click="toPath('xp')"
      >
        <div class="card-item-box box-shadow">
          <div class="card-in-box">
            <div class="img-box">
              <div class="img-box-in">
                <!-- <img src="@/assets/home/active2.png" /> -->
                <img :src="imgObj.newImg" />
                <!-- <h-img :imgUrl="$store.dispatch({matkId:data[0].matklCode,type:'new'})" :errorImg="'/assets/new/images/product/' +data[0].matklCode + '.jpg'" isErrorHideFlag></h-img> -->
              </div>
            </div>
            <p class="top">新品优选</p>
            <p class="btm">来这里发现更多</p>
            <div class="word-btn">新品尝鲜</div>
          </div>
        </div>
      </div>
      <div
        :key="data[0].activityCode + 'rm'"
        class="swiper-slide animated fadeInRight view-card three"
        v-if="data.length < 2"
        :style="{ animationDelay: .4 + 's' }"
        @click="toPath('rm')"
      >
        <div class="card-item-box box-shadow">
          <div class="card-in-box">
            <div class="img-box">
              <div class="img-box-in">
                <!-- <img src="@/assets/home/active3.png" /> -->
                <img :src="imgObj.rxImg" />
                <!-- <h-img :imgUrl="$store.dispatch({matkId:data[0].matklCode,type:'rx'})" :errorImg="'/assets/new/images/product/' +data[0].matklCode + '.jpg'" isErrorHideFlag></h-img> -->
              </div>
            </div>
            <p class="top">热卖榜销</p>
            <p class="btm">引领风向标</p>
            <div class="word-btn">热卖展销</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import countdown from "./CountDown";
import { mapState } from "vuex";
export default {
  name: "LimitShopNew",
  // components: {
  //   countdown //限时抢购倒计时
  // },
  data() {
    return {
      type: ""
    };
  },
  props: {
    // data: {
    //   type: Object,
    //   default: () => {}
    // }
    data: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data: function() {
      if (this.data && this.data.length > 4) {
        this.swiperInit();
      }
    },
  },
  //定义swiper对象
  computed: {
    // imgObj() {
    //   return this.$store.state.user.imgObj
    // },
    ...mapState({
      //用于查询列表的筛选配置字段
      imgObj: (state) =>
        state.user.imgObj
    }),
  },
  mounted() {
    if (this.data && this.data.length > 4) {
      this.swiperInit();
    }
  },
  methods: {
    getImg(matkObj) {
      this.$store.dispatch("user/getMatkImg", matkObj).then(res => {
        return res;
      });
      // this.$store.dispatch('user/getMatkImg',matkObj)
    },
    toPath(type) {
      let popularType;
      if (type == "bk") {
        //
        popularType = 'popularProduct';
      } else if (type == "xp") {
        //
        popularType = 'newProduct';
      } else {
        //
        popularType = 'hotProduct';
      }
      this.$router.push({
        path: "/product",
        query: { popularType: popularType,matklCodes: this.data[0].matklCode,matklName:this.data[0].matklName }
      });
    },
    swiperInit() {
      this.$nextTick(() => {
        let this_ = this;
        var mySwiper = new this.$Swiper(this.$refs.mySwiper, {
          on: {
            slideChange: function() {
            },
            click: () => {
              // alert("你点了Swiper");
            }
          },
          autoplay: {
            //自动轮播
            delay: 200000,
            disableOnInteraction: false,
            autoplayDisableOnInteraction: false
            // stopOnLastSlide:true
          },
          // loop: true, // 循环模式选项
          // direction: "vertical",
          onlyExternal: true,
          // noSwiping : true,
          // simulateTouch: false,//鼠标模拟手机触摸。默认为true，Swiper接受鼠标点击、拖动。
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 12,
          // preventLinksPropagation:false,//防止冒泡。
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          //         // 如果需要分页器
          // pagination: {
          //   el: '.swiper-pagination',
          // },
          navigation: {
            nextEl: this_.$refs.nextEl,
            prevEl: this_.$refs.prevEl
          }
        });
      });
    },
    borderColorFun(index) {
      let idx = index % 4;
      let colorList = ["#FF8F3C", "#00B7B3", "#4285FF", "#D9161C"];
      return colorList[idx];
    }
  }
};
</script>
<style lang="less" scoped>
.limit-item.swiper-box {
  // width: 1188px;
  width: 1200px;
  // width: 1228px;//多30px 展示动画阴影
  height: 360px;
  // display: flex;
  /* justify-content: center; */
  // align-items: center;
  margin-top: 12px;
  // clear: both;
  float: left;
  
  &.reduce {
    // width: 588px;
    .swiper-slide {
      + .swiper-slide {
        // margin-left: 12px;
      }
    }
  }
  .time-box {
    width: 288px;
    height: 360px;
    background: #efeaea;
    float: left;
    // border-top: 2px solid #d9161c;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    .color-line {
      width: 100%;
      height: 2px;
      background: #d9161c;
    }
    h2 {
      width: 260px;
      height: 24px;
      font-size: 24px;
      color: #d9161c;
      line-height: 24px;
      margin-top: 64px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .icon-box {
      margin-top: 30px;
      width: 54px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-xianshizhuanchang {
        font-size: 54px;
        color: #d9161c;
      }
    }
  }
  .swiper-slide {
    width: 288px;
    height: 360px;
    float: left;
    border-radius: 4px;
    margin-right: 12px;
  }
  .swiper-container {
    // width: 888px;
    width: 1188px;
    height: 360px;
    float: left;
    padding: 20px 12px;
    margin-top: -20px;
    margin-left: -12px;
    box-sizing: content-box;
    .swiper-button-prev {
      left: 12px;
    }
    .swiper-button-next {
      right: 12px;
    }
  }
  .card-ani-box {
    margin-top: 0;
  }
  /deep/.view-card {
    width: 288px;
    height: 360px;
    // background-color: #fff;
    cursor: pointer;
    position: relative;
    text-align: center;
    border-radius: 10px;
    // overflow: hidden;
    
    &.one {
      > .card-item-box {
        .card-in-box {
          background: linear-gradient(180deg, #ffffff 0%, #fff5f5 100%);
        }
      }

      .word-btn {
        border: 1px solid #d9161c;
        color: #d9161c;
      }
      &:hover {
        .word-btn {
          background-color: #d9161c;
          color: #fff;
        }
      }
    }
    &.two {
      > .card-item-box {
        .card-in-box {
          background: linear-gradient(180deg, #ffffff 0%, #ecf8f8 100%);
        }
      }

      .word-btn {
        border: 1px solid #00aaa6;
        color: #00aaa6;
      }
      &:hover {
        .word-btn {
          background-color: #00aaa6;
          color: #fff;
        }
      }
    }
    &.three {
      > .card-item-box {
        .card-in-box {
          background: linear-gradient(180deg, #ffffff 0%, #fdf3ed 100%);
        }
      }

      .word-btn {
        border: 1px solid #ff8f3c;
        color: #ff8f3c;
      }
      &:hover {
        .word-btn {
          background-color: #ff8f3c;
          color: #fff;
        }
      }
    }
    > .card-item-box {
      width: 288px;
      height: 360px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      background-color: #fff;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      .card-in-box {
        width: 260px;
        height: 340px;
        border-radius: 6px;
        margin: 8px auto 0;
      }
    }
    .img-box {
      width: 100%;
      height: 228px;
      overflow: hidden;
      .img-box-in {
        width: 210px;
        height: 210px;
        margin: 14px auto 0;
        // background: url("../../../assets/home/demo (1).png") no-repeat center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        // border: 1px solid #efefef;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    p.top {
      height: 20px;
      font-size: 14px;
      font-weight: 600;
      color: #262626;
      line-height: 20px;
      margin-top: 10px;
    }
    p.btm {
      height: 16px;
      margin-top: 6px;
      font-size: 12px;
      color: #777;
      line-height: 16px;
    }
    .word-btn {
      width: 88px;
      height: 28px;
      line-height: 26px;
      border-radius: 14px;
      margin: 20px auto 0;
      font-size: 14px;
      transition: all 0.32s;
    }
  }
}
</style>

