<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2020-11-30 14:46:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div class="commodity-box clearfix">
    <slot name="commodity-box" />
  </div>
</template>

<script>
export default {
  name: "commodity-box",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    }
  },
  watch: {
    data: function() {}
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="less" scoped>
.commodity-box {
  // padding-top: 40px;
  &.no-login {
    padding-top: 12px;
  }
  .tip-line + .card-box {
    margin-top: 0;
  }
  .tip-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .tip-in {
      text-indent: 2px;
      margin: 0;
    }
    .search-box {
      float: right;
      width: 280px;
      height: 50px;
      .ant-input-group {
        height: 100%;
        .ant-input {
          height: 100%;
        }
        .ant-input-search-button {
          height: 100%;
        }
      }
    }
  }
  .card-box {
    // padding-top: 40px;
  }
  .card-box:last-child {
    margin-bottom: 40px;
  }
}
</style>

