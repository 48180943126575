<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2021-01-27 19:13:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div class="limit-shop-box clearfix" v-if="userInfo">
    <slot name="limit-shop-box" />
  </div>
</template>

<script>
export default {
  name: "limit-shop-box",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    }
  },
  watch: {
    data: function() {}
  },
  mounted() {},
  methods: {}
};
</script>
<style lang="less">
.limit-shop-box {
  width: 1188px;
  margin: 24px auto 0;
  .limit-box {
    // margin-top: -12px;
    .active-nav {
      li.dft{
        height: 168px;
        background: #e6f2fc;
        float: left;
        border-radius: 10px;
        &.dft1{
          background: url("~@/assets/home/activeDft1.png") no-repeat center;
          background-size: 100% 100%;
          width: 288px;
        }
        &.dft2{
          background: url("~@/assets/home/activeDft2.png") no-repeat center;
          background-size: 100% 100%;
          width: 588px;
        }
        &.dft3{
          background: url("~@/assets/home/activeDft3.png") no-repeat center;
          background-size: 100% 100%;
          width: 888px;
        }
      }
      li.dft-item {
        width: 288px;
        // height: 140px;
        height: 168px;
        background: #e6f2fc;
        float: left;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        transition: all .2s;
        &:hover,&.active{
          background-color: #fff !important;
          h2,h3{
            transition: all .2s;
            color: #D9161C !important;
          }
          .bg-box{
            opacity: .18;
          }
        }
        &.active{
          .word-box{
            display: flex;
            i{
              -webkit-animation-name: hvr-icon-bob-float, hvr-icon-bob;
              animation-name: hvr-icon-bob-float, hvr-icon-bob;
              -webkit-animation-duration: .3s, 1.5s;
              animation-duration: .3s, 1.5s;
              -webkit-animation-delay: 0s, .3s;
              animation-delay: 0s, .3s;
              -webkit-animation-timing-function: ease-out, ease-in-out;
              animation-timing-function: ease-out, ease-in-out;
              -webkit-animation-iteration-count: 1, infinite;
              animation-iteration-count: 1, infinite;
              -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
              -webkit-animation-direction: normal, alternate;
              animation-direction: normal, alternate;
            }
          }
        }
        + li {
          margin-left: 12px;
        }
        &::after{
          // content: '进行中';
          width: 60px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
          color: #fff;
          border-bottom-left-radius: 10px;
          border-top-right-radius: 10px;
          position: absolute;
          right: 0;
          top: 0;
          // background: #FF8F3C;
        }
        &.ing::after{
          width: 54px;
          content: '进行中';
          background: #FF8F3C;
        }
        &.wait::after{
          width: 60px;
          content: '即将开始';
          background: #00B7B3;
        }
        .bg-box{
          width: 158px;
          height: 80px;
          // position: absolute;
          // left: calc(50% - 79px);
          // top: 68px;
          // opacity: 1;
          // margin: -12px auto 0;
          margin: 13px auto 0;
          transition: all .2s;
          pointer-events: none;
          z-index: 0;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          position: relative;
          img{
            // width: 100%;
            // height: 100%;
            // border: 1px solid;
          }
          img{
            position: absolute;
            width: 158px;
            height: 80px;
            top: 0px;
            left: 0;
            // z-index: 0;
          }
        }
        .word-box{
          position: absolute;
          width: 158px;
          height: 72px;
          left: calc(50% - 79px);
          top:100px;
          opacity: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          display: none;
          animation-duration: .6s;
           span{
            width: 80px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            color: #fff;
            font-size:14px;
            background: #D9161C;
            border-radius: 15px;
            margin-bottom: 6px;
          }
          i{
            color: #D9161C;
            font-size: 12px;
          }
        }
        h1 {
          width: 80%;
          height: 20px;
          font-size: 16px;
          color: #353f52;
          line-height: 20px;
          // margin-top: 14px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          margin: 14px auto 0;
        }
        h2 {
          height: 14px;
          font-size: 14px;
          line-height: 14px;
          margin-top: 8px;
        }
        h3 {
          height: 14px;
          font-size: 14px;
          line-height: 14px;
          margin-top: 6px;
          z-index: 1;
        }
        
        &:nth-of-type(1){
          background-color: #E6F2FC;
          h2,h3{
            color: #417ED1;
          }
        }
        &:nth-of-type(2){
          background-color: #F0EBE8;
          h2,h3{
            color: #E26635;
          }
        }
        &:nth-of-type(3){
          background-color: #E0EAED;
          h2,h3{
            color: #00AAA6;
          }
        }
        &:nth-of-type(4){
          background-color: #EEEEFA;
          h2,h3{
            color: #5555CF;
          }
        }
        
      }
    }
  }
  .fadeInUp {
    animation-duration: 0.8s;
  }
  & + .commodity-box {
    margin-top: 30px;
  }
}
</style>

