var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.userInfo&& _vm.subMenuList.length>0)?_c('div',{staticClass:"operation-box clearfix"},[_c('h1',{staticClass:"title-line"},[_c('b',[_vm._v("经营分析")]),_c('div',{staticClass:"btn-group",on:{"click":_vm.goDetail}},[_vm._m(0)])]),_c('ul',[_c('li',{staticClass:"type1 box-shadow box-radius"},[_c('div',{staticClass:"item1"},[_c('h3',{staticClass:"toptext"},[_vm._v("本月提货额（元）")]),_c('h4',{staticClass:"amount"},[_vm._v(" "+_vm._s(_vm.getProp(_vm.purchaseReport, "purchaseAmount.count", "") || 0)+" ")]),_c('p',[_c('span',[_vm._v(" 环比 "),_c('em',{staticClass:"down",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseAmount.HB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.purchaseReport, "purchaseAmount.HB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.HB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.HB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])]),_c('p',[_c('span',[_vm._v(" 同比 "),_c('em',{staticClass:"up",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseAmount.TB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.purchaseReport, "purchaseAmount.TB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.TB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.TB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])])]),_c('div',{staticClass:"item2"},[_c('h3',{staticClass:"toptext"},[_vm._v("本月提货量（台）")]),_c('h4',{staticClass:"num"},[_vm._v(" "+_vm._s(_vm.getProp(_vm.purchaseReport, "purchaseQuantity.count", "") || 0)+" ")]),_c('p',[_c('span',[_vm._v(" 环比 "),_c('em',{staticClass:"down",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.HB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.purchaseReport, "purchaseQuantity.HB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.HB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.HB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])]),_c('p',[_c('span',[_vm._v(" 同比 "),_c('em',{staticClass:"up",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.TB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.purchaseReport, "purchaseQuantity.TB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.TB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.purchaseReport, 'purchaseQuantity.TB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])])])]),_c('li',{staticClass:"type1 box-shadow box-radius"},[_c('div',{staticClass:"item1"},[_c('h3',{staticClass:"toptext"},[_vm._v("本月销售额（元）")]),_c('h4',{staticClass:"amount"},[_vm._v(" "+_vm._s(_vm.getProp(_vm.salesReport, "salesTotalAmount.count", "") || 0)+" ")]),_c('p',[_c('span',[_vm._v(" 环比 "),_c('em',{staticClass:"down",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalAmount.HB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.salesReport, "salesTotalAmount.HB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalAmount.HB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalAmount.HB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])]),_c('p',[_c('span',[_vm._v(" 同比 "),_c('em',{staticClass:"up",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalAmount.TB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.salesReport, "salesTotalAmount.TB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalAmount.TB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalAmount.TB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])])]),_c('div',{staticClass:"item2"},[_c('h3',{staticClass:"toptext"},[_vm._v("本月销售总量（台）")]),_c('h4',{staticClass:"num"},[_vm._v(" "+_vm._s(_vm.getProp(_vm.salesReport, "salesTotalQuantity.count", "") || 0)+" ")]),_c('p',[_c('span',[_vm._v(" 环比 "),_c('em',{staticClass:"down",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalQuantity.HB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.salesReport, "salesTotalQuantity.HB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalQuantity.HB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalQuantity.HB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])]),_c('p',[_c('span',[_vm._v(" 同比 "),_c('em',{staticClass:"up",style:({
                color: _vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalQuantity.TB', '0')
                )
              })},[_vm._v(_vm._s(_vm.getProp(_vm.salesReport, "salesTotalQuantity.TB", "")))]),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalQuantity.TB', '0')
                ) == '#FC5260')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/上升@2x.png")}}):_vm._e(),(_vm.getHomeColor(
                  _vm.getProp(_vm.salesReport, 'salesTotalQuantity.TB', '0')
                ) == '#1890FF')?_c('img',{staticClass:"topOrdown",attrs:{"src":require("./../../../assets/home/下降@2x.png")}}):_vm._e()])])])]),_c('li',{staticClass:"box-shadow box-radius"},[_c('pie',{attrs:{"data":_vm.pieChartData}})],1)])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"show-more hvr-icon-forwardgnegduo"},[_vm._v(" 查看更多 "),_c('i',{staticClass:"iconfont icon-shouye-gonggao-youdianji hvr-icon"})])
}]

export { render, staticRenderFns }