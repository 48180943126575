<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2021-02-22 17:55:37
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div class="regionInfo-box" v-if="userInfo">
    <div class="region-box box-radius">
      <div class="welcome-box">
        <div class="head-icon"></div>
        <div class="info-box">
          <h3>Hi，{{userInfo.account.userName}} 欢迎回来</h3>
          <h4>{{title}}</h4>
        </div>
      </div>
      <!--      <ul class="region-in-box" id="region">-->
      <!--        -->
      <!--          <li class="gc" @click="clickItem('工程专区')" >-->
      <!--            <div class="icon">-->
      <!--              <i class="iconfont icon-gongchengzhuanqu1"></i>-->
      <!--            </div>-->
      <!--            <h4 >工程专区</h4>-->
      <!--          </li>-->
      <!--       -->
      <!--       -->
      <!--          <li class="th"   @click="clickItem('特惠专区')">-->
      <!--            <div class="icon">-->
      <!--              <i class="iconfont icon-tehuizhuanqu1"></i>-->
      <!--            </div>-->
      <!--            <h4>特惠专区</h4>-->
      <!--          </li>-->
      <!--        -->
      <!--       -->
      <!--          <li class="tg" @click="clickItem('套购专区')">-->
      <!--            <div class="icon">-->
      <!--              <i class="iconfont icon-taogouzhuanqu1"></i>-->
      <!--            </div>-->
      <!--            <h4>套购专区</h4>-->
      <!--          </li>-->
      <!--       -->
      <!--       -->
      <!--          <li class="hd" @click="clickItem('活动专区')">-->
      <!--            <div class="icon">-->
      <!--              <i class="iconfont icon-huodongzhuanqu"></i>-->
      <!--            </div>-->
      <!--            <h4>活动专区</h4>-->
      <!--          </li>-->
      <!--     -->
      <!--     -->
      <!--          <li class="dz" @click="$message.info('该模块正在开发')">-->
      <!--            <div class="icon">-->
      <!--              <i class="iconfont icon-dingzhizhuanqu1"></i>-->
      <!--            </div>-->
      <!--            <h4>定制专区</h4>-->
      <!--          </li>-->
      <!--        -->
      <!--      </ul>-->
      <div class="shop-historyaaa swiper-box"  >
        <div class="swiper-father" style="position:relative">
          <div class="swiper-container" ref="mySwiper1" >
            <div class="swiper-wrapper">
              <div  class="swiper-slide" v-for="(items,index) in dataList2" :key="index" @click="clickItem(items)">
                <img style='width: 40px;height: 40px' :src="items.iconUrl" alt="">
                <p style="font-size: 12px;color: #777777;margin-top: 4px">{{items.sourceName}}</p>
              </div>
            </div>
          </div>
          <div v-if="dataList2.length > 5" class="swiper-button-prev animated fadeInRight aswiper-button-prevaa">
            <i class="icon iconfont icon-shangpin-zuo"></i>
          </div>
          <div v-if="dataList2.length > 5" class="swiper-button-next animated fadeInLeft">
            <i class="icon iconfont icon-shangpin-you"></i>
          </div>
        </div>

      </div>
    </div>
    <!-- 消息中心 -->
    <slot name="info-main" />
  </div>
</template>

<script>
// import util from "./util";
import {menuList} from "./../../../utils/api.js"
import { getImgInfo } from "@/views/home/api";
export default {
  name: "region",
  data() {
    return {
      menuList: [],
      dataList2: [],
      title: "快来看看最近的经营情况和最新消息吧！"
    };
  },
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    }
  },
  watch: {
    data: function() {},
    // dataList2: function() {
    //   this.$nextTick(() => {
    //     this.initSwiper()
    //   });
    // }

  },
  mounted() {
    //获取导航
    if (this.userInfo) {
      this.getMenuList()
      this.geticonImg()
    }
    if (this.userInfo) {
      let title = this.$configTotal({
        route: this.$route.fullPath,
        id: "14181431005"
      });
      this.title = title.msgDesc;
    }
    // window.addEventListener("resize", this.initEcharts);




  },
  methods: {
    // 获取自定义图标数据
    geticonImg() {
      let data = {
        clientPlatform:'WEBNEW'
      }
      getImgInfo(data).then(res =>{
        this.dataList2 = res.data.list
        this.$nextTick(() => {
          this.initSwiper()
        });
      })

    },
    // 图标个性化轮播图
    initSwiper () {
      let this_ = this;
      var mySwiper = new this.$Swiper(this.$refs.mySwiper1, {
        slidesPerView: 5,
        spaceBetween: 10,
        slidesPerGroup: 5,
        loop: false,
        loopFillGroupWithBlank: true,
        autoplay:false,
        onlyExternal: false,
        preventLinksPropagation:false,//防止冒泡。
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
        on: {
          slideChangeTransitionEnd: function(){
            if(this.isEnd){
              this.navigation.$nextEl.css('display','none');
            }else{
              this.navigation.$nextEl.css('display','block');
            }

          },
        }
      });
      // mySwiper.el.onmouseover = function() {
      //   //鼠标放上暂停轮播
      //   mySwiper.autoplay.stop();
      // };
      // mySwiper.el.onmouseleave = function() {
      //   mySwiper.autoplay.start();
      // };
    },
    // 获取菜单列表
    getMenuList() {
      //调用接口
      let params = { pageNo: 1, pageSize: 20,  loginPlant:'WEBNEW'};
      menuList(params)
        .then(res => {
          this.dataList = res.data.list;
        })
        .catch(error => {
          console.log(error);
        });
    },
    // 点击图标触发
    clickItem(name) {
      if(name.sourceName.indexOf('学堂') !== -1) {
        // this.$router.push({path:name.url});
        window.open(name.url,'_blank')
        return;
      }
      let bb = isHas(name.sourceName,this.dataList)
      // 循环遍历获取所有的菜单 有一致的就return出去
      function isHas(name, dataList) {
        for(let i = 0;i < dataList.length;i++ ) {
          let aaItem = dataList[i].subMenuList
          for(let j = 0;j< aaItem.length;j++){
            let bbItem = aaItem[j].subMenuList
            for(let c = 0;c< bbItem.length;c++){
              if(bbItem[c].sourceName == name) {
                return bbItem[c]
              }
            }
          }
        }
        return false
      }
      if(this.dataList.length > 0) {
        // 如果点击的url 为1的话就不提示 否则跳转
        if(bb) {
          if(bb.url == '1') {
            this.$message.info('该模块正在开发！')
            return
          } else {
            this.$router.push({path:name.url});
          }

        } else {
          this.$message.info("暂无权限");
        }
      }else {
        this.$message.info("暂无权限");
      }
    }
  }
};
</script>
<style lang="less">
.regionInfo-box {
  width: 1188px;
  position: absolute;
  top: 472px;
  left: calc(50% - 594px);
  z-index: 9999;
  .region-box {
    width: 452px;
    height: 164px;
    box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.06);
    float: left;
    .welcome-box {
      width: 460px;
      height: 88px;
      // box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.04);

      // border-radius: 4px;
      float: left;
      padding-left: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .head-icon {
        width: 54px;
        height: 54px;
        line-height: 54px;
        text-align: center;
        // background-color: #f5f5f5;
        border-radius: 50%;
        background: url(../../../assets/common/head-icon.png) no-repeat center;
        background-size: 100% 100%;
        // i {
        //   font-size: 30px;
        //   color: #999;
        // }
      }
      .info-box {
        margin-left: 16px;
        text-align: left;
        h3 {
          height: 22px;
          font-size: 16px;
          color: #262626;
          line-height: 22px;
        }
        h4 {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #777;
          line-height: 20px;
          margin-top: 4px;
        }
      }
    }
    .region-in-box {
      // width: 716px;
      width: 100%;
      height: 56px;
      // box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.04);
      // float: right;
      padding: 0 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      li {
        width: 48px;
        height: 54px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 36px;
          height: 36px;
          border-radius: 18px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
          line-height: 36px;
          text-align: center;
          overflow: hidden;
          i {
            font-size: 22px;
            -webkit-background-clip: text;
            color: transparent;
            display: none;
          }
        }
        &.gc .icon {
          // background: linear-gradient(180deg, #8fe7e3 0%, #59cdc8 100%);
          i {
            display: none;
            // background-image: linear-gradient(151deg, #ffffff 0%, #c4e9e7 100%);
          }

          background-image: url("~@/assets/home/gc-item3.png");
        }
        &.th .icon {
          // background: linear-gradient(180deg, #feacab 0%, #fa7971 100%);
          i {
            // background-image: linear-gradient(151deg, #ffffff 0%, #FFCCCB 100%);
          }
          background-image: url("~@/assets/home/th-item3.png");
        }
        &.tg .icon {
          // background: linear-gradient(180deg, #b6e1fe 0%, #76b1ee 100%);
          i {
            // background-image: linear-gradient(151deg, #ffffff 0%, #d8e3ee 100%);
          }
          background-image: url("~@/assets/home/tg-item3.png");
        }
        &.hd .icon {
          // background: linear-gradient(165deg, #f6e29c 0%, #f08243 100%);
          i {
            display: none;
            // background-image: linear-gradient(151deg, #ffffff 0%, #f7d4a7 100%);
          }
          background-image: url("~@/assets/home/hd-item3.png");
          // background-image: url("../../../assets/home/region-hd.png");
        }
        &.dz .icon {
          // background: linear-gradient(180deg, #bfc1f7 0%, #8385e9 100%);
          i {
            // background-image: linear-gradient(152deg, #ffffff 0%, #c4c5f4 100%);
          }
          background-image: url("~@/assets/home/dz-item3.png");
        }
        h4 {
          height: 12px;
          line-height: 12px;
          font-size: 12px;
          color: #777777;
          // margin-top: 6px;
        }
        &:hover {
          h4 {
            color: #00aaa6;
          }
        }
      }
      .gc {
      }
    }
  }
  .info-main {
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    width: 730px;
    height: 164px;
    float: right;
    .info-nav-box {
      width: 100%;
      height: 52px;
      padding: 0 24px;
      display: flex;
      // justify-content: center;
      align-items: center;
      h1 {
        width: 98px;
        height: 30px;
        // height: 26px;
        font-size: 20px;
        color: #262626;
        // line-height: 26px;
        line-height: 30px;
        float: left;
        text-align: left;
        // margin-top: 11px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          right: 0;
          top: 7px;
          width: 1px;
          height: 16px;
          background: #eeeeee;
          // background: #000;
        }
      }
      .nav-box {
        float: left;
        margin-left: 10px;
        span {
          width: 66px;
          height: 28px;
          line-height: 28px;
          border-radius: 14px;
          margin-right: 6px;
          // text-align: left;
          // text-indent: 34px;
          font-size: 12px;
          color: #777;
          transition: all 0.2s;
          // background-color: #ECF8F8;
          background-position: left center;
          background-repeat: no-repeat;
          cursor: pointer;
          float: left;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          text-indent: 24px;
          // &.gg{
          //   background-image:url('~@/assets/home/region-gc.png');
          //   background-size: 19px 19px;
          // }
          // &.db{
          //   background-image:url('~@/assets/home/region-th.png');
          //   background-size: 20px 19px;
          // }
          // &.tz{
          //   background-image:url('~@/assets/home/region-tg.png');
          //   background-size: 16px 20px;
          // }
          > i {
            font-size: 19px;
            // width: 19px;
            // height: 19px;
            background-image: linear-gradient(180deg, #bfc1f7 0%, #8385e9 100%);
            -webkit-background-clip: text;
            color: transparent;
            margin-right: 6px;
            display: none;
          }
          em {
            display: inline-block;
          }
          &:hover {
            em {
              color: #00aaa6;
            }
          }
          &.active {
            background-color: #ecf8f8 !important;
            em {
              color: #00aaa6;
            }
          }
          &.noread::after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background: #d9161c;
            border: 1px solid #ffffff;
          }
          &.gg {
            background: url("~@/assets/home/info-gg-icon.png") no-repeat 7px
            center;
            background-size: 20px 20px;
            &::after {
              left: 20px;
              top: 3px;
            }
          }
          &.db {
            background: url("~@/assets/home/info-db-icon.png") no-repeat 7px
            center;
            background-size: 20px 20px;
            i {
              background-image: linear-gradient(
                180deg,
                #8fe7e3 0%,
                #59cdc8 100%
              );
            }
            &::after {
              left: 19px;
              top: 3px;
            }
          }
          &.tz {
            background: url("~@/assets/home/info-tz-icon.png") no-repeat 7px
            center;
            background-size: 20px 20px;
            i {
              background-image: linear-gradient(
                180deg,
                #b6e1fe 0%,
                #76b1ee 100%
              );
            }
            &::after {
              left: 20px;
              top: 3px;
            }
          }
        }
      }
      .more {
        position: absolute;
        cursor: pointer;
        height: 16px;
        top: 18px;
        right: 24px;
        float: right;
        color: #777;

        font-size: 14px;
        i {
          margin-left: 6px;
          font-size: 12px;
        }
      }
    }
    .info-box {
      > li {
        width: 100%;
        height: 102px;
        // background: rgba(255, 255, 255, 1);
        float: left;
        position: relative;
        background-repeat: no-repeat;
        // &.gg-box {
        //   background-image: url("../../../assets/home/info-gg.png");
        //   background-position: 251px 52px;
        // }
        // &.db-box {
        //   background-image: url("../../../assets/home/info-db.png");
        //   background-position: 253px 51px;
        // }
        // &.tz-box {
        //   background-image: url("../../../assets/home/info-tz.png");
        //   background-position: 248px 46px;
        // }
        .flag {
          width: 78px;
          height: 20px;
          position: absolute;
          top: 16px;
          left: 16px;
          // z-index: 2;
          line-height: 20px;
          text-align: left;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC, Microsoft YaHei;
          text-indent: 11px;
          color: #262626;
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 14px;
            left: 0;
            top: 4px;
            border-radius: 2px;
          }
          &.gg::before {
            background: #00aaa6;
          }
          &.db::before {
            background: #ff8f3c;
          }
          &.tz::before {
            background: #fd6a6e;
          }
        }
        .more {
          width: 30px;
          height: 30px;
          position: absolute;
          text-align: center;
          line-height: 30px;
          top: 12px;
          right: 6px;
          cursor: pointer;
          // z-index: 2;
          &:hover {
            i {
              color: #00aaa6;
            }
          }
          i {
            color: #999;
            line-height: 30px;
            font-size: 12px;
          }
        }
        ol {
          padding: 55px 17px 0 15px;
          li {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            color: rgba(170, 170, 170, 1);
            cursor: pointer;
            .info-txt {
              // width: 252px;
              width: calc(100% - 80px);
              float: left;
              text-align: left;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .info-date {
              text-align: right;
              float: right;
              font-weight: 400;
            }
            &:hover {
              color: rgba(38, 38, 38, 1);
            }
          }
          li + li {
            margin-top: 4px;
          }

          li:first-child {
            // color: rgba(38, 38, 38, 1);
          }
        }
      }

      > li + li {
        margin-left: 12px;
      }
    }
  }
  + .limit-shop-box {
    margin-top: 96px;
  }
  + .commodity-box {
    padding-top: 96px;
  }
}
</style>

<style lang="less" scoped>
.shop-historyaaa.swiper-box {
  width: 452px!important;
  height: 164px!important;
  margin: 0 auto;
  .swiper-father {
    position: relative;
    padding:0 25px;
    width: 100%;
    height: 100%;
    box-sizing:border-box;
    //border:1px solid red;

  }
  .swiper-father .swiper-button-next,.swiper-father .swiper-button-prev {
    width: 18px;
    height: 36px;
    background: #3f6cc7;

    text-align: center;
    display: block;
    margin-top: 17px;
    align-items: center;
    line-height: 36px;
    i {
      font-size: 12px;
    }
  }

  .swiper-father  .swiper-button-prev {
    //background:#F2F3F5  url("./../../../assets/noticeList/加@2x.png") no-repeat center center;
    left:0;
    background:#F2F3F5 ;
    background-size: 10px 10px;
    border-radius: 0px 8px 8px 0px;
  }

  .swiper-father .swiper-button-next {
    //background:#F2F3F5  url("./../../../assets/noticeList/减@2x.png") no-repeat center center;
    right:0;
    background-size: 10px 10px;
    background:#F2F3F5 ;
    border-radius: 8px 0px 0px 8px;
  }
  .swiper-container {
    //width:calc(100% - 10px);
    margin:auto;
    width: 100%;
  }
  .swiper-slide {
    //height: 120px;
    width: 100%;
    box-sizing: border-box;
    //border:1px solid #f00;
    margin-right: 10px!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }


  //.swiper-father .swiper-button-next,
  //.swiper-father .swiper-button-prev {
  //  display: none;
  //
  //}
  //.swiper-father:hover .swiper-button-next,
  // .swiper-father:hover .swiper-button-prev {
  //  display: block;
  //
  //}
}
</style>