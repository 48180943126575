import { mapState } from "vuex";
// 导入api接口
import {
  getPrices, //获取产品价格接口
  getStocks, //获取产品库存接口
  homepageNormalSalesReport
} from "@/utils/api.js";
import { announDetail, saveViewWait } from "@/views/center/announcement/detail/api.js";
import { publicPath } from "@/common/constant.js";
import {
  //未读公告数量、未处理待办数量、未读消息数量接口
  getUnTreatNum,
  //待办接口
  delegateList,
  //通知接口
  tzList,
  //公告接口
  ggList,
  //通知未读消息转换为已读消息的接口d
  tzRead,
  //商家浏览记录接口
  saveCustBrowseRecord,
  getHomeProductList,
  getMarket, getnotRead, getprivacyPolicyInfo
} from "./api.js";
import { defaultImgPath, infoImgUrl } from "@/common/constant.js";

import util from "./components/util";
import tableUtil from "@/components/global/ToolTable/util";
import banner from "./components/Banner";
import region from "./components/Region&Info";
import infoItem from "./components/InfoItem";
import operation from "./components/Operation";
import evaluationOfcoreFunctions from "@/views/home/components/evaluationOfcoreFunctions";
// import limitShop from "./components/LimitShop";
import limitShop from "./components/LimitShopNew";
import limitShopBox from "./components/LimitShopBox";
import commodityBox from "./components/CommodityBox";
import zoneBox from "./components/Zone";

import cardActiveItem from "./components/CardActiveItem";
import cardNavItem from "./components/CardNavItem";
import unreadMessage from "./components/unreadMessage";
// 获取菜单列表
// import {menuList} from "./../../../utils/api.js"
import { menuList } from "@/utils/api.js";
import { getInvStatus } from "@/views/stockCenter/adjustmentApply/components/api";
import Util from "@/utils/utils";
import { env } from "@/common/constant";


export default {
  name: "Home",
  components: {
    banner,
    region,
    operation,
    unreadMessage,
    evaluationOfcoreFunctions,
    infoItem, //信息滚动
    limitShop, //限时抢购
    limitShopBox,
    commodityBox,
    zoneBox,
    cardActiveItem,
    cardNavItem
  },
  data() {
    return {
      //组建用
      // tcolumns:tableUtil.tcolumns,
      tcolumns: [],
      tableData: [],
      //x 最大宽度 y 最大高度
      // scroll:{ x: 2100, y: 600},
      tableWidth: 1188,
      scroll: { x: this.tableWidth },
      modelDataConfirm: [], // 查询结果
      pagination: {
        pageNo: 1,
        pageSize: 10,
        count: tableUtil.tableData.length
      },
      sumKey: "",
      loading: false,
      //结束

      guideFirstModal: require("@/assets/common/guideFirstModal.png"),
      guideLastModal: require("@/assets/common/guideLastModal.png"),
      componentsArr: [
        { code: "banner", name: "banner" },
        { code: "region", name: "region" },
        { code: "unreadMessage", name: "unreadMessage" },
        { code: "operation", name: "operation" },
        // 核心职能评价 1.10版本不上
        { code: "evaluationOfcoreFunctions", name: "evaluationOfcoreFunctions" },
        { code: "limit-shop-box", name: "limitShopBox" },
        { code: "commodity-box", name: "commodityBox" },
        { code: "zone-box", name: "zoneBox" }
      ],

      pageLoadFlag: false,
      //轮播
      bannerBaseUrl: "",
      bannerList: [],
      //公告 待办 通知信息
      ggList: [],
      dbList: [],
      tzList: [],
      infoType: "gg", //信息默认活动状态 公告
      // infoData: [],
      dataList: [],
      // pieChartData: [],
      productList: [],
      productListAll: {},
      productImageUrl: "",
      hotImage: "",
      cartNum: 0,
      marketList: [], //抢购活动展示列表
      marketListAll: [], //抢购活动全部列表
      marketAcitveItemData: [],
      defaultImgPath: defaultImgPath,
      infoImgUrl: infoImgUrl,
      key: 18787,
      fadeFlag: true, //首页淡入动画loadding 防止引导层级问题
      steps: [
        {
          element: "#headNav",
          popover: {
            title: "顶部快捷功能",
            description:
              "顶部可快速访问订单中心、下载中心、网站导航、收藏夹、购物车以及退出入口哟~",
            position: "bottom"
          }
        },
        {
          element: "#productClass",
          popover: {
            title: "商品分类可收起",
            description:
              "商品分类在这里，可以快速查看二级分类，点击这里可以收起和展开哟~~",
            position: "bottom"
          }
        },
        {
          element: "#menu",
          popover: {
            title: "导航区域可快速切换模块",
            description: "鼠标移动切换模块，可以快速访问相应的功能~",
            position: "bottom"
          }
        },
        {
          element: "#region",
          popover: {
            title: "专区入口",
            description: "各种专区的入口迁移到了这里，是不是很好找~",
            position: "bottom"
          }
        },
        {
          element: "#info",
          popover: {
            title: "信息中心",
            description: "最新的公告、待办和通知请及时关注哟~",
            position: "bottom"
          }
        },
        {
          element: "#rx-nav",
          popover: {
            title: "热销爆款",
            description: "各种新品、爆款请关注这里哟~",
            position: "bottom"
          },
          onDeselected: () => {
            this.guideLastVisible = true;
            this.$store.commit("user/SET_LOGIN_LATER_FLAG", true);
          }
        }
      ],
      guideFirstVisible: false,
      guideLastVisible: false,
      firstGGVisible: false,
      GGDetailArr: null,
      ggDetailFlag: false,
      //摧残专区
      zoneObj: {},
      infoTimer: "",
      // 报表数据
      reportData: {
        normalSalesReport: {}
      },
      menuList: [],
      showActiveBox: undefined,// 是否显示活动专区
      subMenuList: [],
      isPlay: true,//公告待办通知切换开关
      noReadVisible: false,//未读数据弹窗
      unreadMsgObj: {//未读数据
        msgNum: 0,
        noticeNum: 0,
        questionNum: 0,
        taskNum: 0,
        cgrk: 0,
        ddsh: 0,
        xsck: 0,
        return_wait_inbound: 0,
        return_wait_outbound: 0
      },
      noReadVisibleNum: 1,
      userAgreement: false,// 用户协议
      privacyPolicyInfor: {},
      showRR:false,
      showShBox:false,
      showCk:false,
      showWenQ:false,
      showActiveDBox:false

    };
  },

  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    },
    ...mapState({
      //用于查询列表的筛选配置字段
      wlzImgsArr: (state) =>
        state.user.wlzImgsArr
          ? state.user.wlzImgsArr
          : localStorage.getItem("wlzImgsArr")
    })
  },
  watch: {
    userInfo: {
      handler(newData, oldData) {
        if (
          this.userInfo &&
          JSON.stringify(newData) &&
          JSON.stringify(newData) != JSON.stringify(oldData) &&
          (localStorage.getItem("loginLaterFlag")
            ? !JSON.parse(localStorage.getItem("loginLaterFlag"))
            : true)
        ) {
          setTimeout(() => {
            //设置状态 防止元素点击
            // this.$store.commit("user/SET_LOGIN_LATER_FLAG", false);
            //打开引导弹窗
            // this.guideFirstVisible = true;
          }, 1000);

          // //判断登陆状态
          // if (this.$route.path == "/index" && $("#menu")) {
          //   //判断是首页且有dom元素
          //   this.$nextTick(() => {
          //     setTimeout(() => {
          //       this.$driver.defineSteps(this.steps);
          //       this.$driver.start();
          //     }, 1000);
          //   });
          // }
        }
      },
      immediate: true,
      deep: true
    },
    infoType: {
      handler() {
        clearTimeout(this.infoTimer);
        if (!this.isPlay) {
          return;
        }
        if (this.infoType == "gg" && this.ggList && this.ggList.length < 4) {
          this.infoTimer = setTimeout(() => {
            this.infoType = "db";
          }, 3000);
        } else if (
          this.infoType == "db" &&
          this.dbList &&
          this.dbList.length < 4
        ) {
          this.infoTimer = setTimeout(() => {
            this.infoType = "tz";
          }, 3000);
        } else if (
          this.infoType == "tz" &&
          this.tzList &&
          this.tzList.length < 4
        ) {
          this.infoTimer = setTimeout(() => {
            this.infoType = "gg";
          }, 3000);
        }
      },
      immediate: true
      // deep: true,
    }
  },
  beforeCreate() {
    let count = 0;
    let imgs = [
      //用require的方式添加图片地址，直接添加图片地址的话，在build打包之后会查找不到图片，因为打包之后的图片名称会有一个加密的字符串
      require("@/assets/common/guideFirstModal.png"),
      require("@/assets/common/guideLastModal.png")
    ];
    for (let img of imgs) {
      let image = new Image();
      image.onload = () => {
        count++;
      };
      image.src = img;
    }
  },
  created() {
    let timer = setTimeout(() => {
      this.fadeFlag = false;
      clearTimeout(timer);
    }, 1000);

    // this.tcolumns = tableUtil.tcolumns
    this.tcolumns = localStorage.getItem(this.$route.path + "TableColumns")
      ? JSON.parse(localStorage.getItem(this.$route.path + "TableColumns"))
      : tableUtil.tcolumns;
    // this.tableData = tableUtil.tableData
    this.sumKey = tableUtil.tcolumns[0].dataIndex;

    // for (const DataItem of this.tableData) {
    //   for (const columnItem of this.tcolumns) {
    //     for (const Datakey in DataItem) {
    //       for (const columnkey in columnItem) {
    //         if()
    //       }
    //     }
    //   }
    // }
    // setTimeout(()=>{
    let tableObj = {
      columns: localStorage.getItem(this.$route.path + "TableColumns")
        ? JSON.parse(localStorage.getItem(this.$route.path + "TableColumns"))
        : tableUtil.tcolumns,
      tableData: tableUtil.tableData
    };
    this.$store.dispatch("user/setTabelWidth", tableObj).then((resTableObj) => {
      // this.$nextTick(()=>{
      this.tcolumns = resTableObj.columns;
      this.tableData = resTableObj.tableData;
      this.tableWidth = resTableObj.tableWidth;
      this.$forceUpdate();
      this.key = 998798;
      // })
    });
    // })
  },
  mounted() {
    // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    window.addEventListener("scroll", this.handleScroll);
    //侧边栏默认隐藏
    this.$store.commit("user/SET_BAR_BOTTOM", true);

    //设置不跳转登录页标志
    this.$store.commit("user/SET_ISGOLOGIN", false);
    if (this.userInfo) {
      // 未读公告数量、未处理待办数量、未读消息数量接口
      this.getGetUnTreatNum();
      this.getDmsInfo();
      // 待办接口
      this.getDelegateList();
      // 通知接口
      this.getTzList();
      // this.closeAuto();
      //获取导航
      this.getMenuList()
      //公告接口
      this.getGgList();
    }

    //商家浏览记录接口
    // this.getSaveCustBrowseRecord();
    //首页商品列表
    this.getPorductList();

    //获取抢购列表
    // this.getMarketList();

    // this.infoData = util.infoData;
    // this.pieChartData = util.pieChartData;


    if (!this.userInfo) {
      this.consentToprivacyAgreement();
    }


  },
  methods: {
    // 获取隐私政策数据
    getprivacyPolicy() {
      let data = {
        loginPlant: "PC"
      };
      return getprivacyPolicyInfo(data);
    },
    //先获取缓存里是否已经同意  不同意弹出弹窗 同意不展示弹窗
    async consentToprivacyAgreement() {
      let res = await this.getprivacyPolicy()
      if(res.data.list.length > 0) {
        this.privacyPolicyInfor = res.data.list[0]
      }
      if(!localStorage.getItem("agreePrivacy") ) {
        this.userAgreement = true
        return
      }
      //先获取缓存里是否已经同意  不同意弹出弹窗 同意跳转页面
      if( localStorage.getItem('agreePrivacy') == 'false') {
        this.userAgreement = true
        return
      }
      // 如果缓存里为true 版本号和当前接口调用中的不一样 也会弹出弹窗
      if(localStorage.getItem('agreePrivacy') == 'true' && localStorage.getItem('UserAgreementVersionNumber') !== this.privacyPolicyInfor.pageKey ){
        this.userAgreement = true
        return
      }
    },
    // 点击按钮关闭
    closeX() {
      this.noReadVisible = false;
      this.noReadVisibleNum = 1;
      localStorage.setItem("noReadVisibleNum", this.noReadVisibleNum);
      //公告接口
      this.getGgList();
    },
    //获取dms未读消息条数
    getDmsInfo() {
      let data = {
        userAccount: this.$store.state.user.userInfo.account.account,
        module: "cgrk,ddsh,xsck,return_wait_outbound,return_wait_inbound"

      };
      getnotRead(data).then(res => {
        // 如果后端返回code为1 的话就保持原来的数据都是0
        if (res.data.code !== "1") {
          let reaData = JSON.parse(res.data);
          if (reaData.code == 0) {
            this.unreadMsgObj.cgrk = reaData.data.cgrk || 0,
              this.unreadMsgObj.ddsh = reaData.data.ddsh || 0,
              this.unreadMsgObj.xsck = reaData.data.xsck || 0,
              this.unreadMsgObj.return_wait_inbound = reaData.data.return_wait_inbound || 0,
              this.unreadMsgObj.return_wait_outbound = reaData.data.return_wait_outbound || 0;
          } else {
            this.unreadMsgObj.cgrk = 0,
              this.unreadMsgObj.ddsh = 0,
              this.unreadMsgObj.xsck = 0,
              this.unreadMsgObj.return_wait_inbound = 0,
              this.unreadMsgObj.return_wait_outbound = 0;
          }
        } else {
          this.unreadMsgObj.cgrk = 0,
            this.unreadMsgObj.ddsh = 0,
            this.unreadMsgObj.xsck = 0,
            this.unreadMsgObj.return_wait_inbound = 0,
            this.unreadMsgObj.return_wait_outbound = 0;
        }
      }).catch(error => {
        console.log(error);
      });
    },
    // 获取列表信息
    getMenuList() {
      //调用接口
      let params = { pageNo: 1, pageSize: 20, loginPlant: "WEBNEW" };
      menuList(params)
        .then(res => {
          if (res.data.code == 0 && res.data.list && res.data.list.length > 0) {

            for (let i = 0; i < res.data.list.length; i++) {
              if (res.data.list[i].sourceName === "采购") {
                let twoItem = res.data.list[i];
                for (let a = 0; a < twoItem.subMenuList.length; a++) {
                  if (twoItem.subMenuList[a].sourceName === "专区采购") {
                    let three = twoItem.subMenuList[a];
                    for (let bb = 0; bb < three.subMenuList.length; bb++) {
                      if (three.subMenuList[bb].sourceName === "活动专区") {
                        this.showActiveBox = true;
                        break;
                      } else {
                        this.showActiveBox = false;
                      }
                    }
                    break;
                  } else {
                    this.showActiveBox = false;
                  }
                }
              }
              if (res.data.list[i].sourceName === "经营") {
                let twoItem = res.data.list[i];
                for (let a = 0; a < twoItem.subMenuList.length; a++) {
                  if (twoItem.subMenuList[a].sourceName === "经营分析") {
                    this.subMenuList = twoItem.subMenuList[a].subMenuList;
                  }
                }
              }
            }
            if(res.data.list.length > 0) {
              this.showRR = Util.isHas("退货入库", res.data.list);
              this.showShBox = Util.isHas("销售订单（审核出库）", res.data.list);
              this.showCk = Util.isHas("退货明细", res.data.list);
              this.showWenQ = Util.isHas("调研问卷", res.data.list);
              this.showActiveDBox=Util.isHas("海信订单汇总（入库）", res.data.list);
            }


          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    search(arg) {
    },
    linkClick(obj) {
      if (obj.key == "orgId") {
        this.$router.push({
          path: "/product",
          query: {
            productId: obj.productCode
          }
        });
      }
    },
    rowClick(arr, arr2) {
    },
    pageSearch(pager) {
      this.pagination.pageNo = pager.pageNo;
      this.pagination.pageSize = pager.pageSize;
      // this.getData();
    },
    tableChange(list) {

      this.sumKey = list[0].dataIndex;
      // this.getData();
      let dataIndex = list[0].dataIndex;
      // // let this_ = this;
      // this.relColumns[this.relColumns.length - 1] = {
      //   key:258,
      //   outMem:"123456",
      // }
      // this.relColumns[this.relColumns.length - 1][dataIndex] = '合计'
      for (const key in this.tableData[this.tableData.length - 1]) {
        this.tableData[this.tableData.length - 1][key] = "";
      }
      this.tableData[this.tableData.length - 1].key = "258";
      this.tableData[this.tableData.length - 1].outMem = "1321654";
      this.tableData[this.tableData.length - 1][dataIndex] = "合计";
    },
    change(list) {
    },
    joinPageFun() {
      this.guideLastVisible = false;
      $("body,html").animate({ scrollTop: 0 }, 1000);

      setTimeout(() => {
        if (this.ggList && this.ggList.length > 0 && this.ggList[0].id) {
          this.getGGDetail(this.ggList[0].id);
        }
      }, 1000);
    },
    //引导 点击不用了
    guideNoIngFun() {
      this.guideFirstVisible = false;
      this.$store.commit("user/SET_LOGIN_LATER_FLAG", true);
      if (
        this.ggList &&
        this.ggList.length > 0 &&
        this.ggList[0].id &&
        this.ggList[0].isPop == 1
      ) {
        this.getGGDetail(this.ggList[0].id);
      }
    },
    //引导 点击好的
    guideIngFun() {
      this.guideFirstVisible = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$driver.defineSteps(this.steps);
          this.$driver.start();
        }, 300);
      });
    },
    componentProps(name) {
      let propObj = {};
      switch (name) {
        case "banner":
          propObj = {
            data: this.bannerList,
            baseUrl: this.bannerBaseUrl
          };
          break;
        case "region":
          propObj = {};
          break;
        case "operation":
          propObj = {};
          break;
        case "limitShopBox":
          propObj = {};
          break;
        case "commodityBox":
          propObj = {};
          break;
        case "zoneBox":
          propObj = {
            // data: util.zoneObj,
            data: this.zoneObj
          };
          break;
      }
      // if (name == 'banner') {
      //   return {
      //     data: this.bannerList,
      //     baseUrl:this.bannerBaseUrl
      //   };
      // }
      // return {}; // return empty object

      return propObj;
    },
    toInfoMoreFun() {
      let name;
      if (this.infoType == "gg") {
        name = "announcement";
      } else if (this.infoType == "db") {
        name = "todoLisWait";
      } else {
        name = "notice";
      }
      this.$router.push({
        name: name
      });
    },
    //活动换一批
    changeLimitFun() {
      //1接口中得到数据 求出总数num 假设 总数num = 5
      let lastKey;

      for (let key in this.marketListAll) {
        //2现已展示的最后一项 为 总数据第几项
        if (
          JSON.stringify(this.marketList[this.marketList.length - 1]) ===
          JSON.stringify(this.marketListAll[key])
        ) {
          lastKey = parseInt(key);
          break;
        }
      }

      if (this.marketListAll.length - lastKey > 3) {
        //假定lastKey 1->第二项  展示345
        this.marketList = this.marketListAll.slice(lastKey + 1, lastKey + 4);
      } else {
        let headItemLength = 3 - (this.marketListAll.length - lastKey - 1); //需要展示list数组开头的个数 (5 - lastKey - 1)->到最后的个数 3->轮播展示个数
        this.marketList = this.marketListAll
          .slice(lastKey + 1, this.marketListAll.length)
          .concat(this.marketListAll.slice(0, headItemLength));
      }
      // this.dataList = util.dataList;
      // },1000)
    },

    //判断侧边栏是否浮上来
    handleScroll() {
      if ($(window).scrollTop() > 0) {
        this.$store.commit("user/SET_BAR_BOTTOM", false);
      } else {
        this.$store.commit("user/SET_BAR_BOTTOM", true);
      }
    },

    getGetUnTreatNum() {
      let params = { pageNo: 1, pageSize: 20 };
      getUnTreatNum(params)
        .then((res) => {
          this.unreadMsgObj.msgNum = res.data.msgNum;
          this.unreadMsgObj.noticeNum = res.data.noticeNum;
          this.unreadMsgObj.questionNum = res.data.questionNum;
          this.unreadMsgObj.taskNum = res.data.taskNum;
          if(!localStorage.getItem("noReadVisibleNum")) {
            localStorage.setItem("noReadVisibleNum", 0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    infoChangeHover(isPlay) {
      if (isPlay) {
        this.isPlay = true;
      } else {
        this.isPlay = false;
        clearTimeout(this.infoTimer);
        return;
      }
    },
    //信息轮播切换
    infoChange(type, dataLength) {
      // alert(type)
      if (dataLength < 8) {
        // alert(11)
        let timer = setTimeout(() => {
          if (type == 1) {
            //
            this.infoType = "db";
          } else if (type == 2) {
            this.infoType = "tz";
          } else if (type == 3) {
            this.infoType = "gg";
          } else {
            this.infoType = "";
          }
          clearTimeout(timer);
        }, 5000);
      } else {
        // alert(12)
        let timer = setTimeout(() => {
          if (type == 1) {
            //
            this.infoType = "db";
          } else if (type == 2) {
            this.infoType = "tz";
          } else if (type == 3) {
            this.infoType = "gg";
          } else {
            this.infoType = "";
          }
          clearTimeout(timer);
        }, 3000);
      }
    },
    //获取公告信息
    getGgList() {
      let params = {
        loginType: "CS",
        pageNo: 1,
        type: 0, // 0 -> 待办
        pageSize: 20,
        deleteType:1
      };
      ggList(params)
        .then((res) => {
          if (res.data.list && res.data.list.length > 0) {
            this.ggList = res.data.list.slice(0, 10);
            if (
              res.data.list[0].isPop == 1
            ) {
              // 必弹
              this.firstGGVisible = true;
              this.GGDetailArr = res.data.list[0];
            }
          } else {
            this.ggList = [];

          }

        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取公告详情
    getGGDetail(id) {
      this.ggDetailFlag = true;
      announDetail({ id: id })
        .then((res) => {
          this.firstGGVisible = true;
          this.GGDetailArr = res.data.billboardMessage;
          this.ggDetailFlag = false;

        })
        .catch((error) => {
          this.ggDetailFlag = false;
        });
    },
    gotoDetail(GGDetailArr) {
      this.firstGGVisible = false;
      this.$router.push({
        name: "announcementDetail",
        query: {
          id: GGDetailArr.id,
          name: "home"
        }
      });
      // let routeUrl = this.$router.resolve({
      //
      // });
      // window.open(routeUrl.href, "_blank");
    },
    
    isContentEmpty(tagContent) {
      // 使用DOMParser来解析字符串为DOM元素
      var parser = new DOMParser();
      var doc = parser.parseFromString(tagContent, 'text/html');
      // 获取解析后的元素的纯文本内容（不包含标签）
      var textContent = doc.body.textContent || "";
      // 去除字符串前后的空格再判断是否为空字符串
      return textContent.trim() === "";
    },
    // 我已阅读 关闭且调详情接口->已读
    readGG(id){
      this.firstGGVisible = false;
      
      announDetail({ id: id }).then((res)=>{
        if( res.data&&res.data.billboardMessage&&res.data.billboardMessage.content) {
          //  如果解析出来content是空 就保持原来逻辑 否则就跳转详情
          if(!this.isContentEmpty( res.data.billboardMessage.content)) {
            this.$router.push({
              name: "announcementDetail",
              query: {
                id: id,
                name:'home'
              },
            });
          }else {
            // 公告接口 获取下一个未读公告
             this.getGgList();
           }
        }
      })
    },
    // 稍后查看
    waitLookGG(id){
      this.firstGGVisible = false;
      saveViewWait({ id: id }).then(()=>{
      })
    },
    downFile(item) {
      // window.open('https://3s-static.hisense.com/notice/'+item.filePath[0],"_blank");
      window.open(
        "http://nginx-proxy-cis.devapps.hisense.com/notice/" + item.filePath,
        "_blank"
      );
    },
    //获取待办信息
    getDelegateList() {
      let params = {
        loginType: "CS",
        pageNo: 1,
        status: 0, // 0 -> 待办
        pageSize: 20
      };
      delegateList(params)
        .then((res) => {
          if (
            res.data.priceDelegateMessageList &&
            res.data.priceDelegateMessageList.length > 0
          ) {
            this.dbList = res.data.priceDelegateMessageList.slice(0, 10);
          } else {
            this.dbList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //获取通知信息
    getTzList() {
      let params = {
        loginType: "CS",
        pageNo: 1,
        pageSize: 20,
        status: 0 // 0 -> 未读
      };
      tzList(params)
        .then((res) => {
          if (
            res.data.priceMessageList &&
            res.data.priceMessageList.length > 0
          ) {
            this.tzList = res.data.priceMessageList.slice(0, 10);
          } else {
            this.tzList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //把未读消息转换为已读消息的接口
    setRead(type, id, sourceId, infoType, typeName, typeCode) {
      // this.$store.commit("user/SET_IS_OPEN_PAGE");
      let params = {
        priceMessageId: id
      };
      tzRead(params)
        .then((res) => {
        })
        .catch((error) => {
          console.log(error);
        });
      if (type == 1) {
        let routeUrl = this.$router.resolve({
          name: "announcementDetail",
          query: {
            id: id,
            name: "home"
          }
        });
        window.open(routeUrl.href, "_blank");
      }
      if (type == 2) {
        // this.$router.push({
        //   name: 'todoList',
        // })
        //财务待办14170681475 合同待办14170681476 意见征询待办14173612879 巡店待办14173612880 整改通知14173612881 退货待办14182051644 终端管理待办14182972398 终包采购计划提报14182972401 终包收货提报14182972402 信天翁账户档案绑定提醒14183445135
        if (typeCode == "14173612880" || typeCode == "14182972398" || typeCode == "14183445135") {
          this.$message.info(`请到信天翁移动端处理${typeName}`);
          return;
        }
        if (typeCode == "14170681475") {
          const url = publicPath + "/redirect.nd?loginPlant=loginId&urlCode=XTW_DUIZHANG_URL";
          window.open(url, "_blank");
        }
        if (typeCode == "14170681476") {
          this.$message.info(`请点击查看更多，到列表页操作`);
        }
        if (typeCode == "14173612881") {
          let routeUrl = this.$router.resolve({
            path: "/center/inspection",
            query: { id: sourceId }
          });
          window.open(routeUrl.href, "_blank");
          return;
        }
        if (typeCode == "14182051644") {

          let routeUrl = this.$router.resolve({
            path: "/hisenseReturn/ReturnApplicationDetails",
            query: { id: sourceId }
          });
          window.open(routeUrl.href, "_blank");
          return;
        }
        if (typeCode == "14182972401") {

          let routeUrl = this.$router.resolve({
            path: "/threeQualityManagement/thirdQradeReceivingDetail",
            query: { taskId: id }
          });
          window.open(routeUrl.href, "_blank");
          return;
        }
        if (typeCode == "14182972402") {

          let routeUrl = this.$router.resolve({
            path: "/threeQualityManagement/thirdQradeReportDetail",
            query: { taskId: id }
          });
          window.open(routeUrl.href, "_blank");
          return;
        }
        if (typeCode == "14173612879") {
          let routeUrl = this.$router.resolve({
            path: "/center/tododetail/detail",
            query: {
              id: id
            }
          });
          window.open(routeUrl.href, "_blank");
        }
      }

      //待办跳转
      if (type == 3) {
        if (infoType == "订单状态变更") {
          let routeUrl = this.$router.resolve({
            path: "/order/detail",
            query: { id: sourceId }
          });
          window.open(routeUrl.href, "_blank");
          // this.$router.push({
          //   path: "/order/detail",
          //   query: { id: sourceId }
          // });
        }

        if (infoType == "电子合同到期通知") {
          let routeUrl = this.$router.resolve({
            path: "/center/notice",
            query: {
              filter: "type:" + "14171893228",
              onlyUserParam: true
            }
          });
          window.open(routeUrl.href, "_blank");

        }
      }
    },
    borderColorFun(index) {
      let idx = index % 4;
      let colorList = ["#FF8F3C", "#00B7B3", "#4285FF", "#D9161C"];
      return colorList[idx];
    },

    getSaveCustBrowseRecord() {
      let params = { pageNo: 1, pageSize: 20 };
      saveCustBrowseRecord(params)
        .then((res) => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 根据产品列表获取价格
    async getPricesList(param, idx) {
      try {
        const res = await getPrices(param);
        // 源数据赋值库存
        if (res.statusText == "OK") {
          //抢购
          if (parseFloat(idx).toString() == "NaN") {
            for (const key in res.data.list) {
              // 标准价格
              this.$set(this.productList[key], "price", res.data[key].price);
              // 零售价格
              this.$set(
                this.productList[key],
                "retailPrice",
                res.data[key].retailPrice
              );
            }
          } else {
            for (const key in res.data.list) {
              // 标准价格
              this.$set(
                this.marketList[idx].hotProductDTOs[key],
                "price",
                res.data[key].price
              );
              // 零售价格
              this.$set(
                this.marketList[idx].hotProductDTOs[key],
                "retailPrice",
                res.data[key].retailPrice
              );
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 根据产品列表获取库存 暂时不用
    async getStocksList(param) {
      try {
        const res = await getStocks(param);
        // 源数据赋值库存
        if (res.statusText == "OK") {
          for (const key in res.data.list) {
            // 海信库存
            this.$set(
              this.productList[key],
              "inventory",
              res.data[key].inventory
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取首页商品列表
    async getPorductList() {
      this.pageLoadFlag = true;
      try {
        const res = await getHomeProductList({ ly: 3 });
        this.productList = res.data.hotProductDTOs;
        this.productListAll = res.data;
        this.productImageUrl = res.data.productImageUrl;

        if (res) {
          this.pageLoadFlag = false;
          //记录物料组图片
          this.$store.commit("user/SET_WLZ_IMGS", res.data.activityTitleImage);
          if (this.userInfo) {
            //限时抢购
            this.getMarketList();
          }
        }
        //记录图片头路径
        this.$store.dispatch(
          "user/productImgBaseUrl",
          res.data.productImageUrl
        );
        //璀璨专区
        if (res.data.hotProductDTOs7 && res.data.hotProductDTOs7[0]) {
          this.zoneObj = {
            // title:res.data.hotProductDTOs7.imageName,
            title: "璀璨·成套家电专区",
            //url: res.data.galleryImageUrl + res.data.hotProductDTOs7[0].imagePath,
            url: 'http://3s-static.hisense.com/menuIcon/20240703-41d074c5-8c25-4e8e-a31f-96c596339c54.png',
            path: res.data.hotProductDTOs7[0].jumpPath
          };
        }
        // this.hotImage = res.data.hotTitleImage.tvImageName;
        this.hotImage = res.data.hotTitleImageNew.tvImageName;
        this.bannerBaseUrl = env == 'test' ? 'http://3s-static.hisense.com/gallery/' : res.data.galleryImageUrl;
        // this.bannerList = util.bannerList;
        this.bannerList = res.data.topPictureList;
        if (res) {
          let param = {
            code: [],
            orgId: [],
            orgCode: []
          };
          for (const key in this.productList) {
            param.code.push(this.productList[key].productCode);
            param.orgId.push(this.productList[key].orgId);
            param.orgCode.push(this.productList[key].orgCode);
          }
          if (this.userInfo) {
            //热销商品 暂无需查价格
            // this.getPricesList(param);
          }
        }
      } catch (error) {
        console.log(error);
        this.pageLoadFlag = false;
      }
    },
    /**
     * 切换产品
     * ds:电视
     * kt：空调
     * bx：冰箱
     * xyj：洗衣机
     * lg：冷柜
     * stcp：生态产品
     */
    productTableClick(e) {
      const {
        hotProductDTOs,
        hotProductDTOs1,
        hotProductDTOs2,
        hotProductDTOs3,
        hotProductDTOs4,
        hotProductDTOs5,
        hotProductDTOs6,
        hotProductDTOs8
      } = this.productListAll;
      const {
        sjImageName,
        tvImageName,
        ktImageName,
        bxImageName,
        xyjImageName,
        lgImageName,
        cwImageName,
        stcpImageName
      } = this.productListAll.hotTitleImageNew;
      let productList = [];
      let hotImage = "";
      switch (e) {
        case "ds":
          productList = hotProductDTOs;
          hotImage = tvImageName;
          break;
        case "kt":
          productList = hotProductDTOs1;
          hotImage = ktImageName;
          break;
        case "xyj":
          productList = hotProductDTOs2;
          hotImage = xyjImageName;
          break;
        case "sj":
          productList = hotProductDTOs3;
          hotImage = sjImageName;
          break;
        case "lg":
          productList = hotProductDTOs4;
          hotImage = lgImageName;
          break;
        case "bx":
          productList = hotProductDTOs5;
          hotImage = bxImageName;
          break;
        case "cw":
          productList = hotProductDTOs6;
          hotImage = cwImageName;
          break;
        case "stcp":
          productList = hotProductDTOs8;
          hotImage = stcpImageName;
          break;
      }
      this.productList = productList;
      this.hotImage = hotImage;

      let param = {
        code: [],
        orgId: [],
        orgCode: []
      };
      for (const key in this.productList) {
        param.code.push(this.productList[key].productCode);
        param.orgId.push(this.productList[key].orgId);
        param.orgCode.push(this.productList[key].orgCode);
      }
      // this.getStocksList(param);
    },

    // // 根据产品列表获取价格
    // async getPricesList(param) {
    //   try {
    //     const res = await getPrices(param);
    //     // 源数据赋值库存
    //     if (res.statusText == "OK") {
    //       for (const key in res.data.list) {
    //         // 标准价格
    //         this.$set(
    //           this.productList[key],
    //           "price",
    //           res.data[key].price
    //         );
    //         // 零售价格
    //         this.$set(
    //           this.productList[key],
    //           "retailPrice",
    //           res.data[key].retailPrice
    //         );
    //       }
    //     }

    //   } catch (error) {
    //     console.log(error);
    //   }
    // },

    //活动切换
    activeItemChange(item) {
      for (const activeItem of this.marketList) {
        if (activeItem.activityCode == item.activityCode) {
          this.marketAcitveItemData = item.productDtoList;
          this.$store.commit("user/getMatkImg", {
            matkId: this.marketAcitveItemData[0].matklId,
            productCode: this.marketAcitveItemData[0].productInfoId
          });
        }
      }
      // if(item.activityCode)
    },
    //获得活动默认图
    getActiveDftImg(e) {
      for (const item of this.wlzImgsArr) {
        // debugger
        if (item.matklIds?item.matklIds.indexOf(e.productDtoList[0].matklId) != -1:false) {
          //找到对应物料组 -》根据type拿图 -》dft默认 new新品 hot爆款 rx热销
          // let imgHeadUrl = this.marketAcitveItemData[0].matklId + '/' + this.marketAcitveItemData[0].productCode + '/180-180/' + item.mastreImage;
          return item.mastreImage;
        }
      }
    },
    //获取抢购列表
    async getMarketList() {
      try {
        let dataIng = {
          // activityName: "抢购",
          status: 2 //进行中
          // startDate: "2020-09-12",
          // endDate: "2020-09-30"
        };
        let marketListDataIng = await getMarket(dataIng);
        let dataWait = {
          // activityName: "抢购",
          status: 1 //等待进行
          // startDate: "2020-09-12",
          // endDate: "2020-09-30"
        };
        let marketListDataWait = await getMarket(dataWait);

        //升序排列，优先展示快到期的活动
        // let marketList = marketListData.data.list.sort((a, b) => {
        //   return a.endDate - b.endDate;
        // });

        if (marketListDataIng.data.list && marketListDataWait.data.list) {
          // debugger
          let marketList = [];
          //各自 最近开始的活动 排序
          marketListDataIng.data.list = marketListDataIng.data.list.sort(
            (a, b) => {
              return b.startDate - a.startDate;
            }
          );
          marketListDataWait.data.list = marketListDataWait.data.list.sort(
            (a, b) => {
              return b.startDate - a.startDate;
            }
          );
          // marketListDataIng.data.list = marketListDataIng.data.list.slice(0, 2)
          if (
            marketListDataIng.data.list &&
            marketListDataIng.data.list.length < 4
          ) {
            marketList = [
              ...marketListDataIng.data.list,
              ...marketListDataWait.data.list
            ].slice(0, 4);
          } else {
            marketList = marketListDataIng.data.list.slice(0, 4);
          }
          this.marketList = marketList;
          // let marketList = util.marketList.list;
          if (this.marketList && this.marketList[0]) {
            this.marketAcitveItemData = this.marketList[0].productDtoList;
          }


          //展示爆款等底图
          this.activeItemChange(this.marketList[0]);
        }
        //展示最近开始的活动
        // let marketList = marketListDataIng.data.list.sort((a, b) => {
        //   return b.startDate - a.startDate;
        // });

        // this.marketAcitveItemData = this.marketList[0].productDtoList;
      } catch (error) {
        console.log(error);
      }
    },
    goactivity() {
      this.$router.push({
        path: "/activity"
      });
    },
    onSearch() {
      alert("搜索");
    }
  }
};
