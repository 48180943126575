<!--
 * @Author: sqk
 * @Date: 2020-08-12 15:51:12
 * @LastEditTime: 2022-09-05 18:00:01
 * @LastEditors: xiaojie 1264801634@qq.com
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\CardItem\CardItem.vue
-->


<template>
  <div
    @click="goDetail(data)"
    class="card-ani-box animated fadeInRight"
    :style="{ animationDelay: (itemIndex + 1) * .1 + 's' }"
  >
    <div class="card-item-box box-shadow">
      <div class="color-line" :style="borderColor ? 'background:' + borderColor : ''"></div>
      <span class="flag" :class="getFlagCls">{{ getFlagTitle }}</span>
      <div class="img-box">
        <div class="img-box-in">
          <h-img :imgUrl="picture" :errorImg="errorImg" isUseBasePath></h-img>
        </div>
      </div>
      <p class="top" :title="productName">{{ productName }}</p>
      <p class="btm" :title="importInfo">{{ importInfo }}</p>
      <h5 class="amount">
        <span v-if="price" class="trueAmount">{{ price }}元</span>
        <span v-if="falseAmount" class="falseAmount">{{ falseAmount }}</span>
      </h5>
    </div>
  </div>
</template>

<script>
import { defaultImgPath } from "@/common/constant.js";
import { mapState } from "vuex";
// import { commImgUrl } from "@/common/constant.js";
// 导入api接口
import {
  product, //规格属性接口
  // showProductDesc, //未登录时规格属性接口
  saveCustBrowseRecord //商家浏览记录接口
} from "@/utils/api.js";
export default {
  name: "card-nav-item",
  data() {
    return {
      imgUrl: ""
      // defaultImg: 'this.src="' + require('@/assets/home/7.jpg') + '"'
      // defaultImg: require('@/assets/home/banner.jpg')
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    productName: {
      //产品名称
      type: String,
      default: ""
    },
    importInfo: {
      //卖点信息
      type: String,
      default: ""
    },

    picture: {
      //图片地址
      type: String,
      default: ""
    },
    price: {
      //价格 另外调用接口
      type: [String, Number]
    },

    productCode: {
      //产品Id
      type: String,
      default: ""
    },
    errorImg: {
      //报错图片
      type: String,
      default: ""
    },
    falseAmount: {
      type: [String, Number],
      default: ""
    },
    //活动编号
    activityCode: {
      type: [String, Number],
      default: ""
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    borderColor: {
      type: String,
      default: ""
    }
  },
  mounted() {
    // this.imgUrl = commImgUrl;
    this.defaultImgPath = defaultImgPath;
  },
  computed: {
    getFlagCls: function() {
      let cls = "";
      if (this.data.type == "popularProduct") {
        cls = "type1";
      } else if (this.data.type == "hotProduct") {
        cls = "type2";
      } else if (this.data.type == "newProduct") {
        cls = "type3";
      }
      return cls;
    },
    getFlagTitle: function() {
      let title = "";
      if (this.data.type == "popularProduct") {
        title = "爆款";
      } else if (this.data.type == "hotProduct") {
        title = "热卖";
      } else if (this.data.type == "newProduct") {
        title = "新品";
      }
      return title;
    },
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    },
    ...mapState({
      //处理后的筛选数据list
      menuList: state => state.user.menuList,
    }),
  },
  methods: {
    //点击跳转
    goDetail(data) {
      // if(this.userInfo){
      //    this.doSaveCustBrowseRecord();
      // }
      //已登录
      if (this.userInfo) {

        // if((this.userInfo.account.loginSystem.indexOf('14168810880') != '-1' || this.userInfo.account.loginSystem.indexOf('14168810879') != '-1')){
        //   this.$message.info("暂无权限");
        //   return
        // }
        let flag = false;
        for(let i = 0;i< this.menuList.length;i++) {
          if(this.menuList[i].sourceName === '商品') {
            flag = true;
            break
          }
        }
        if(!flag){
          this.$message.info("暂无权限");
          return
        }
        
        if (this.activityCode) {
          //跳转活动页
          this.$router.push({
            path: "/activity",
            query: { activityCode: this.activityCode }
          });
        } else {
          //跳转详情或商品列表页
          product({ productId: data.productCode }).then(res => {
            if (res.data.targetPage == "product") {
              this.$router.push({
                path: "/product/detail",
                query: { productId: this.productCode }
              });
            } else if (res.data.targetPage == "productList") {
              this.$router.push({
                path: "/product",
                query: {
                  productId: this.productCode,
                  tagNameL1: this.productName
                }
              });
            } else if (res.data.targetPage == "orgProduct") {
              this.$router.push({
                path: "/product/detail",
                query: { productId: this.productCode }
              });
            }
          });
        }
      } else {
        this.$router.push({
          path: "/product/detail",
          query: { productId: this.productCode }
        });
      }
    },

    //商家浏览接口
    doSaveCustBrowseRecord() {
      let params = {
        productInfoId: this.productCode, //产品id
        custAccountId: this.userInfo.account.id, //商家账号id
        custInfoId: this.userInfo.customer.id, //商家id
        type: 0 //0 pc端 1 小程序
      };
      saveCustBrowseRecord(params)
        .then(res => {
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.hvr-float-shadow:hover {
  &.animated {
    transform: translateY(-5px) !important;
  }
}
.card-ani-box {
  margin-top: 12px;
  margin-left: 12px;
}
.card-ani-box:nth-of-type(4n + 1) {
  margin-left: 0;
}
.card-item-box {
  width: 288px;
  height: 340px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  .img-box {
    width: 100%;
    height: 228px;
    overflow: hidden;
    .img-box-in {
      width: 210px;
      height: 210px;
      margin: 20px auto 0;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .flag {
    //     position: absolute;
    //     top: 0;
    //     font-size: 12px;
    //     left: -2px;
    //     top:-6px;
    //     width: 32px;
    // height: 32px;
    // border-radius: 16px;
    //     display: none;
    //     color: #fff;
    //     line-height: 36px;
    //     text-indent: 2px;
    //     text-align: center;
    position: absolute;
    top: 0;
    font-size: 12px;
    left: -4px;
    top: -10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: none;
    color: #fff;
    line-height: 44px;
    text-indent: 2px;
    text-align: center;
    transform: scale(.9);
    font-weight: 600;
    &.type1 {
      background-color: #fd6a6e;
      display: block;
    }
    &.type2 {
      background-color: #ff8f3c;
      display: block;
    }
    &.type3 {
      background-color: #00b7b3;
      display: block;
    }
  }

  p {
    // text-align: left;
    width: 210px;
    margin: 0 auto;
    
  }
  p.top {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    line-height: 20px;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.btm {
    // height: 16px;
    margin-top: 6px;
    font-size: 12px;
    color: #777;
    line-height: 20px;
    overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  }
}
</style>
