<!--
 * @Author: sqk
 * @Date: 2020-08-19 10:51:00
 * @LastEditTime: 2021-10-22 15:56:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\home\components\Pie.vue
-->

<template>
  <div class="operation-box clearfix" v-if="userInfo&& subMenuList.length>0">
    <h1 class="title-line">
      <b>经营分析</b>
      <div class="btn-group"   @click="goDetail">
        <span class="show-more hvr-icon-forwardgnegduo">
          查看更多
          <i class="iconfont icon-shouye-gonggao-youdianji hvr-icon"></i>
        </span>
      </div>
    </h1>
    <ul>
      <li class="type1 box-shadow box-radius">
        <div class="item1">
          <h3 class="toptext">本月提货额（元）</h3>
          <h4
            class="amount"

          >
            {{ getProp(purchaseReport, "purchaseAmount.count", "") || 0}}
          </h4>
          <p>
            <span>
              环比
              <em
                class="down"
                :style="{
                  color: getHomeColor(
                    getProp(purchaseReport, 'purchaseAmount.HB', '0')
                  )
                }"
                >{{ getProp(purchaseReport, "purchaseAmount.HB", "") }}</em
              >
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.HB', '0')
                  ) == '#FC5260'"   src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.HB', '0')
                  ) == '#1890FF'"   src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
          <p>
            <span>
              同比
              <em
                class="up"
                :style="{
                  color: getHomeColor(
                    getProp(purchaseReport, 'purchaseAmount.TB', '0')
                  )
                }"
                >{{ getProp(purchaseReport, "purchaseAmount.TB", "") }}</em
              >
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.TB', '0')
                  ) == '#FC5260'"  src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.TB', '0')
                  ) == '#1890FF'"   src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
        </div>
        <div class="item2 ">
          <h3 class="toptext">本月提货量（台）</h3>
          <h4
            class="num"
          >
            {{ getProp(purchaseReport, "purchaseQuantity.count", "") || 0 }}
          </h4>
          <p>
            <span>
              环比
              <em
                class="down"
                :style="{
                  color: getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.HB', '0')
                  )
                }"
                >{{ getProp(purchaseReport, "purchaseQuantity.HB", "") }}</em
              >
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.HB', '0')
                  ) == '#FC5260'"   src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.HB', '0')
                  ) == '#1890FF'"  src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
          <p>
            <span>
              同比
              <em
                class="up"
                :style="{
                  color: getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.TB', '0')
                  )
                }"
                >{{ getProp(purchaseReport, "purchaseQuantity.TB", "") }}</em
              >
                <img
                  class="topOrdown"
                  v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.TB', '0')
                  ) == '#FC5260'"  src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(purchaseReport, 'purchaseQuantity.TB', '0')
                  ) == '#1890FF'" src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
        </div>
      </li>
      <li class="type1 box-shadow box-radius">
        <div class="item1">
          <h3 class="toptext">本月销售额（元）</h3>
          <h4
            class="amount"

          >
            {{ getProp(salesReport, "salesTotalAmount.count", "") || 0}}
          </h4>

          <p>
            <span>
              环比
              <em
                class="down"
                :style="{
                  color: getHomeColor(
                    getProp(salesReport, 'salesTotalAmount.HB', '0')
                  )
                }"
              >{{ getProp(salesReport, "salesTotalAmount.HB", "") }}</em
              >
               <img
                 class="topOrdown"
                 v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalAmount.HB', '0')
                  ) == '#FC5260'"   src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalAmount.HB', '0')
                  ) == '#1890FF'"   src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
          <p>
            <span>
              同比
              <em
                class="up"
                :style="{
                  color: getHomeColor(
                    getProp(salesReport, 'salesTotalAmount.TB', '0')
                  )
                }"
              >{{ getProp(salesReport, "salesTotalAmount.TB", "") }}</em
              >
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalAmount.TB', '0')
                  ) == '#FC5260'"   src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalAmount.TB', '0')
                  ) == '#1890FF'"   src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
        </div>
        <div class="item2">
          <h3 class="toptext">本月销售总量（台）</h3>
          <h4
            class="num"

          >
            {{ getProp(salesReport, "salesTotalQuantity.count", "") || 0}}
          </h4>
          <p>
            <span>
              环比
              <em
                class="down"
                :style="{
                  color: getHomeColor(
                    getProp(salesReport, 'salesTotalQuantity.HB', '0')
                  )
                }"
              >{{ getProp(salesReport, "salesTotalQuantity.HB", "") }}</em
              >
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalQuantity.HB', '0')
                  ) == '#FC5260'"   src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalQuantity.HB', '0')
                  ) == '#1890FF'"   src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
          <p>
            <span>
              同比
              <em
                class="up"
                :style="{
                  color: getHomeColor(
                    getProp(salesReport, 'salesTotalQuantity.TB', '0')
                  )
                }"
              >{{ getProp(salesReport, "salesTotalQuantity.TB", "") }}</em
              >
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalQuantity.TB', '0')
                  ) == '#FC5260'"   src="./../../../assets/home/上升@2x.png"/>
              <img
                class="topOrdown"
                v-if="getHomeColor(
                    getProp(salesReport, 'salesTotalQuantity.TB', '0')
                  ) == '#1890FF'"   src="./../../../assets/home/下降@2x.png"/>
            </span>
          </p>
        </div>
      </li>
      <li class="box-shadow box-radius">
        <!-- <img src="@/assets/home/chart-item.jpg" alt /> -->
        <pie :data="pieChartData"></pie>
      </li>
    </ul>
  </div>
</template>

<script>
import util from "./util";
import pie from "./Pie";
import {
  homepageNormalSalesReport,
  homepageSalesReport,
  reportCustSales,
  homepageInventoryReport
} from "@/utils/api.js";
import moment from "moment";
moment.locale("zh-cn");
export default {
  name: "operation",
  components: {
    pie //chart图
  },
  data() {
    return {
      pieChartData: [],
      purchaseReport: {},
      normalSalesReport: {},
      salesReport: {},
      inventoryReport:{}
    };
  },
  props: {
    subMenuList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    }
  },
  watch: {
    data: function() {}
  },
  mounted() {
    this.getOperationData();
  },
  methods: {
    //获取首页报表数据
    getOperationData() {
      if(!this.$store.state.user.userInfo){
        return
      }
      let data = {
        queryDate: moment(new Date()).format("YYYYMM"),
        orgId: "",
        matkl: ""
      };
      //采购报表
      reportCustSales(data).then(res => {
        if (typeof res.data == "string") {
          res.data = JSON.parse(res.data);
        }
        const { nowData, tb, orderRate } = res.data;
        const purchaseAmount = {
          count: nowData.realPrice,
          HB: tb.hbRealPrice,
          TB: tb.tbRealPrice
        };
        const purchaseQuantity = {
          count: nowData.realNum,
          HB: tb.hbRealNum,
          TB: tb.tbRealNum
        };
        const rate = {
          monthChRatio: orderRate.monthChRatio,
          yearChRatio: orderRate.yearChRatio,
          yearYjRatio: orderRate.yearYjRatio,
          monthYjRatio: orderRate.monthYjRatio
        };
        const purchaseReport = {
          purchaseAmount,
          purchaseQuantity,
          rate
        };
        this.purchaseReport = purchaseReport;
      });
      data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account
      };
      //渠道采购报表
      homepageNormalSalesReport(data).then(res => {
        if (typeof res.data == "string") {
          res.data = JSON.parse(res.data);
        }
        this.normalSalesReport = res.data.report || {};
      });
      //销售报表
      
      homepageSalesReport(data).then(res => {
        if (typeof res.data == "string") {
          res.data = JSON.parse(res.data);
        }
        this.salesReport = res.data.report || {};
      });
      //库存报表
      // data.gicWarehouseType= ""
      data = {
        // 获取当前时间
        // month:moment(new Date()).format("YYYYMMDD"),
        // 获取前一天时间
        month:moment().subtract(1, 'days').format('YYYYMMDD'),
        operatorCode: this.$store.state.user.userInfo.customer.cisCode,
      }
      homepageInventoryReport(data).then(res => {
        if (typeof res.data == "string") {
          res.data = JSON.parse(res.data);
        }
        const { report } = res.data
        const { total, details } = report
        const series = []
        details.forEach((res) => {
          if(res.name == '电视') {
            res.color = '#F36556'
          }
          if(res.name == '激光') {
            res.color = '#00C8D4'
          }
          if(res.name == '冰箱') {
            res.color = '#6185FF'
          }
          if(res.name == '洗衣机') {
            res.color = '#6C7497'
          }
          if(res.name == '冷柜') {
            res.color = '#F78C30'
          }
          if(res.name == '空调') {
            res.color = '#8A7BFE'
          }
          const item = {
            name: res.name,
            value: res.count,
            color: res.color,
            stroke: false,
          }
          series.push(item)
        })
        const title = {
          name: total,
          color: '#262626',
          fontSize: 14,
        }
        const inventoryReport = {
          series,
          title,
          total
        }
        this.inventoryReport = inventoryReport;
        this.pieChartData = inventoryReport;
      });
    },
    //更多
    goDetail(){
      if(this.subMenuList.length > 0) {
        this.$router.push(this.subMenuList[0].vuePath)
      } else {
        this.$message.info('暂时没有更多报表进行查看')
      }
      
    },
    getHomeColor(string) {
      if (!string) {
        return "";
      }
      string += "";
      return string
        ? string.indexOf("--") !== -1
          ? "#AAAAAA"
          : string.indexOf("+") !== -1
          ? "#FC5260"
          : "#1890FF"
        : "";
    },
    getProp(object, path, defaultValue) {
      try {
        const rest = (!Array.isArray(path)
          ? path
              .replace(/\[/g, ".")
              .replace(/\]/g, "")
              .split(".")
          : path
        ).reduce((o, k) => (o || {})[k], object);
        return rest || rest === 0 ? rest : defaultValue;
      } catch (e) {

        return defaultValue;
      }

    }
  }
};
</script>
<style lang="less" scoped>
.operation-box {
  //   margin-top: 28px;
  width: 1188px;
  margin: 16px auto 0;
  ul {
    width: 1188px;
    height: 192px;
    background: #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 0 16px 0 16px;
    li {
      box-shadow:none!important;
      width: 374px;
      height: 160px;

      border-radius: 10px;
      padding: 16px;
      box-sizing: border-box;
      text-align: left;
      cursor: pointer;
      float: left;
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
      &.more-box {
        text-align: center;
        img {
          width: 48px;
          height: 48px;
          margin-top: 38px;
          margin-bottom: 24px;
        }
        // .anticon-right-circle {
        //   color: #00bcb7;
        //   font-size: 48px;
        //   margin-top: 38px;
        //   margin-bottom: 24px;
        // }
        p {
          font-size: 18px;
          font-weight: 500;
          color: rgba(38, 38, 38, 1);
          line-height: 25px;
        }
      }
      h3 {
        height: 22px;
        line-height: 22px;
        font-size: 16px;
        color: #777777;;
      }
      h4 {
        height: 20px;
        line-height: 20px;
        font-size: 20px;
        font-weight: 500;
        margin-top: 8px;
        margin-bottom: 18px;
        &.amount {
          //color: rgba(217, 22, 28, 1);
          color: #262626;
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 20px;
        }

        &.num {
          //color: rgba(51, 51, 51, 1);

          font-size: 24px;
          font-weight: 500;
          color: #262626;
          margin-bottom: 20px;

        }
      }
      p {
        height: 14px;
        font-size: 14px;
        //color: rgba(153, 153, 153, 1);
        line-height: 14px;
        color: #777;
        margin-bottom: 8px;
        em {
          &.up {
            color: #FC5260;
          }
          &.down {
            color: #1890FF;
          }
        }
        .topOrdown {
          width:14px;
          height: 14px;
          margin-left: 6px
        }
      }
      &.type1 {
        > div {
          width: 50%;
          float: left;
          margin-top: 8px;
        }
        .item1,.item2 {
          .toptext {
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #777777;
            line-height: 20px;
          }
        }
      }
      &.type2 {
        h4 {
          margin-bottom: 8px;
        }
        .item1 {
          width: 100%;

        }
        .btm-box {
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: flex-start;
          .item2,
          .item3 {
            //width: 50%;
            float: left;
             p{
              white-space:nowrap;
            }
          }

          .small {
            h3 {

              color: #777777;
              font-size: 14px;
              line-height: 20px;
              //color: rgba(102, 102, 102, 1);
            }
            h4 {
              font-size: 24px;
              line-height: 16px;
              font-weight: 500;
              color: #262626;
            }
            span {
              font-size: 12px;
              line-height: 12px;
            }
          }
          .item3 {
            // width: 180px;
            margin-left: 15px;
          }
        }

        p {
          span + span {
            margin-left: 20px;
          }
        }

      }
    }
    li:first-child {
      background: #F1FAFF;
    }
    li:nth-child(2) {
      background: #FFF4F4;
    }
    li:last-child {
      background:#ECF8F8
    }
    > li + li {
      margin-left: 12px;
    }
    > li:nth-of-type(4) {
      margin-left: 0;
    }
    > li:nth-of-type(n + 4) {
      margin-top: 12px;
    }
  }
  & + .commodity-box {
    margin-top: 30px;
  }
}
</style>
