<!--
 * @Author: sqk
 * @Date: 2020-08-12 15:51:12
 * @LastEditTime: 2021-01-14 14:23:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\CardItem\CardItem.vue
-->


<template>
  <div
    @click="goDetail(data)"
    class="card-ani-box animated fadeInRight"
    :style="{ animationDelay: (itemIndex + 1) * .1 + 's' }"
  >
    <div class="card-item-box box-shadow">
       <div class="xl-box" v-if="data.xl">
         <p>限量</p>
         <p>{{data.xl}}件</p>
      </div>
      <div class="img-box">
        <!-- <div class="img-box-in" :style="{ backgroundImage: 'url(' + picture + ')' }"></div> -->
        <!-- <img :src="picture" v-real-img="require('@/assets/home/banner.jpg')" alt=""> -->
        <div class="img-box-in">
          <!-- <img :src="picture" :onerror="defaultImg" alt=""> -->
          <h-img :imgUrl="picture" :errorImg="errorImg" isUseBasePath></h-img>
        </div>
      </div>
      <p class="top" :title="productName">{{ productName }}</p>
      <p class="btm" :title="importInfo">{{ importInfo }}</p>
      <!-- <h5 class="amount">
        <span v-if="price" class="trueAmount">{{ price }}元</span>
        <span v-if="falseAmount" class="falseAmount">{{ falseAmount }}</span>
      </h5> -->
      <!-- <h5 class="amount">
        <span v-if="price  && price.toString().includes('.')" class="trueAmount">
          <i class="fh">￥</i>
          <span class="before">{{ price ? price.toString().split('.')[0] : 0 }}</span>
          <i class="dian">.</i>
          <span class="after">{{ price ? price.toString().split('.')[1] : 0 }}</span>
        </span>
        <span
          v-if="standardPrice && standardPrice != price && isFenXiao == 'N'"
          class="falseAmount"
        >{{ standardPrice }}</span>
      </h5> -->
      <h5 class="amount">
        <span v-if="price" class="trueAmount">
          <i class="fh">￥</i>
          <span class="before">{{price.toString().split('.')[0]}}</span>
          <i class="dian">.</i>
          <span class="after">{{price.toString().split('.')[1]}}</span>
        </span>
        <span
          class="falseAmount"
        >￥{{standardPrice}}</span>
      </h5>
    </div>
  </div>
</template>

<script>
import { defaultImgPath } from "@/common/constant.js";
// import { commImgUrl } from "@/common/constant.js";
// 导入api接口
import {
  product, //规格属性接口
  // showProductDesc, //未登录时规格属性接口
  saveCustBrowseRecord //商家浏览记录接口
} from "@/utils/api.js";
export default {
  name: "card-item",
  data() {
    return {
      imgUrl: ""
      // defaultImg: 'this.src="' + require('@/assets/home/7.jpg') + '"'
      // defaultImg: require('@/assets/home/banner.jpg')
    };
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    productName: {
      //产品名称
      type: String,
      default: ""
    },
    importInfo: {
      //卖点信息
      type: String,
      default: ""
    },

    picture: {
      //图片地址
      type: String,
      default: ""
    },
    price: {
      //价格 另外调用接口
      type: [String, Number]
    },
standardPrice: {
      //提货价格 另外调用接口
      type: [String, Number]
    },
    productCode: {
      //产品Id
      type: String,
      default: ""
    },
    errorImg: {
      //报错图片
      type: String,
      default: ""
    },
    falseAmount: {
      type: [String, Number],
      default: ""
    },
    //活动编号
    activityCode: {
      type: [String, Number],
      default: ""
    },
    itemIndex: {
      type: Number,
      default: 0
    },
  },
  mounted() {
    // this.imgUrl = commImgUrl;
    this.defaultImgPath = defaultImgPath;
  },
  computed: {
    getFlagCls: function() {
      let cls = "";
      if (this.data.type == "popularProduct") {
        cls = "type1";
      } else if (this.data.type == "hotProduct") {
        cls = "type2";
      } else if (this.data.type == "newProduct") {
        cls = "type3";
      }
      return cls;
    },
    getFlagTitle: function() {
      let title = "";
      if (this.data.type == "popularProduct") {
        title = "爆款";
      } else if (this.data.type == "hotProduct") {
        title = "热卖";
      } else if (this.data.type == "newProduct") {
        title = "新品";
      }
      return title;
    },
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : "";
    }
  },
  methods: {
    //点击跳转
    goDetail(data) {
      // if(this.userInfo){
      //    this.doSaveCustBrowseRecord();
      // }
      //已登录
      if (this.userInfo) {
        if (this.activityCode) {
          //跳转活动页
          this.$router.push({
            path: "/activity",
            query: { activityCode: this.activityCode }
          });
          
        } else {
          //跳转详情或商品列表页
          product({ productId: data.productCode }).then(res => {
            if (res.data.targetPage == "product") {
              this.$router.push({
                path: "/product/detail",
                query: { productId: this.productCode }
              });
            } else if (res.data.targetPage == "productList") {
              this.$router.push({
                path: "/product",
                query: {
                  productId: this.productCode,
                  tagNameL1: this.productName
                }
              });
            } else if (res.data.targetPage == "orgProduct") {
              this.$router.push({
                path: "/product/detail",
                query: { productId: this.productCode }
              });
            }
          });
        }
      } else {
        this.$router.push({
          path: "/product/detail",
          query: { productId: this.productCode }
        });
      }
    },

    //商家浏览接口
    doSaveCustBrowseRecord() {
      let params = {
        productInfoId: this.productCode, //产品id
        custAccountId: this.userInfo.account.id, //商家账号id
        custInfoId: this.userInfo.customer.id, //商家id
        type: 0 //0 pc端 1 小程序
      };
      saveCustBrowseRecord(params)
        .then(res => {
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.hvr-float-shadow:hover {
  &.animated {
    transform: translateY(-5px) !important;
  }
}
.card-ani-box {
  margin-top: 12px;
  margin-left: 12px;
}
.card-ani-box:nth-of-type(4n + 1) {
  margin-left: 0;
}
.card-item-box {
  width: 288px;
  height: 360px;
  background-color: #fff;
  cursor: pointer;
  position: relative;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  .xl-box{
    width: 64px;
    height: 63px;
    background: url("~@/assets/home/limit-shop-item-num.png") no-repeat center;
    background-size: 100% 100%;
    text-align: center;
    font-size: 14px;
    color: #fff;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    position: absolute;
    right: 8px;
    top: 8px;
    p{
      width: 100%;
      font-size: 14px;
      line-height: 1;
      &:first-child{
        margin-top:13px;
      }
      +p{
        margin-top: 3px;
      }
    }
  }
  .img-box {
    width: 100%;
    height: 228px;
    overflow: hidden;
    .img-box-in {
      width: 210px;
      height: 210px;
      margin: 18px auto 0;
      // background: url("../../../assets/home/demo (1).png") no-repeat center;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      // border: 1px solid #efefef;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  // .flag {
  //   position: absolute;
  //   top: 0;
  //   font-size: 12px;
  //   left: 16px;
  //   width: 50px;
  //   height: 20px;
  //   border-bottom-left-radius: 6px;
  //   border-bottom-right-radius: 6px;
  //   display: none;
  //   color: #fff;
  //   &.type1 {
  //     background-color: #d9161c;
  //     display: block;
  //   }
  //   &.type2 {
  //     background-color: #ff8f3c;
  //     display: block;
  //   }
  //   &.type3 {
  //     background-color: #00aaa6;
  //     display: block;
  //   }
  // }

  p {
    // text-align: left;
    width: 210px;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  p.top {
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    color: #262626;
    line-height: 20px;
    margin-top: 20px;
  }
  p.btm {
    height: 16px;
    margin-top: 4px;
    font-size: 12px;
    color: #777;
    line-height: 16px;
  }
  h5.amount {
    width: calc(100% - 48px);
    height: 28px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    margin: 20px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .trueAmount {
      color: #fff;
      font-size: 16px;
      // font-weight: 600;
      border: 1px solid #D9161C;
      background-color: #D9161C;
      border-right: 0;
      padding: 0 6px 0 8px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      position: relative;
      i,
      span {
        float: left;
      }
      i.fh {
        font-size: 12px;
        width: 12px;
        line-height: 29px;
        height: 26px;
        // margin-right: 2px;
      }
      i.dian{
        display: flex;
        align-items: flex-end;
        height: 28px;
      }
      span.before {
        font-size: 18px;
        line-height: 26px;
      }
      i.dian{
        // line-height: 18px;
        
      }
      span.after {
        font-size: 12px;
        line-height: 30px;
        height: 26px;
      }

      &::after{
        content: '';
        position: absolute;
        right: -4px;
        top: 0;
        width: 7px;
        height: 100%;
        transform: skew(-14deg);
        background-color: #D9161C;
      }
    }
    .falseAmount {
      // margin-left: 8px;
      height: 28px;
      line-height: 26px;
      padding: 0 8px;
      text-align: center;
      color: #777;
      font-size: 12px;
      text-decoration: line-through;
      border: 1px solid #D9161C;
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
</style>
