<!--
 * @Author: sqk
 * @Date: 2020-08-12 10:40:56
 * @LastEditTime: 2021-01-30 18:11:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\components\global\Carousel\Carousel.vue
-->

<template>
  <div class="swiper-container banner-swiper" ref="mySwiper">
    <div class="swiper-wrapper">
      <!-- <div
        class="swiper-slide"
        :class="{hasUrl : item.url}"
        :data-url="item.url"
        v-for="item in data"
        :key="item.imgName"
        :style="{ backgroundImage:'url(' + baseUrl + item.imgName + ')'}"
      > -->
      <div
        class="swiper-slide"
        :class="{hasUrl : item.url}"
        :data-url="item.url"
        :data-type ="item.impDetail"
        v-for="item in data"
        :key="item.imgName"
        :style="{ background:'url(' + baseUrl + item.imgName + ')'}"
      >
        <!-- <div
        class="swiper-slide"
        v-for="item in data"
        :key="item.imgName"
        :style="{ backgroundImage:'url(' + item.imgName + ')'}"
        >-->
        <!-- <img src="@/assets/home/banner.jpg" alt /> -->
        <!-- <img :src="baseUrl + item.imgName" alt /> -->
      </div>
    </div>
    <!-- 如果需要分页器" -->
    <div class="swiper-pagination banner-pagination " ref="pagination"></div>
    <!--箭头。如果放置在swiper-container外面，需要自定义样式。-->
    <div
      class="swiper-button-prev animated fadeInRight"
      ref="prevEl"
      v-if="data && data.length > 1"
    ></div>
    <div class="swiper-button-next animated fadeInLeft" ref="nextEl" v-if="data && data.length > 1"></div>
  </div>
</template>

<script>
export default {
  name: "banner",
  data() {
    return {};
  },
  props: {
    data: {
      type: Array,
      default: () => {}
    },
    baseUrl: {
      type: String,
      default: ""
    }
  },
  watch: {
    data: function() {
      // this.initEcharts();
      this.swiperInit();
    }
  },
  //定义swiper对象
  computed: {},
  mounted() {
    // this.swiperInit();
  },
  methods: {
    borderColorFun(index) {
      let idx = index % 4;
      let colorList = ["#FF8F3C", "#00B7B3", "#4285FF", "#D9161C"];
      return colorList[idx];
    },
    swiperInit() {
      let this_ = this;
      this.$nextTick(() => {
        var mySwiper = new this.$Swiper(this.$refs.mySwiper, {
          on: {
            slideChange: function() {
            },
            click: () => {
              // alert("你点了Swiper");
              let url = mySwiper.clickedSlide.getAttribute("data-url");
              // 如果是http或者https开头跳转外部链接 否则跳转内部链接
              if(url.indexOf('http://')===0||url.indexOf('https://')===0) {
                  window.open(url)
              } else {
                this.$router.push({
                  path: url
                })
              }
            }
          },
          autoplay: {
            //自动轮播
            delay: 5000
            // disableOnInteraction: false
          },
          effect: "fade", //渐变形式
          fadeEffect:{crossFade:true},//防止loop后重叠
          loop: this.data.length > 1 ? true : false, // 循环模式选项
          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          //         // 如果需要分页器
          pagination: {
            el: this_.$refs.pagination,
            clickable: true
          },
          navigation: {
            nextEl: this_.$refs.nextEl,
            prevEl: this_.$refs.prevEl
          }
        });
        mySwiper.el.onmouseover = function() {
          //鼠标放上暂停轮播
          mySwiper.autoplay.stop();
        };
        mySwiper.el.onmouseleave = function() {
          mySwiper.autoplay.start();
        };
      });
    }
  }
};
</script>
<style lang="less">
.banner-swiper {
  width: 100%;
  height: 560px;
  background-size: cover;
  z-index: 0;
  .swiper-slide {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    // background-size: contain;
    // background-size: 100% 100%;
    // background-size: 100%;
    &.hasUrl {
      cursor: pointer;
    }
  }
  &.swiper-container .swiper-button-prev {
    background-image: url("../../../assets/common/arrow-left-big.png");
    // left: 180px;
    left: 9.5%;
    right: auto;
  }
  &.swiper-container .swiper-button-next {
    background-image: url("../../../assets/common/arrow-right-big.png");
    // right: 180px;
    right: 9.5%;
    left: auto;
  }
  &.swiper-container .swiper-button-prev,
  &.swiper-container .swiper-button-next {
    position: absolute;
    top: 50%;
    // width: 72px;
    // height: 72px;
    width: 62px;
    height: 62px;
    margin-top: -36px;
    z-index: 10;
    border-radius: 50%;
    cursor: pointer;
  }
  // .swiper-pagination,
  //   .swiper-pagination-custom,
  //   .swiper-container-horizontal > .swiper-pagination-bullets {
  //     bottom: 16px;
  //     right: 10px;
  //     // width: 100%;
  //   }
  .swiper-pagination.banner-pagination {
    bottom: 100px;
    right: 10px;
    width: 1188px;
    text-align: right;
    margin: 0 auto;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      background: #fff;
      opacity: 0.5;
      margin: 0 7px;
      border-radius: 4px;
      transition: opacity 0.5s, background-color 0.5s, width 0.26s;
      transition-delay: 0.5s, 0.5s, 0s;
      border: none;
    }
    .swiper-pagination-bullet-active {
      opacity: 1;
      background: #ffffff;
      width: 12px;
      transition-delay: 0s;
    }

    // 另一种样式
    // .swiper-pagination-bullet {
    //   width: 6px;
    //   height: 6px;
    //   border: none;
    //   background: rgba(255, 255, 255, 0.5) !important;
    //   border: 2px solid transparent !important;
    // }
    // .swiper-pagination-bullet-active {
    //   width: 6px;
    //   height: 6px;
    //   background: rgba(0, 0, 0, 0.2) !important;
    //   border: 2px solid #fff !important;
    // }
  }
}
</style>

