import http from "@/utils/request";

  export function announDetail(data) {
    return http({
      method: "get",
      url: "/billboard/loginMessageDetail.htm",
      params: data
    });
  }

  // 稍后查看
  export function saveViewWait(data) {
    return http({
      method: "get",
      url: "/billboard/saveViewWait.nd",
      params: data
    });
  }