<template>
  <a-spin :spinning="pageLoad" size="large">
    <div class="evalutaion clearfix" v-if="userInfo && this.showCD ">
      <h1 class="title-line">
        <b>核心指标 </b>
        <div class="btn-group">
          <ul class="tablBox">
            <li
              @click="changeIndex(index)"
              v-for="(item,index ) in tablist"
              :class="tabIndex == index ? 'Actice':'noActice'"
              :key="index"
            >
              {{item.name}}
            </li>
          </ul>
        </div>
      </h1>
      <div class="BigBox" v-show="tableName == '运营商'">
        <div class="topleftBox" >
          <div class="detail-header">
            <div class = 'leftFilter'>
              <div class="tab-change">
                <div  class="tab-item"  :class="tabActive == 1 ? 'active' : ''"  @click="handleChangeTab( 1 )">月报</div>
                <div class="tab-item" :class="tabActive == 2 ? ' active' : '' " @click="handleChangeTab( 2 )">年报</div>
              </div>
              <div class="date-picker">
                <el-date-picker
                  popper-class="date-style"
                  @change="changeMonth"
                  class="zhanbi-date"
                  v-if="tabActive == 1"
                  v-model="dateEchoValue"
                  format="yyyy-MM"
                  value-format="yyyy-MM"
                  type="month"
                  placeholder="选择月"
                  :clearable="false"
                  :picker-options="pickerMonthOptions"
                >
                </el-date-picker>
                <el-date-picker
                  popper-class="date-style"
                  @change="changeYonth"
                  class="zhanbi-date"
                  :clearable="false"
                  v-if="tabActive == 2"
                  format="yyyy"
                  value-format="yyyy"
                  v-model="dateEchoYearValue"
                  :picker-options="pickerYearOptions"
                  type="year"
                  placeholder="选择年">
                  <a-icon slot="suffixIcon" type="caret-down" class="down-icon"/>
                </el-date-picker>
              </div>
            </div>
            <div class="time-choose" >
              <p style="color:#1890FF;cursor: pointer" @click="exportClick">导出报表</p>
            </div>
          </div>
          <div class="smallBox echartsBox">
            <div class="paimingBox">
              <p class="text">第 <span class="mingci">{{comprehensiveRanking || '--'}}</span>  名<span class="all">(共{{customerNum || '--'}}家)</span></p>
            </div>
            <div class="scoreBox">
              <p class="text">{{comprehensiveScore?Number(comprehensiveScore).toFixed(1): '0'}}</p>
              <span class="all">分</span>
            </div>
            <div class="echarts"  ref="fourth" ></div>
          </div>
        </div>
        <div class="topRightBox">
          <div class="leftSmallBox">

            <div class="topEarch">
              <div class="every echartsBoxone">
                <div class="bigBox">
                  <div class="leftPart">
                    <p>全渠道口径出货(万元)</p>
                    <p>{{channelDiameter&&channelDiameter.accomplish?Number(channelDiameter.accomplish).toFixed(0):0}}</p>
                    <p>
                      较去年 {{channelDiameter&&channelDiameter.yoy?Number(channelDiameter.yoy).toFixed(1):'0.0'}}%
                      <img v-if="channelDiameter&&channelDiameter.yoy > 0 "  src="./../../../assets/home/上升@2x.png" alt="">
                      <img v-if="channelDiameter&&channelDiameter.yoy < 0 " src="./../../../assets/home/下降@2x.png" alt="">
                    </p>
                  </div>
                  <div class="rightPart">
                    <div class="centerTxt">
                      <p>{{ channelDiameter&&channelDiameter.rate?Number(channelDiameter.rate).toFixed(1):'0.0' }}<span>%</span></p>
                      <p>完成进度</p>
                    </div>
                    <div class="content" ref="numone"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bottomEcharts">
              <p class="tips">零售能力提升</p>
              <div class="echartsBox">
                <div class="greenEcharts">
                  <p>前置及异业规模(万元)</p>
                  <p>{{preposition&&preposition.accomplish?Number(preposition.accomplish).toFixed(0):0.0}}</p>
                  <p class="increaseTip">
                    较去年 {{preposition&&preposition.yoy?Number(preposition.yoy).toFixed(1):'0.0'}}%
                    <img v-if="preposition&&preposition.yoy > 0 "  src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="preposition&&preposition.yoy < 0 " src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                  <div class="preEmployment" ref="preEmployment"></div>
                  <div class="circlePrompt">
                    <span class="percentage">{{ preposition&&preposition.rate?Number(preposition.rate).toFixed(1):'0.0' }}<span style="font-size:12px">%</span></span>
                    <p class="ranking">排名<span class="nowRanking">{{ preposition && preposition.rankingShow? preposition.rankingShow.split('/')[0]:'0' }}</span>/{{ preposition && preposition.custNum? preposition.custNum:'0' }}
                    </p>
                  </div>
                </div>
                <div class="blueEcharts">
                  <p>线上店销售(万元)</p>
                  <p>{{onlineStore&&onlineStore.accomplish?Number(onlineStore.accomplish).toFixed(0):0}}</p>
                  <p class="increaseTip">
                    较去年{{onlineStore&&onlineStore.yoy?Number(onlineStore.yoy).toFixed(1):'0.0'}}%
                    <img v-if="onlineStore&&onlineStore.yoy > 0 "  src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="onlineStore&&onlineStore.yoy < 0 " src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                  <div class="preEmployment" ref="preEmploymentTwo"></div>
                  <div class="circlePrompt">
                    <span class="percentage">{{onlineStore&&onlineStore.rate?Number(onlineStore.rate).toFixed(1):'0.0'}}<span style="font-size:12px">%</span></span>
                    <p class="ranking">排名<span class="nowRanking">{{ onlineStore && onlineStore.rankingShow? onlineStore.rankingShow.split('/')[0]:'0' }}</span>/{{ onlineStore && onlineStore.custNum? onlineStore.custNum:'0' }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightSmallBox">
            <p class="distributiveNetwork">分销网络拓展与维护</p>
            <div class="firstBox">
              <p class="distributionNetworkRanking">
                <span class="title">分销网点年累净增(个)</span>
                <span class="ranking">排名<span class="currentRanking">{{ distributeNet && distributeNet.rankingShow? distributeNet.rankingShow.split('/')[0]:'0' }}</span>/{{ distributeNet && distributeNet.custNum? distributeNet.custNum:'0' }}</span>
              </p>
              <div class="greenProgressbar" ref="greenProgressbar"></div>
              <p class="reach">达成<span class="redTips">{{distributeNet && distributeNet.accomplish || 0}}</span>/{{distributeNet && distributeNet.target||0}}</p>
            </div>

            <!--          动销率(%)-->
            <div class="twoBox">
              <p class="distributionNetworkRanking">
                <span class="title">动销率(%)</span>
                <span class="ranking">排名<span class="currentRanking">{{ distributeRate && distributeRate.rankingShow? distributeRate.rankingShow:'0' }}</span>/{{ distributeRate && distributeRate.custNum? distributeRate.custNum:'0' }}</span>
              </p>
              <div class="orgProgressbar" ref="orgProgressbar"></div>
              <div class="reachBox">
                <p class="reach">达成<span class="redTips">{{distributeRate && distributeRate.accomplish?Number(distributeRate.accomplish).toFixed(1):'0.0'}}</span>/{{distributeRate && distributeRate.target ?Number(distributeRate.target).toFixed(1) :'0.0'}}</p>
                <p class="compare">
                  较去年 {{distributeRate&&distributeRate.yoy?Number(distributeRate.yoy).toFixed(1):'0.0'}}%
                  <img v-if="distributeRate&&distributeRate.yoy > 0"  src="./../../../assets/home/上升@2x.png" alt="">
                  <img v-if="distributeRate&&distributeRate.yoy < 0" src="./../../../assets/home/下降@2x.png" alt="">

                </p>
              </div>
            </div>
            <div class="twoBox">
              <p class="distributionNetworkRanking">
                <span class="title">单网点产出(万元)</span>
                <span class="ranking">排名<span class="currentRanking">{{ singlePoint && singlePoint.rankingShow? singlePoint.rankingShow:'0' }}</span>/{{ singlePoint && singlePoint.custNum?singlePoint.custNum:'0' }}</span>
              </p>
              <div class="orgProgressbar" ref="purpleProgressbar"></div>
              <div class="reachBox">
                <p class="reach">达成<span class="redTips">{{singlePoint && singlePoint.accomplish?Number(singlePoint.accomplish).toFixed(0): '0'}}</span>/{{singlePoint && singlePoint.target ?Number(singlePoint.target).toFixed(0):'0'}}</p>
                <p class="compare">
                  较去年 {{singlePoint&&singlePoint.yoy?Number(singlePoint.yoy).toFixed(1):'0.0'}}%
                  <img v-if="singlePoint&&singlePoint.yoy > 0"   src="./../../../assets/home/上升@2x.png" alt="">
                  <img v-if="singlePoint&&singlePoint.yoy < 0"  src="./../../../assets/home/下降@2x.png" alt="">

                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="BigBox" v-show="tableName == '专卖店' ">
        <div class="leftBoxzm" >
          <div class="smallBox echartsBox">
            <div class="selectBox">
              <div class="ZH">
                <div class="ZHSj" v-if="isCherkZH">
                  <div class="clear-btn" @click="delectZH">
                    <i class="icon iconfont icon-guanbi-gouwuche"></i>
                  </div>
                </div>
                <div class="ZHbox"
                     :class="{'more' : isShowMoreZH, 'isHaveVal': isHaveVal}">
                  <div v-show="isHaveVal" class="clear-btn" @click="delectZH">
                    <i class="icon iconfont icon-guanbi-gouwuche"></i>
                  </div>
                  <h-btn style="width:100%!important;text-align: left" class="more-fil-btn" level="2" @click="moreClick">
                    {{ this.orderBy ? this.cherZhname :  this.cherZhname }}<i
                    class="iconfont icon-sanjiao" style="margin-left: 4px"
                  /></h-btn>
                </div>
                <div v-show="isShowMoreZH" class="formFiltrateMorBox clearfix">
                  <a-spin class="mid-spin" :spinning="filterLoadFlag" size="large">
                    <ul class="form">
                      <li v-for="(item,index) in PxList"
                          :key="index"
                          :class="item.cisShopCode==orderBy?'liCherk':''"
                          @click="changeZH(item)">
                        {{ item.name }}
                      </li>
                    </ul>
                  </a-spin>
                </div>
              </div>
            </div>

          </div>
          <div class="bottomEchartsBox">
            <div class="topEcharts">
              <div class="echartsOne">
                <div class="every echartsBoxone">
                  <div class="bigBox">
                    <div class="leftPart">
                      <p>年度提货总额(万元)</p>
                      <p>{{sixData.currentYearTotal}}</p>
<!--                      <p>较上月 {{sixData.chain}}-->
<!--                        <img v-if="sixData.arrowMonth == 1 " src="./../../../assets/home/上升@2x.png" alt="">-->
<!--                        <img v-if="sixData.arrowMonth == 0 " src="./../../../assets/home/下降@2x.png" alt="">-->
<!--                      </p>-->
                      <p>
                        较去年 {{sixData.year}}
                        <img v-if="sixData.arrowYear == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                        <img v-if="sixData.arrowYear == 0 "  src="./../../../assets/home/下降@2x.png" alt="">
                      </p>
                      <p>其中本月 {{sixData.currentMonthTotal}}</p>
                    </div>
                    <div class="rightPart">
                      <div class="centerTxt">
                        <p>{{sixData.completion}}<span>%</span></p>
                        <p>完成进度</p>
                      </div>
                      <div class="content" ref="numsix"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="echartsTwo">
                <div class="every echartsBoxthree3">
                  <P style="margin-top:10px;margin-bottom:2px;text-align:left;margin-left:16px">零售</P>
                  <div class="bigBox">
                    <div class="leftPart">
                      <p>年度零售总额(万元)</p>
                      <p>{{eightData.year }}</p>
                      <p>
                        较去年 {{eightData.chain}}
                        <img v-if="eightData.arrowYear == 1 "   src="./../../../assets/home/上升@2x.png" alt="">
                        <img v-if="eightData.arrowYear == 0 "  src="./../../../assets/home/下降@2x.png" alt="">
                      </p>
                      <p>其中本月 {{ eightData.currentRetailTotalAmount  }}
                        <img v-if="eightData.arrowMonth == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                        <img v-if="eightData.arrowMonth == 0 " src="./../../../assets/home/下降@2x.png" alt="">
                      </p>
                      
                    </div>
                    <div class="rightPart">
                      <div class="centerTxt">
                        <p>{{ eightData.completion}}<span>%</span></p>
                        <p>完成进度</p>
                      </div>
                      <div class="content" ref="numeight"></div>
                    </div>

                  </div>
                </div>
                <!-- 专供占比 -->
                <div class="proportionExclusiveSupply">
                  <p class="title">本月专供占比
                    <a-popover placement="topRight">
                      <template slot="content" style="margin-left: -20px">
                        专供产品占比=本月专供产品零售额/月度实际零售额
                      </template>
                      <i style='color:#AAAAAA;font-size: 12px' class="iconfont icon-wentitubiao"></i>
                    </a-popover>
                  </p>
                  <!-- <p class='radio'>{{ dedicatedProductData.ratio }}</p> -->
                  <p class='radio'><span>{{ dedicatedProductData.ratio?dedicatedProductData.ratio.split('%')[0]:'0'}}</span><span style="font-size:14px">%</span></p>
                  
                  <p class="compare month">
                    较上月 {{ dedicatedProductData.chain }}
                    <img  v-if="dedicatedProductData.arrowMonth == 1" src="@/assets/order/上升@2x.png"  alt="">
                    <img  v-if="dedicatedProductData.arrowMonth == 0" src="@/assets/order/下降@2x.png"  alt="">
                  </p>
                  <p class="compare year">
                    较去年 {{ dedicatedProductData.year }}
                    <img  v-if="dedicatedProductData.arrowYear == 1" src="@/assets/order/上升@2x.png"  alt="">
                    <img  v-if="dedicatedProductData.arrowYear == 0" src="@/assets/order/下降@2x.png"  alt="">
                  </p>
                </div>
                <!-- 专供零售额(万元) -->
                <div class="proportionExclusiveSupply saleBox">
                  <p class="title">本月专供零售额(万元)</p>
                  <p class='radio'>{{ dedicatedProductData.dedicatedRetailAmount}}</p>
                  <!-- <p class="compare month">
                  较上月 10.3%
                  <img  v-if="dedicatedProductData.arrowMonth == 1" src="@/assets/order/上升@2x.png"  alt="">
                  <img  v-if="dedicatedProductData.arrowMonth == 0" src="@/assets/order/下降@2x.png"  alt="">
                 </p>
                 <p class="compare year">
                  较去年 5%
                  <img  v-if="dedicatedProductData.arrowYear == 1" src="@/assets/order/上升@2x.png"  alt="">
                  <img  v-if="dedicatedProductData.arrowYear == 0" src="@/assets/order/下降@2x.png"  alt="">
                 </p> -->
                </div>
                <!-- 中高端占比 -->
                <div class='proportionExclusiveSupply proportionMidHighEnd'>
                  <p class="title">本月中高端占比
                    <a-popover placement="topRight">
                      <template slot="content" style="margin-left: -20px">
                        中高端产品占比=本月中高端产品零售额/月度实际零售额
                      </template>
                      <i style='color:#AAAAAA;font-size: 12px' class="iconfont icon-wentitubiao"></i>
                    </a-popover>
                  </p>
                  <p class='radio'><span>{{ midHighData.midHighRatio?midHighData.midHighRatio.split('%')[0]:'0'}}</span><span style="font-size:14px">%</span></p>
                  <p class="compare month">
                    较上月 {{midHighData.chain}}
                    <img  v-if="midHighData.arrowMonth == 1" src="@/assets/order/上升@2x.png"  alt="">
                    <img  v-if="midHighData.arrowMonth == 0" src="@/assets/order/下降@2x.png"  alt="">
                  </p>
                  <p class="compare year">
                    较去年 {{midHighData.year}}
                    <img  v-if="midHighData.arrowYear == 1" src="@/assets/order/上升@2x.png"  alt="">
                    <img  v-if="midHighData.arrowYear == 0" src="@/assets/order/下降@2x.png"  alt="">
                  </p>
                </div>
                <!-- echarts图 -->
                <div class="orgBox">
                  <div class="lastEcharts" ref="lastEcharts"></div>
                  <ul class="rightText">
                    <li><span class="pink"></span><span class="zgd">中高端</span><span class="zgdPrice">{{ midHighData.midHighAmount }}万</span></li>
                    <li><span class="blue"></span><span class="zdd">中低端</span><span  class="zgdPrice">{{ midHighData.midLowAmount }}万</span></li>
                  </ul>
                </div>


              </div>

            </div>
            <div class="bottomEcharts">
              <div class="every echartsBoxtwo echartsOne">
                <div class="bigBox">
                  <div class="leftPart">
                    <p>本月毛利总额(万元)</p>
                    <p>{{sevenData.currentGrossTotalAmount}}</p>
                    <p>较上月 {{sevenData.chain}}
                      <img v-if="sevenData.arrowMonth == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                      <img v-if="sevenData.arrowMonth == 0 "  src="./../../../assets/home/下降@2x.png" alt="">
                    </p>
                    <p>
                      较去年 {{sevenData.year}}
                      <img v-if="sevenData.arrowYear == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                      <img v-if="sevenData.arrowYear == 0 " src="./../../../assets/home/下降@2x.png" alt="">
                    </p>
                  </div>
                  <div class="rightPart">
                    <div class="centerTxt">
                      <p>{{sevenData.completion}}<span>%</span></p>
                      <p>完成进度</p>
                    </div>
                    <div class="content" ref="numseven"></div>
                  </div>
                </div>
              </div>
              <div class="echartsOne twoSmall" v-show="!showDlBox">
                <div class="dataBox">
                  <p class="title">库存周转天数
                    <a-popover placement="topRight">
                      <template slot="content" style="margin-left: -20px">
                        库存周转天数=（月度库存均值/月度零售额） *30
                      </template>
                      <i style='color:#AAAAAA;font-size: 12px' class="iconfont icon-wentitubiao"></i>
                    </a-popover>
                  </p>
                  <p class="num">{{inventoryTurnoverData.turnoverDays}}</p>
                  <span class="lastM">
                    较上月 {{inventoryTurnoverData.chainDay}}
                    <img v-if="inventoryTurnoverData.arrowChainDay == 1" src="@/assets/order/上升@2x.png" alt="">
                    <img v-if="inventoryTurnoverData.arrowChainDay == 0" src="@/assets/order/下降@2x.png"  alt="">
                  </span>
                  <span class="lastY">
                    较去年 {{inventoryTurnoverData.yearDay}}
                    <img v-if="inventoryTurnoverData.arrowYearDay == 1" src="@/assets/order/上升@2x.png" alt="">
                    <img v-if="inventoryTurnoverData.arrowYearDay == 0" src="@/assets/order/下降@2x.png"  alt="">

                  </span>
                </div>
                <div class="dataBox">
                  <p class="title">库存数量（台）</p>
                  <p class="num">{{inventoryTurnoverData.turnoverNum}}</p>
                  <span class="lastM">
                    较上月 {{inventoryTurnoverData.chainNum}}
                    <img   v-if="inventoryTurnoverData.arrowChainNum == 1" src="@/assets/order/上升@2x.png" alt="">
                    <img  v-if="inventoryTurnoverData.arrowChainNum == 0" src="@/assets/order/下降@2x.png"  alt="">
                  </span>
                  <span class="lastY">
                    较去年 {{inventoryTurnoverData.yearNum}}
                    <img  v-if="inventoryTurnoverData.arrowYearNum == 1" src="@/assets/order/上升@2x.png" alt="">
                    <img  v-if="inventoryTurnoverData.arrowYearNum == 0"  src="@/assets/order/下降@2x.png"  alt="">

                  </span>
                </div>
              </div>
              <div class="every echartsBoxfour4 echartsOne">
                <div class="box1">
                  <p>
                    <img src="./../../../assets/home/shijianhong.png" alt="">
                    本月活动场次(场)
                  </p>
                  <p>{{activityData.monthActivityNum}}</p>
                </div>
                <div class="box2">
                  <p>
                    <img src="./../../../assets/home/shijianlan.png" alt="">
                    季度累计场次(场)
                  </p>
                  <p>{{activityData.quarterActivityNum}}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- <div class="rightBoxzm">
          <div class="bigBBBox">
            <div class="every echartsBoxone">
              <div class="bigBox">
                <div class="leftPart">
                    <p>本月提货总额(万元)</p>
                    <p>{{sixData.currentMonthTotal}}</p>
                    <p>较上月 {{sixData.chain}}
                      <img v-if="sixData.arrowMonth == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                      <img v-if="sixData.arrowMonth == 0 " src="./../../../assets/home/下降@2x.png" alt="">
                    </p>
                    <p>
                      较去年 {{sixData.year}}
                      <img v-if="sixData.arrowYear == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                      <img v-if="sixData.arrowYear == 0 "  src="./../../../assets/home/下降@2x.png" alt="">
                    </p>
                </div>
                <div class="rightPart">
                  <div class="centerTxt">
                    <p>{{sixData.completion}}<span>%</span></p>
                    <p>提货完成率</p>
                  </div>
                  <div class="content" ref="numsix"></div>
                </div>

              </div>
            </div>
            <div class="every echartsBoxtwo">
              <div class="bigBox">
                <div class="leftPart">
                  <p>本月毛利总额(万元)</p>
                  <p>{{sevenData.currentGrossTotalAmount}}</p>
                  <p>较上月 {{sevenData.chain}}
                    <img v-if="sevenData.arrowMonth == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="sevenData.arrowMonth == 0 "  src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                  <p>
                    较去年 {{sevenData.year}}
                    <img v-if="sevenData.arrowYear == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="sevenData.arrowYear == 0 " src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                </div>
                <div class="rightPart">
                  <div class="centerTxt">
                    <p>{{sevenData.completion}}<span>%</span></p>
                    <p>毛利率</p>
                  </div>
                  <div class="content" ref="numseven"></div>
                </div>

              </div>
            </div>
            <div class="every echartsBoxthree3">
              <div class="bigBox">
                <div class="leftPart">
                  <p>本月零售总额(万元)</p>
                  <p>{{eightData.currentRetailTotalAmount}}</p>
                  <p>较上月 {{ eightData.chain }}
                    <img v-if="eightData.arrowMonth == 1 " src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="eightData.arrowMonth == 0 " src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                  <p>
                    较去年 {{eightData.year }}
                    <img v-if="eightData.arrowYear == 1 "   src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="eightData.arrowYear == 0 "  src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                </div>
                <div class="rightPart">
                  <div class="centerTxt">
                    <p>{{ eightData.completion}}<span>%</span></p>
                    <p>零售完成率</p>
                  </div>
                  <div class="content" ref="numeight"></div>
                </div>

              </div>

            </div>
            <div class="every echartsBoxfour4">
              <div class="box1">
                <p>
                  <img src="./../../../assets/home/shijianhong.png" alt="">
                  本月活动场次(场)
                </p>
                <p>{{activityData.monthActivityNum}}</p>
              </div>
              <div class="box2">
                <p>
                  <img src="./../../../assets/home/shijianlan.png" alt="">
                  季度累计场次(场)
                </p>
                <p>{{activityData.quarterActivityNum}}</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="pickupAmountBox"  v-show="tableName == '运营商'">
        <div class="pickupAmountBox-lefIndex">
          <div class="pickupAmountBox-lefIndex-amountBox">
            <div class="every echartsBoxone">
              <div class="bigBox">
                <div class="leftPart">
                  <p>提货总额(万元)</p>
                  <p>{{takeDelivery&&takeDelivery.accomplish?Number(takeDelivery.accomplish).toFixed(0):'0'}}</p>
                  <p>
                    较去年 {{takeDelivery&&takeDelivery.yoy?Number(takeDelivery.yoy).toFixed(1):'0.0'}}%
                    <img v-if="takeDelivery&&takeDelivery.yoy > 0"  src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="takeDelivery&&takeDelivery.yoy < 0"  src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                </div>
                <div class="rightPart">
                  <div class="centerTxt">
                    <p>{{ takeDelivery&&takeDelivery.rate?Number(takeDelivery.rate).toFixed(1):'0.0'}}%<span></span></p>
                    <p>完成进度</p>
                  </div>
                  <div class="content" ref="totalPickupAmount"></div>
                </div>
              </div>
            </div>

          </div>
          <div class="pickupAmountBox-lefIndex-structureImprovement">
            <p class="pickupAmountBox-lefIndex-structureImprovement-title">结构改善</p>
            <div class="pickupAmountBox-lefIndex-structureImprovement-echartsBox">
              <div class="twoBox">
                <p class="distributionNetworkRanking">
                  <span class="title">高中端占比(%)</span>
                  <span class="ranking">排名<span class="currentRanking">{{ midHigProportion&&midHigProportion.rankingShow || 0 }}</span>/{{ midHigProportion&&midHigProportion.custNum || 0 }}</span>
                </p>
                <div class="orgProgressbar" ref="proportionusers"></div>
                <div class="reachBox">
                  <p class="reach">达成<span class="redTips">{{midHigProportion && midHigProportion.accomplish?Number(midHigProportion.accomplish).toFixed (1) : '0.0'}}</span>/{{midHigProportion && midHigProportion.target?Number(midHigProportion.target).toFixed (1)  : '0.0'}}
                  </p>
                  <p class="compare">
                    较去年 {{midHigProportion&&midHigProportion.yoy?Number(midHigProportion.yoy).toFixed(1):'0.0'}}%
                    <img v-if="midHigProportion&&midHigProportion.yoy > 0" src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="midHigProportion&&midHigProportion.yoy < 0" src="./../../../assets/home/下降@2x.png" alt="">

                  </p>
                </div>
              </div>
              <div class="twoBox">
                <p class="distributionNetworkRanking">
                  <span class="title">专供机占比(%)</span>
                  <span class="ranking">排名<span class="currentRanking">{{ dedicatedProportion&&dedicatedProportion.rankingShow || 0 }}</span>/{{ dedicatedProportion&&midHigProportion.custNum || 0 }}</span>
                </p>
                <div class="orgProgressbar" ref="dedicatedMachine"></div>
                <div class="reachBox">
                  <p class="reach">达成<span class="redTips">{{dedicatedProportion && dedicatedProportion.accomplish?Number(dedicatedProportion.accomplish).toFixed (1): '0.0'}}</span>/{{dedicatedProportion && dedicatedProportion.target?Number(dedicatedProportion.target).toFixed (1) : '0.0'}}</p>
                  <p class="compare">
                    较去年 {{ dedicatedProportion&&dedicatedProportion.yoy?Number(dedicatedProportion.yoy).toFixed(1):'0.0'}}%
                    <img v-if="dedicatedProportion&&dedicatedProportion.yoy > 0" src="./../../../assets/home/上升@2x.png" alt="">
                    <img v-if="dedicatedProportion&&dedicatedProportion.yoy < 0" src="./../../../assets/home/下降@2x.png" alt="">
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pickupAmountBox-rightIndex">
          <div class="pickupAmountBox-rightIndex-leftTxt">
            <p class="pickupAmountBox-rightIndex-leftTxt-title">本月毛利总额(万元)</p>
            <p class="pickupAmountBox-rightIndex-leftTxt-num">{{grossProfit&&grossProfit.accomplish?Number(grossProfit.accomplish).toFixed(1):'0.0'}}</p>
            <p class="pickupAmountBox-rightIndex-leftTxt-compare">
              <span>较去年 {{grossProfit&&grossProfit.yoy?Number(grossProfit.yoy).toFixed(1):'0.0'}}%</span>
              <img v-if="grossProfit&&grossProfit.yoy > 0"  src="./../../../assets/home/上升@2x.png" alt="">
              <img v-if="grossProfit&&grossProfit.yoy < 0"  src="./../../../assets/home/下降@2x.png" alt="">
            </p>
            <p class="pickupAmountBox-rightIndex-leftTxt-indicatorIdentification">
              <span class="greenCircle"></span>
              <span class="name">毛利率<span class="proportion">{{ grossProfit&&grossProfit.rate?Number(grossProfit.rate).toFixed(2):'0.00'}}%</span></span>
            </p>
            <p class="pickupAmountBox-rightIndex-leftTxt-indicatorIdentification">
              <span class="blueCircle"></span>
              <span class="name">基本运营点位<span class="proportion">{{ grossProfit&&grossProfit.basicOperationPoint?Number(grossProfit.basicOperationPoint).toFixed(2):'0.00'}}</span>%</span>
            </p>
          </div>
          <div class="rightEcharts" ref="rightEcharts"></div>

        </div>

      </div>
    </div>
  </a-spin>
</template>

<script>
import moment from "moment";
import Util from "@/utils/utils";
import {
  findComprehensiveEvaluationNew,
  getfindActivity,
  getfindDelivery,
  getfindGrossProfit,
  getfindRetail,
  reportUnionAllMonth,
  reportUnionAllYear,
  saveLog
} from "../api.js";
import * as echarts from "echarts";
import { SalesShopInfoList } from "@/views/confirmAgain/api";
import { queryShopMessage,getfindDedicatedProduct,getfindMidHigh,getfindInventoryTurnover } from "@/views/reports/specialStoreCE/api";
moment.locale("zh-cn");
export default {
  name: "evaluationOfcoreFunctions",
  data() {
    return {
      pageLoad: false,
      isCherkZH: false,// 展示选中数据需要删除图标
      isShowMoreZH: false,// 综合更多选项展开收起
      isHaveVal: false,// 综合前边的删除图标是否展示
      filterLoadFlag: false,// 排序展示数据loading效果
      cherZhname: "",// 排序选中的名称
      orderBy:'',
      shopTypeId:'',
      shopTypeName:'',
      PxList: [],// 排序筛选列表
      custnum:0,
      paiming:0,
      zonghedefr:0,
      filForm: {},
      tableData:{},
      mubiaoArray:[0,0,0,0],
      shijiArray:[0,0,0,0],
      tableNameArr:["全渠道口径出货", "分销网拓展与维护", "零售能力提示", "提货结构改善"],
      tablist:[
        {
          id:0,
          name:'运营商'
        },
        {
          id:1,
          name:'专卖店'
        }
      ],
      tabIndex:0,
      tableName:'',
      customerNum:'',// 商家总数
      comprehensiveRanking:'', // 排名
      comprehensiveScore:'--', // 综合得分

      customerNumZM:'',// 商家总数
      comprehensiveRankingZM:'', // 排名
      comprehensiveScoreZM:'--', // 综合得分
      pieData:[
        {value: 0, name: '全渠道口径出货(60) '},
        {value: 0, name: '分销网拓展与维护(20)'},
        {value: 0, name: '零售能力提示(5)'},
        {value: 0, name: '提货结构改善(15) '},
      ],// 饼图数据
      DataZM:[
        {value: 0, name: '月度履约进度(30)'},
        {value: 0, name: '学习综合得分(20)'},
        {value: 0, name: '专供产品占比(15)'},
        {value: 0, name: '中高端产品占比(15)'},
        {value: 0, name: '库存周转天数(20)'},
      ],// 饼图数据
      //运营商数据
      InventoryData:{
        "turnoverDays": 0,//周转天数
        "compareLastMonth": '',//较上月
        "arrowMonth": null,//较上月增幅 （1：正，0：负）
        "inventoryTotalNum": 0,//库存总数
        "inventoryList": []
      },
      //提货
      collectionDelivery: {
        completionjd:10,
        "monthAmount": 10,//提货总额
        "completion": '20',//任务完成进度
        "compareWithLastMonth": "40",//较上月
        "compareWithLastYear": "30",//较上年
        "arrowYear": null,//较上年增幅箭头 0标识负值，1标识正值得
        "arrowMonth": null//较上月增幅箭头 0标识负值，1标识正值得
      },
      totalPickupAmountData: {
        completionjd:10,
        "monthAmount": 10,//提货总额
        "completion": '20',//任务完成进度
        "compareWithLastMonth": "40",//较上月
        "compareWithLastYear": "30",//较上年
        "arrowYear": null,//较上年增幅箭头 0标识负值，1标识正值得
        "arrowMonth": null//较上月增幅箭头 0标识负值，1标识正值得
      },
      grossMargin:{
        "totalAmount": "0.0",//本月毛利总额
        "compareLastMonth": null,//较上月
        "compareLastYear": null,//较上年
        "grossMarginRate": "0",//毛利率
        grossMarginRatejd:0,
      },
      salesReport:{},// 销售

      // 专卖店===>本月提货数据
      sixData:{
        completionjd:0,
        currentMonthTotal:0, //本月提货总额
        chain:0,// 较上月
        year:0,//较去年
        completion:'0',//提货完成率
        arrowYear:null, // //较上年增幅箭头 0标识负值，1标识正值得
        arrowMonth:null,//较上月增幅箭头 0标识负值，1标识正值得
        currentYearTotal:0,
      },
      //   毛利
      sevenData:{
        currentGrossTotalAmount:0, //本月提货总额
        chain:0,// 较上月
        year:0,//较去年
        completion:'0',//提货完成率
        completionjd:0,
        arrowYear:null, // //较上年增幅箭头 0标识负值，1标识正值得
        arrowMonth:null,//较上月增幅箭头 0标识负值，1标识正值得
      },
      eightData:{
        completionjd:0,
        currentRetailTotalAmount:0, //本月提货总额
        chain:0,// 较上月
        year:0,//较去年
        completion:'0',//提货完成率
        arrowYear:null, // //较上年增幅箭头 0标识负值，1标识正值得
        arrowMonth:null,//较上月增幅箭头 0标识负值，1标识正值得
      },
      //活动数据
      activityData:{
        "monthActivityNum": "0",//本月活动场次
        "monthActivitySalesNum": "0",//月度活动销量
        "monthActivitySalesAmount": "0",//月度活动销额
        "quarterActivityNum": "0"//季度累计场次
      },
      queryTime:'',
      showCD:false,
      // 月度履约进度数据
      performanceSchedule:{
        monthDeliveryTask:'',
        monthDeliveryTotalAmount:'',
        monthPerformanceSchedule:'',
        monthPerformanceSchedulejd:20,
      },
      turnoverRate:{
        monthDeliveryTask:'',
        monthDeliveryTotalAmount:'',
        monthPerformanceSchedule:'',
        monthPerformanceSchedulejd:20,
      },
      singleOutletOutput:{
        monthDeliveryTask:'',
        monthDeliveryTotalAmount:'',
        monthPerformanceSchedule:'',
        monthPerformanceSchedulejd:20,
      },
      tabActive:1,// 年报月报切换，月报-1，年报-2
      dateEchoValue: this.$util.formatDate(new Date().getTime() - 86400000, 'Y-M'),// 默认当月
      dateEchoYearValue: this.$util.formatDate(new Date().getTime() - 86400000, 'Y'),
      minDate: new Date(2023, 0).getTime(),
      maxDate: new Date().getTime() - 86400000,
      pickerMonthOptions: {
        disabledDate(time) {
          return time.getTime() > new Date().getTime() - 86400000 || time.getTime() < new Date(2023, 0).getTime();
        },
      },
      pickerYearOptions: {
        disabledDate(time) {
          // var year = new Date().getFullYear()
          return time.getTime() > new Date().getTime() - 86400000 || time.getTime() < new Date(2023, 0).getTime();

          // return (
          //   time.getTime() > new Date(year + '-12-31').getTime()- 86400000 ||
          //   time.getTime() < new Date(year + '-01-01').getTime()- 86400000
          // )
        }
      },
      operatorData: null, // 运营商核心指标数据
      summaryScoreList: [{
        name: "全渠道口径出货",// 指标名称
        fullScore: "50",// 满分
        score: "",// 得分
        value: "0",// 得分-echarts传值需要
      },
        {
          name: "分销网络拓展与维护",
          fullScore: "20",
          score: "",
          value: "0",// 得分
        },
        {
          name: "零售能力提升",
          fullScore: "15",
          score: "",
          value: "0",
        },
        {
          name: "提货结构改善",
          fullScore: "15",
          score: "",
          value: "0",
        }], // 运营商核心指标右侧列表
      channelDiameter: null, // 全渠道口径出货
      preposition: null, // 前置及异业规模
      onlineStore: null, // 线上店销售
      distributeNet: null, // 分销网点净增
      distributeRate: null, // 分销网络动销率
      singlePoint: null, // 单网点产出
      takeDelivery: null, // 提货
      midHigProportion: null, // 高中端占比
      dedicatedProportion: null, // 专供机占比
      grossProfit: null, // 毛利
      // 专供机占比
      dedicatedProductData:{
        "dedicatedRetailAmount": "0",//专供产品零售额
        "totalRetailAmount": "0",//门店零售额
        "ratio": "0",//专供产品占比
        "chain": null,//较上月
        "arrowMonth": null,//同比(较上月)增长幅箭头 1:正，0：负
        "year": null,//较上年
        "arrowYear": null,//环比(较上年)增长幅箭头 1:正，0：负
        "categoryRatioList": []
      },
      // 中高端占比数据
      midHighData: {
        "totalAmount": "0",//总额（高、中高、低、中低的和）
        "midHighAmount": "0",//中高端销售额(高端+中高端)
        "midHighRatio": "0%",//中高端占比(高端+中高端)
        "chain": null,//较上月（中高端）
        "arrowMonth": null,//较上月增幅箭头 0标识负值，1标识正值得
        "year": null,//较上年（中高端）
        "arrowYear": null,//较上年增幅箭头 0标识负值，1标识正值得
        "midLowAmount": "0",//中低端销售额(低端+中低端)
        "midLowRatio": "0%",//中低端占比(低端+中低端)
        "categoryRatioList": [//各品类中高端占比集合
          // {
          //   "categoryName": "海信洗衣机",//品类名称
          //   "ratio": "100.0%"//占比
          // }
        ],
      },
      queryTimeYes:'',
      // 库存数据
      inventoryTurnoverData:{
        "turnoverDays": "0",//库存周转天数
        "turnoverNum": 0,//库存数量
        "chainDay": "0",//较上月（天数）
        "chainNum": "0",//较上月（数量）
        "yearDay": 0,//较去年（天数）
        "yearNum": 0,//较去年（数量）
        "arrowChainDay":null ,//库存周转天数 环比(较上月)增长幅箭头 1:正，0：负
        "arrowChainNum": null,//库存周转数量 环比(较上月)增长幅箭头 1:正，0：负
        "arrowYearDay": null,//库存周转天数 同比(较去年)增长幅箭头 1:正，0：负
        "arrowYearNum": null//库存周转数量 同比(较去年)增长幅箭头 1:正，0：负
      },
      showDlBox:false,
    };
  },
  props: {
    subMenuList: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo
        : localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo"))
          : "";
    }
  },
  watch: {
    data: function() {}
  },
  created() {

    this.filForm.date = moment(new Date()).format("YYYY-MM");
    if(this.$store.state.user.userInfo.showExclusiveCore == 0  && this.$store.state.user.userInfo.showOperatorCore == 0) {
      this.showCD = false
      return
    }
    if(this.$store.state.user.userInfo.showExclusiveCore == 1  && this.$store.state.user.userInfo.showOperatorCore == 1){
      this.tablist= [
        {
          id:0,
          name:'运营商'
        },
        {
          id:1,
          name:'专卖店'
        }
      ]
      this.tabIndex = 0
      this.tableName = '运营商'
      this.showCD = true
      return;
    }
    if(  this.$store.state.user.userInfo.showOperatorCore == 1  && this.$store.state.user.userInfo.showExclusiveCore == 0) {
      this.tablist= [
        {
          id:0,
          name:'运营商'
        },
      ]
      this.tabIndex = 0
      this.tableName = '运营商'
      this.showCD = true
      return;
    }
    if(this.$store.state.user.userInfo.showOperatorCore == 0  && this.$store.state.user.userInfo.showExclusiveCore == 1) {
      this.tablist= [
        {
          id:0,
          name:'专卖店'
        }
      ]
      this.tabIndex = 0
      this.tableName = '专卖店'
      this.showCD = true
      return;
    }
  },
  mounted() {
    if(this.userInfo) {
      if(this.$store.state.user.userInfo.isAgentOperator == 1) {
        this.showDlBox = true
      } else {
        this.showDlBox = false
      }
      this.getTime()
      if(this.tableName == '运营商') {
        this.getReportDataNew()
        // this.getTH()
        // 全渠道口径出货(万元)

        // this.getMLL()
        // this.getKC()
        // this.getSALE()

      }
      if( this.tableName == '专卖店'){
        this.getYesterday()
        // this.drawChartsfive()
        this.getShopLLIst()
        this.getzgj()
        // 中高端数据
        this.getzgd()
        this.getKucun()
      }

    }

  },
  methods: {
    // 库存数据查询
    getKucun(){
      let data  = {
        cisShopCode:this.orderBy ,
        queryTime: this.queryTime,
      }
      getfindInventoryTurnover(data).then(res=>{
        if(res.data.code == 0){
          this.inventoryTurnoverData.turnoverDays = res.data.data.inventoryTurnover.turnoverDays?res.data.data.inventoryTurnover.turnoverDays:0
          this.inventoryTurnoverData.turnoverNum = res.data.data.inventoryTurnover.turnoverNum?res.data.data.inventoryTurnover.turnoverNum:0
          this.inventoryTurnoverData.chainDay = res.data.data.inventoryTurnover.chainDay?res.data.data.inventoryTurnover.chainDay:0
          this.inventoryTurnoverData.chainNum = res.data.data.inventoryTurnover.chainNum?res.data.data.inventoryTurnover.chainNum:0
          this.inventoryTurnoverData.yearDay = res.data.data.inventoryTurnover.yearDay?res.data.data.inventoryTurnover.yearDay:0
          this.inventoryTurnoverData.yearNum = res.data.data.inventoryTurnover.yearNum?res.data.data.inventoryTurnover.yearNum:0
          this.inventoryTurnoverData.arrowChainDay = res.data.data.inventoryTurnover.arrowChainDay?res.data.data.inventoryTurnover.arrowChainDay:null
          this.inventoryTurnoverData.arrowChainNum = res.data.data.inventoryTurnover.arrowChainNum?res.data.data.inventoryTurnover.arrowChainNum:null
          this.inventoryTurnoverData.arrowYearDay = res.data.data.inventoryTurnover.arrowYearDay?res.data.data.inventoryTurnover.arrowYearDay:null
          this.inventoryTurnoverData.arrowYearNum = res.data.data.inventoryTurnover.arrowYearNum?res.data.data.inventoryTurnover.arrowYearNum:null
          this.$forceUpdate()
        }else {
          this.$message.warning(res.data.msg)
        }
      }).catch(error=>{
      })
    },
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      this.currentPreviousDay = day1.getFullYear()+"/" + (day1.getMonth()+1) + "/" + day1.getDate();

      let Y = day1.getFullYear(); // 年
      let M = (day1.getMonth() + 1 < 10 ? '0' + (day1.getMonth() + 1) : day1.getMonth() + 1);//月
      let D = (day1.getDate() < 10 ? '0' + (day1.getDate() ) : day1.getDate())
      this.queryTimeYes = Y + '-' + M+'-'+D
    },
    //专供机占比数据
    getzgj(){
      let data  = {
        cisShopCode:this.orderBy  ,
        queryTime: this.queryTime,
      }
      getfindDedicatedProduct(data).then(res=>{
        if(res.data.code == 0) {
          //专供产品零售额
          this.dedicatedProductData.dedicatedRetailAmount = res.data.data.dedicatedProduct.dedicatedRetailAmount? res.data.data.dedicatedProduct.dedicatedRetailAmount:0
          //专供产品占比
          this.dedicatedProductData.ratio = res.data.data.dedicatedProduct.ratio? res.data.data.dedicatedProduct.ratio:0
          // 较上月
          this.dedicatedProductData.chain = res.data.data.dedicatedProduct.chain? res.data.data.dedicatedProduct.chain:0
          // 较去年
          this.dedicatedProductData.year = res.data.data.dedicatedProduct.year? res.data.data.dedicatedProduct.year:0
          // 较上月符号
          this.dedicatedProductData.arrowMonth = res.data.data.dedicatedProduct.arrowMonth? res.data.data.dedicatedProduct.arrowMonth:null
          // 较去年符号
          this.dedicatedProductData.arrowYear = res.data.data.dedicatedProduct.arrowYear? res.data.data.dedicatedProduct.arrowYear:null
          //  循环数据
          this.dedicatedProductData.categoryRatioList = res.data.data.dedicatedProduct.arrowYear? res.data.data.dedicatedProduct.arrowYear:[]
          this.dedicatedProductData.categoryRatioList=res.data.data.dedicatedProduct.categoryRatioList
          // 如果数据大于0  截取%之前 赋值
          if( this.dedicatedProductData.categoryRatioList.length > 0) {
            this.dedicatedProductData.categoryRatioList.forEach(item=>{
              if(item.ratio) {
                item.ratio =  Number(item.ratio.split('%')[0])
              } else {
                item.ratio = 0
              }
            })
          }
          this.$forceUpdate()
        }else {
          this.$message.warning(res.data.msg)
        }

      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 获取中高端
    getzgd(){
      this.pageLoadFlag = true
      let data  = {
        cisShopCode:this.orderBy ,
        queryTime: this.queryTime,
      }
      getfindMidHigh(data).then(res=>{
        if(res.data.code == 0) {
          this.midHighData.totalAmount = res.data.data.midHigh.totalAmount?res.data.data.midHigh.totalAmount:0
          this.midHighData.midHighAmount = res.data.data.midHigh.midHighAmount?res.data.data.midHigh.midHighAmount:0
          this.midHighData.midLowAmount = res.data.data.midHigh.midLowAmount?res.data.data.midHigh.midLowAmount:0
          this.midHighData.midHighRatio = res.data.data.midHigh.midHighRatio?res.data.data.midHigh.midHighRatio:0
          this.midHighData.midLowRatio = res.data.data.midHigh.midLowRatio?res.data.data.midHigh.midLowRatio:0
          this.midHighData.chain = res.data.data.midHigh.chain?res.data.data.midHigh.chain:0
          this.midHighData.year = res.data.data.midHigh.year?res.data.data.midHigh.year:0
          this.midHighData.arrowMonth = res.data.data.midHigh.arrowMonth?res.data.data.midHigh.arrowMonth:null
          this.midHighData.arrowYear = res.data.data.midHigh.arrowYear?res.data.data.midHigh.arrowYear:null
          this.midHighData.categoryRatioList = res.data.data.midHigh.categoryRatioList
          if(this.midHighData.categoryRatioList.length > 0) {
            this.midHighData.categoryRatioList.forEach(item=>{
              if(item.ratio) {
                item.ratio =  Number(item.ratio.split('%')[0])
              } else {
                item.ratio = 0
              }
            })
          }
          this.$nextTick(()=>{
            this.lastEcharts()
          })
          this.$forceUpdate()
          this.pageLoadFlag = false
        } else {
          this.$nextTick(()=>{
            this.lastEcharts()
          })
          this.pageLoadFlag = false
        }
      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    lastEcharts(){
      var myChart = this.$echarts.init(this.$refs.lastEcharts);
      let option = {
        series: [{
          name: "",
          type: "pie",
          radius: '68%',
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [{
            value:this.midHighData.midLowAmount,
            name: "中高端",
            selected: true
          },
            {
              value:this.midHighData.midHighAmount,
              name: "中低端"
            },

          ],
          itemStyle: {
            normal: {
              borderWidth: 3,
              borderColor: '#ffffff',
            },

          },
          color: [
            '#727EFF',
            '#FF8A8A',

          ],
        }],

      };
      myChart.setOption(option);
    },
    exportClick(){
      let logData = {
        interfaceName:'yysyzb/export'
      }
      saveLog(logData).then(res=>{})
      if(this.tabActive == 1 ) {
        let dataMonth = {
          yysCode:this.$store.state.user.userInfo.customer.cisCode,
          queryTime:this.dateEchoValue
        }
        this.pageLoad = true
        reportUnionAllMonth(dataMonth).then(res => {
          Util.blobToJson(res.data).then(content=>{

            if(content&&content.code == "success"){
              this.$message.success('下载成功，请到下载中心查看!')
              this.pageLoad = false;
            }
          }).catch(err=>{
            Util.downloadFile(res.data,'核心指标.xls')
            this.pageLoad = false;
          }).finally(()=>{
            this.pageLoad = false;
          })
        }).catch(err=>{
          this.pageLoad = false;
        });
      }else {
        this.pageLoad = true
        let myDate = new Date();
        let tYear = myDate.getFullYear();
        let dataYearth = {
          yysCode:this.$store.state.user.userInfo.customer.cisCode,
          queryTime:tYear!=this.dateEchoYearValue?this.dateEchoYearValue+'-12':this.dateEchoValue
        }
        reportUnionAllYear(dataYearth).then(res => {
          Util.blobToJson(res.data).then(content=>{
            if(content&&content.code == "success"){
              this.$message.success('下载成功，请到下载中心查看!')
              this.pageLoad = false;
            }
          }).catch(err=>{
            Util.downloadFile(res.data,'核心指标.xls')
            this.pageLoad = false;
          }).finally(()=>{
            this.pageLoad = false;
          })
        })
      }
    },

    exportClick1() {
      this.orderLoading = true;
      let data = {
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        custId: this.data.id,
        ...this.arg,
      };
      exportData(data)
        .then((res) => {
          Util.blobToJson(res.data)
            .then((content) => {
              if (content && content.code == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch((err) => {
              Util.downloadFile(res.data, "合作的分销商列表.xls");
            })
            .finally(() => {
              this.orderLoading = false;
            });
        })
        .catch((err) => {
          this.orderLoading = false;
        });
    },
    // 获取运营商一张表所有数据(新)
    getReportDataNew() {
      let data ={
        type:this.tabActive == 1 ?'month':'year',
        queryTime:this.tabActive == 1 ?this.dateEchoValue:this.dateEchoYearValue,
        cisCode: this.$store.state.user.userInfo.customer.cisCode
      }
      this.pageLoad = true
      findComprehensiveEvaluationNew(data).then(res=>{
        if(res.data.code == 0) {
          // 商家总数
          this.customerNum =  res.data.data.customerNum ?res.data.data.customerNum: '--'
          // 排名
          this.comprehensiveRanking = res.data.data.comprehensiveRanking ?res.data.data.comprehensiveRanking: '--'
          // 饼图数据
          this.pieData = []
          if(res.data.data&&res.data.data.summaryScoreList && res.data.data.summaryScoreList.length > 0){
            res.data.data.summaryScoreList.forEach(item=>{
              item.fullScore = parseInt(item.fullScore)
              this.pieData.push({
                value:item.score?Number(item.score).toFixed(1):0.0,
                name:item.name+'('+item.fullScore+')'
              })
            })
          }  else  {
            this.pieData = [
              {value: 0, name: '全渠道口径出货(60) '},
              {value: 0, name: '分销网拓展与维护(20)'},
              {value: 0, name: '零售能力提示(5)'},
              {value: 0, name: '提货结构改善(15) '},
            ]
          }

          // 综合得分
          this.comprehensiveScore = res.data.data.comprehensiveScore ?res.data.data.comprehensiveScore: '0'
          if(res.data.data.individualEvaluations && res.data.data.individualEvaluations.length > 0) {
            res.data.data.individualEvaluations.forEach(el => {
              if(el.functionName == '全渠道口径出货') {
                this.channelDiameter = el
              }
              if(el.functionName == '前置及异业规模') {
                this.preposition = el
              }
              if(el.functionName == '线上店销售') {
                this.onlineStore = el
              }
              if(el.functionName == '分销网点净增') {
                this.distributeNet = el
              }
              if(el.functionName == '分销网络动销率') {
                this.distributeRate = el
              }
              if(el.functionName == '单网点产出') {
                this.singlePoint = el
              }
              if(el.functionName == '提货') {
                this.takeDelivery = el
              }
              if(el.functionName == '高中端占比') {
                this.midHigProportion = el
              }
              if(el.functionName == '专供机占比') {
                this.dedicatedProportion = el
              }
              if(el.functionName == '毛利') {
                this.grossProfit = el
              }
            })
          }else{
            this.channelDiameter = null
            this.preposition = null
            this.onlineStore = null
            this.distributeNet = null
            this.distributeRate = null
            this.singlePoint = null
            this.takeDelivery = null
            this.midHigProportion = null
            this.dedicatedProportion = null
            this.grossProfit = null
          }
          this.$nextTick(()=>{
            this.drawCharts()
            this.num1Echarts()
            this.getEchartsPreEmployment()
            this.getEchartsPreEmploymentTwo()
            this.drawChartsgreenProgressbar()
            this.drawChartsorgProgressbar()
            this.drawChartspurpleProgressbar()
            this.totalPickupAmountEcharts()
            this.drawChartProportionusers()
            this.drawChartdedicatedMachine()
            this.grossMarginchart()
          })
          this.pageLoad = false

        }else {
          this.pieData = [
            {value: 0, name: '全渠道口径出货(60) '},
            {value: 0, name: '分销网拓展与维护(20)'},
            {value: 0, name: '零售能力提示(5)'},
            {value: 0, name: '提货结构改善(15) '},
          ]
          this.channelDiameter = null
          this.preposition = null
          this.onlineStore = null
          this.distributeNet = null
          this.distributeRate = null
          this.singlePoint = null
          this.takeDelivery = null
          this.midHigProportion = null
          this.dedicatedProportion = null
          this.grossProfit = null
          this.$nextTick(()=>{
            this.drawCharts()
            this.num1Echarts()
            this.getEchartsPreEmployment()
            this.getEchartsPreEmploymentTwo()
            this.drawChartsgreenProgressbar()
            this.drawChartsorgProgressbar()
            this.drawChartspurpleProgressbar()
            this.totalPickupAmountEcharts()
            this.drawChartProportionusers()
            this.drawChartdedicatedMachine()
            this.grossMarginchart()
          })
          this.pageLoad = false
        }
      }).catch(error=>{
        this.pieData = [
          {value: 0, name: '全渠道口径出货(60) '},
          {value: 0, name: '分销网拓展与维护(20)'},
          {value: 0, name: '零售能力提示(5)'},
          {value: 0, name: '提货结构改善(15) '},
        ]
        this.channelDiameter = null
        this.preposition = null
        this.onlineStore = null
        this.distributeNet = null
        this.distributeRate = null
        this.singlePoint = null
        this.takeDelivery = null
        this.midHigProportion = null
        this.dedicatedProportion = null
        this.grossProfit = null
        this.$nextTick(()=>{
          this.drawCharts()
          this.num1Echarts()
          this.getEchartsPreEmployment()
          this.getEchartsPreEmploymentTwo()
          this.drawChartsgreenProgressbar()
          this.drawChartsorgProgressbar()
          this.drawChartspurpleProgressbar()
          this.totalPickupAmountEcharts()
          this.drawChartProportionusers()
          this.drawChartdedicatedMachine()
          this.grossMarginchart()
        })
        this.pageLoad = false
      })

    },
    // 修改月
    changeMonth(value){
      this.getReportDataNew()
      let logData = {
        interfaceName:'yysyzb/month'
      }
      saveLog(logData).then(res=>{})
    },
    // 修改年
    changeYonth(value){
      this.getReportDataNew()
      let logData = {
        interfaceName:'yysyzb/year'
      }
      saveLog(logData).then(res=>{})
    },
    handleChangeEnd(value, record) {
      record.curEndYear = value;
      if (record.curEndYear < record.curStartYear) {
        this.$message.warning("输入时间小于开始时间,请重新选择");
        record.curEndYear = "";
        this.$forceUpdate()
        return;
      }
      this.$forceUpdate()
    },
    // 切换月报/年报
    handleChangeTab(index) {
      this.tabActive = index
      let logData = {
        interfaceName:this.tabActive==1?'yysyzb/month':'yysyzb/year'
      }
      saveLog(logData).then(res=>{})
      if(this.tabActive != 1) {
        this.dateEchoYearValue = this.$util.formatDate(new Date().getTime()- 86400000, 'Y')
      }else {
        this.dateEchoValue = this.$util.formatDate(new Date().getTime()- 86400000, 'Y-M')
      }
      this.getReportDataNew() // 获取运营商数据
    },
    //  获取字体宽度
    countStrWidth(text) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      // 设置字体样式，当然，也可以在这里给一个默认值
      context.font = '12px Microsoft YaHei'  // 字体一定要写，不能只写一个字体大小，不启用
      const dimension = context.measureText(text)
      return dimension.width
    },
    // 分销网点年累净增
    drawChartsgreenProgressbar() {
      var myChart = this.$echarts.init(this.$refs.greenProgressbar);
      this.$forceUpdate()
      let value = ''
      if(this.distributeNet && this.distributeNet.rate && this.distributeNet.rate){
        if(this.distributeNet.rate.split('%')[0] >= 100){
          value = Number(this.distributeNet.rate.split('%')[0]).toFixed(1)
        } else {
          value = Number(this.distributeNet.rate.split('%')[0]).toFixed(1)
        }
      } else {
        value = '0.0'
      }

      let width = this.countStrWidth(value + '%')+6
      let offset = [0,0]
      if(width > 33.6 && Number(value) <= 0){
        offset = [-10,0]
      }
      if(width > 33.6 && Number(value) >= 1){
        offset = [8,0]
      }
      if(width > 33.6 && Number(value) < 1){
        offset = [14,0]
      }
      if(width > 38.8 && Number(value) <= 0){
        offset = [16,0]
      }
      if(width > 38.8 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) > 80){
        offset = [-16,0]
      }
      if(width > 40.6 && Number(value) > 90){
        offset = [-26,0]
      }
      if(width > 45.8 && Number(value) <= 0){
        offset = [19,0]
      }
      if((width> 47.7 && value>0) ){
        offset = [-30,0]
      }
      if((width> 47.7 && value<0) ){
        offset = [23,0]
      }



      let option={
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:width,
              height:20,
              position:'right',
              offset:offset,
              borderWidth:1,
              borderColor:'#18D1BC',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return value + '%';
              },
              textStyle:{
                color: '#18D1BC',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#18D1BC'
              },

            },
            barWidth: 14,
            data: [value>100?100:(value>1?value:0)]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    //动销率
    drawChartsorgProgressbar() {
      var myChart = this.$echarts.init(this.$refs.orgProgressbar);
      this.$forceUpdate()
      let value = '0.0'
      if(this.distributeRate && this.distributeRate.rate && this.distributeRate.rate){
        value = Number(this.distributeRate.rate.split('%')[0]).toFixed(1)
      } else {
        value = '0.0'
      }
      let width = this.countStrWidth(value + '%')+6
      let offset = [0,0]
      if(width > 33.6 && Number(value) <= 0){
        offset = [-10,0]
      }
      if(width > 33.6 && Number(value) >= 1){
        offset = [8,0]
      }
      if(width > 33.6 && Number(value) < 1){
        offset = [14,0]
      }
      if(width > 38.8 && Number(value) <= 0){
        offset = [16,0]
      }
      if(width > 38.8 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) > 80){
        offset = [-16,0]
      }
      if(width > 40.6 && Number(value) > 90){
        offset = [-26,0]
      }
      if(width > 45.8 && Number(value) <= 0){
        offset = [19,0]
      }
      if((width> 47.7 && value>0) ){
        offset = [-30,0]
      }
      if((width> 47.7 && value<0) ){
        offset = [23,0]
      }

      let option={
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:width,
              height:20,
              position:'right',
              offset:offset,
              borderWidth:1,
              borderColor:'#FAB81B',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return value + '%';
              },
              textStyle:{
                color: '#FAB81B',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#FAB81B'
              },

            },
            barWidth: 14,
            data: [value>100?100:(value>1?value:0)]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    //单网点产出(万元)
    drawChartspurpleProgressbar() {
      var myChart = this.$echarts.init(this.$refs.purpleProgressbar);
      this.$forceUpdate()
      let value = '0.0'
      if(this.singlePoint && this.singlePoint.rate && this.singlePoint.rate){
        value = Number(this.singlePoint.rate.split('%')[0]).toFixed(1)
      } else {
        value = '0.0'
      }
      let width = this.countStrWidth(value + '%')+6
      let offset = [0,0]
      if(width > 33.6 && Number(value) <= 0){
        offset = [-10,0]
      }
      if(width > 33.6 && Number(value) >= 1){
        offset = [8,0]
      }
      if(width > 33.6 && Number(value) < 1){
        offset = [14,0]
      }
      if(width > 38.8 && Number(value) <= 0){
        offset = [16,0]
      }
      if(width > 38.8 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) > 80){
        offset = [-16,0]
      }
      if(width > 40.6 && Number(value) > 90){
        offset = [-26,0]
      }
      if(width > 45.8 && Number(value) <= 0){
        offset = [19,0]
      }
      if((width> 47.7 && value>0) ){
        offset = [-30,0]
      }
      if((width> 47.7 && value<0) ){
        offset = [23,0]
      }

      let option={
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:width,
              height:20,
              position:'right',
              offset:offset,
              borderWidth:1,
              borderColor:'#8F98FF',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return param.value + '%';
              },
              textStyle:{
                color: '#8F98FF',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#8F98FF'
              },

            },
            barWidth: 14,
            data: [value>100?100:(value>1?value:0)]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    //高中端占比
    drawChartProportionusers() {
      var myChart = this.$echarts.init(this.$refs.proportionusers);
      this.$forceUpdate()
      let value = '0.0'
      if(this.midHigProportion && this.midHigProportion.rate && this.midHigProportion.rate){
        if(this.midHigProportion.rate.split('%')[0] >= 100){
          value = Number(this.midHigProportion.rate.split('%')[0]).toFixed(1)
        } else {
          value = Number(this.midHigProportion.rate.split('%')[0]).toFixed(1)
        }
      } else {
        value = '0.0'
      }
      let width = this.countStrWidth(value + '%')+6
      let offset = [0,0]
      if(width > 33.6 && Number(value) <= 0){
        offset = [-10,0]
      }
      if(width > 33.6 && Number(value) >= 1){
        offset = [8,0]
      }
      if(width > 33.6 && Number(value) < 1){
        offset = [14,0]
      }
      if(width > 38.8 && Number(value) <= 0){
        offset = [16,0]
      }
      if(width > 38.8 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) > 80){
        offset = [-16,0]
      }
      if(width > 40.6 && Number(value) > 90){
        offset = [-26,0]
      }
      if(width > 45.8 && Number(value) <= 0){
        offset = [19,0]
      }
      if((width> 47.7 && value>0) ){
        offset = [-30,0]
      }
      if((width> 47.7 && value<0) ){
        offset = [23,0]
      }

      let option={
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:width,
              height:20,
              position:'right',
              offset:offset,
              borderWidth:1,
              borderColor:'#18D1BC',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return value + '%';
              },
              textStyle:{
                color: '#18D1BC',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#18D1BC'
              },

            },
            barWidth: 14,
            data: [value>100?100:(value>1?value:0)]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    //专供机占比
    drawChartdedicatedMachine() {
      var myChart = this.$echarts.init(this.$refs.dedicatedMachine);
      this.$forceUpdate()
      let value = '0.0'
      if(this.dedicatedProportion && this.dedicatedProportion.rate && this.dedicatedProportion.rate){
        if(this.dedicatedProportion.rate.split('%')[0] >= 100){
          value = Number(this.dedicatedProportion.rate.split('%')[0]).toFixed(1)
        } else {
          value = Number(this.dedicatedProportion.rate.split('%')[0]).toFixed(1)

        }
      } else {
        value = '0.0'
      }
      let width = this.countStrWidth(value + '%')+6
      let offset = [0,0]
      if(width > 33.6 && Number(value) <= 0){
        offset = [-10,0]
      }
      if(width > 33.6 && Number(value) >= 1){
        offset = [8,0]
      }
      if(width > 33.6 && Number(value) < 1){
        offset = [14,0]
      }
      if(width > 38.8 && Number(value) <= 0){
        offset = [16,0]
      }
      if(width > 38.8 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) >= 0){
        offset = [10,0]
      }
      if(width > 40.6 && Number(value) > 80){
        offset = [-16,0]
      }
      if(width > 40.6 && Number(value) > 90){
        offset = [-26,0]
      }
      if(width > 45.8 && Number(value) <= 0){
        offset = [19,0]
      }
      if((width> 47.7 && value>0) ){
        offset = [-30,0]
      }
      if((width> 47.7 && value<0) ){
        offset = [23,0]
      }

      let option={
        grid: {
          top: 0,
          bottom: 0,
          left: "0",
          right: "0"
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0]
        },
        yAxis: [
          {
            type: "category",
            data: [""],
            axisLine: { show: false },
            axisTick: [
              {
                show: false
              }
            ]
          }
        ],
        series: [
          {
            name: "金额",
            type: "bar",
            zlevel: 1,
            selectedMode: false,
            legendHoverLink:false,
            hoverAnimation: false,
            label:{
              show:true,
              backgroundColor:'#fff',
              width:width,
              height:20,
              position:'right',
              offset:offset,
              borderWidth:1,
              borderColor:'#FAB81B',
              borderRadius:10,
              shadowColor:'none',
              shadowBlur:0,
              formatter:function(param){
                return value + '%';
              },
              textStyle:{
                color: '#FAB81B',
                fontSize:'12',
                // padding: [0, 0, 0, 6],
                align:'center',
                top:'middle',
              }
            },
            showBackground: false,
            silent: false,
            // barWidth: 14,
            itemStyle: {
              normal: {
                barBorderRadius: 14,
                color: '#FAB81B'
              },

            },
            barWidth: 14,
            data: [value>100?100:(value>1?value:0)]
          },
          {
            name: "背景",
            type: "bar",
            barWidth: 14,
            barGap: "-100%",
            data: [100],

            // 高亮颜色
            emphasis: {
              itemStyle: {
                color: "#E1E1E1",//柱条颜色，设置为柱图颜色则取消高亮
              }
            },
            itemStyle: {
              normal: {
                color: "#E1E1E1",
                barBorderRadius: 30
              }
            }
          }
        ]
      }
      myChart.setOption(option);
    },
    // 本月毛利率
    grossMarginchart() {
      var myChart = this.$echarts.init(this.$refs.rightEcharts);
      let greenValue = ''
      if(this.grossProfit && this.grossProfit.basicOperationPoint) {
        if(this.grossProfit.basicOperationPoint.split('%')[0]>=100){
          greenValue = 100
        }else if (this.grossProfit.basicOperationPoint.split('%')[0]<1){
          greenValue = 1
        }  else {
          greenValue = Number(this.grossProfit.basicOperationPoint.split('%')[0]).toFixed(2)
        }
      } else {
        greenValue = 0
      }
      let blueValue = ''
      if(this.grossProfit && this.grossProfit.rate) {
        if(this.grossProfit.rate.split('%')[0]>=100){
          blueValue = 100
        } else if (this.grossProfit.rate.split('%')[0]<1){
          blueValue = 1
        } else{
          blueValue = this.grossProfit.rate.split('%')[0]?Number(this.grossProfit.rate.split('%')[0]).toFixed(2):0
        }
      } else {
        blueValue = 0
      }

      let  option = {
        color:['#1890FF','#18D1BC'],
        angleAxis: {
          max:100,
          axisLine:{
            show:false
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:false
          },
          axisLabel:{
            show:false
          }
        },
        radiusAxis: {

          type: 'category',
          data:[],
          z: 10,
          axisLine:{
            show:false
          },
          splitLine:{
            show:false
          },
          axisTick:{
            show:false
          },
          axisLabel:{
            show:false
          }
        },
        polar: {
          // radius:['30%','50%'],


        },
        series: [{
          type: 'bar',
          barWidth : 9,
          data: [greenValue],
          coordinateSystem: 'polar',
          roundCap: true,
          showBackground:true,
          backgroundStyle:{
            color:'#DAEDFF'
          }
        }, {
          type: 'bar',
          data: [blueValue],
          coordinateSystem: 'polar',
          roundCap: true,
          showBackground:true,
          backgroundStyle:{
            color:'#DAF8F4'
          },
          barGap:0.8,
          barWidth : 9,
        }]
      };
      myChart.setOption(option);
    },
    getTime(){
      // let day1 = new Date();
      // day1.setTime(day1.getTime()-24*60*60*1000);
      // let Y = day1.getFullYear(); // 年
      // let M = (day1.getMonth() + 1 < 10 ? '0' + (day1.getMonth() + 1) : day1.getMonth() + 1);//月
      // let D =  day1.getDate()
      // this.queryTime = Y + '-' + M+'-'+D
      this.queryTime = this.$moment().subtract(1, 'day').format('YYYY-MM-DD')
    },
    gotoDerail(){
      let routeUrl = this.$router.resolve({
        path: "/specialStoreCE",
        query:{
          shopId:this.orderBy,
          shopTypeId:this.shopTypeId,
          shopName:this.cherZhname
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    gotoDerailYY(){
      let routeUrl = this.$router.resolve({
        path: "/customer360",
        query:{
          shopId:this.orderBy
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 运营商相关
    drawCharts() {
      let myChart1 = this.$echarts.init(this.$refs.fourth);
      let dataA =  this.pieData
      this.$forceUpdate()
      let option =
        {
          color: [new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: '#8385E9'
            },
            {
              offset: 0.9,
              color: '#BFC1F7'
            }
          ]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#22C7BB'
            },
              {
                offset: 0.9,
                color: '#9DFDD5'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F36556'
            },
              {
                offset: 0.9,
                color: '#FFAF9B'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F69A52'
            },
              {
                offset: 0.9,
                color: '#F8CA6C'
              }])
          ],
          legend: {
            selectedMode: false,
            icon: "circle",
            left: "53%",
            y: "center", //延Y轴居中
            // x: '30%', //居右显示
            // orient: "vertical",
            color: "#ffffff",
            right: 20, //右边距0
            top: '45%',
            // icon: "pie",
            itemWidth: 8, // 设置宽度
            itemHeight: 8, // 设置高度
            // itemGap: this.$util.setFont(12), // 设置间距
            itemGap: 16, // 设置间距
            formatter: function(name) {
              var value;
              for (let i = 0, l = dataA.length; i < l; i++) {
                if (dataA[i].name == name) {
                  value = dataA[i].value;
                }
              }
              var str = "{a|" + name + "}{b|" + value + "}";
              return str;
            },
            textStyle: {
              rich: {
                a: {
                  color: "#777",
                  fontSize: 14,
                  width: 160,
                  padding: [0, 16, 0, 2] //间距
                },
                b: {
                  color: "#262626",
                  fontSize: 14,
                  fontWeight: 'bolder'
                  // padding: [0, 0, 0, 8] //间距
                }
              }
            }
          },
          series: [
            {
              // hoverAnimation: false,
              name: '',
              type: 'pie',
              center: ['30%', '60%'],
              radius: ['49%', '62%'],
              avoidLabelOverlap: false,
              clockWise: false,
              itemStyle: {
                normal: {
                  borderColor: '#f7f7f7',
                  borderWidth: 4
                }
              },
              label: {
                show: false,
                position: 'center'
              },

              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.pieData
            },
            // 边框的设置
            {
              hoverAnimation: false,
              radius: ['44%', '46%'],
              center: ['30%', '60%'],
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  color: "#C9EEF2",
                },
              }],
            },
            // 内圆设置
            {
              radius: [0, '45%'],
              center: ['30%', '60%'],
              hoverAnimation: false,
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  normal: {
                    color: { // 完成的圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: "#F9FDFE" // 0% 处的颜色
                      }, {
                        offset: 0.4,
                        color: "#E0F6F8" // 100% 处的颜色
                      }]
                    },
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  }
                },
              }],
            }
          ]
        }

      myChart1.setOption(option);
    },
    // 全渠道口径出货图表
    num1Echarts(){
      let myChart = this.$echarts.init(this.$refs.numone);
      let value = ''
      if(this.channelDiameter && this.channelDiameter.rate ){
        if(this.channelDiameter.rate.split('%')[0] >= 100){
          value = 100
        } else {
          value = Number(this.channelDiameter.rate.split('%')[0]).toFixed(1)
        }
      } else {
        value = 0
      }
      let max = 100;
      let center = value > 50? ['48.5%', '81%']:value==0?['51.5%', '80%']:['51%', '81.5%'];
      if(value<10){
        center=['51.4%', '81.5%']
      }
      let option = {
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value>1?value:0],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            name: '',
            roundCap: 1,
            color: '#18D1BC',
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center:center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / 100,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },
    // 前置及其规模
    getEchartsPreEmployment(){
      let myChart = this.$echarts.init(this.$refs.preEmployment);
      let dataArr = ''
      if(this.preposition && this.preposition.rate && this.preposition.rate){
        if(this.preposition.rate >= 100){
          dataArr = 100
        } else {
          dataArr = this.preposition.rate
        }
      } else {
        dataArr = 0
      }
      let option = {
        series: [{
          name: "外部线",
          type: "gauge",
          radius: '65%', // 动态
          startAngle: 225,
          endAngle: -45,
          axisLine: {
            show:false,
            lineStyle: {
              color: [
                [1, '#18D1BC'] // 动态
              ],
              width: 1
            }
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          detail: {
            show: false
          },
          title: { //标题
            show: false,
          }
        },
          {

            name: '外部刻度',
            type: 'gauge',
            radius: '65%',
            min: 0, //最小刻度
            max: 100, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: false,
              lineStyle: {
                color: [
                  [1, 'rgba(0,0,0,0)']
                ],
              }
            }, //仪表盘轴线
            axisLabel: {
              show: false,
              color: '#31F3FF',
              fontSize: 20, // 动态
              distance: -20, // 动态

            }, //刻度标签。
            axisTick: {
              show: false,
            }, //刻度样式
            splitLine: {
              show: false,
            },
          },

          {
            name: "间隔条形",
            type: "gauge",
            //  表盘刻度的位置修改这里
            radius: '-20%',
            z: 4,
            splitNumber: 10,
            startAngle: -225,
            endAngle: 45,
            axisLine: {

              lineStyle: {
                opacity: 0
              }
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              length:10,
              splitNumber: 1,
              lineStyle: {
                color: '#f7f7f7',
                width: 3
              }
            },
            splitLine: {
              show: false
            },
            detail: {
              show: false
            },
            title: {
              show: false,
            }
          },
          {
            show:false,
            name: "数据",
            type: "gauge",
            radius: '52.5%',
            z: 3,
            startAngle: 225,
            max: 100,
            endAngle: -45,
            axisLine: {
              lineStyle: {
                color: [
                  // 动态数据展示 回显
                  [dataArr / 100, '#18D1BC'], // 动态
                  [1, '#fff']
                ],
                width: 8
              }
            },
            tooltip: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false
            },
            detail: {
              show: false,
              fontWeight: 'bold',
              fontSize: 40
            },
            pointer: {
              show: false,
              width: 3,
            },
            data: [{
              name: '',
              value: ''
            }]
          },

        ]
      };
      myChart.setOption(option);
    },
    //线上店销售(万元)
    getEchartsPreEmploymentTwo(){
      let myChart = this.$echarts.init(this.$refs.preEmploymentTwo);
      let dataArr = ''
      if(this.onlineStore && this.onlineStore.rate && this.onlineStore.rate){
        if(this.onlineStore.rate.split('%')[0] >= 100){
          dataArr = 100
        } else {
          dataArr = this.onlineStore.rate.split('%')[0]
        }
      } else {
        dataArr = 0
      }
      let option = {
        series: [{
          name: "外部线",
          type: "gauge",
          radius: '65%', // 动态
          startAngle: 225,
          endAngle: -45,
          axisLine: {
            show:false,
            lineStyle: {
              color: [
                [1, '#18D1BC'] // 动态
              ],
              width: 1
            }
          },
          axisLabel: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            show: false,
          },
          detail: {
            show: false
          },
          title: { //标题
            show: false,
          }
        },
          {

            name: '外部刻度',
            type: 'gauge',
            radius: '65%',
            min: 0, //最小刻度
            max: 100, //最大刻度
            splitNumber: 10, //刻度数量
            startAngle: 225,
            endAngle: -45,
            axisLine: {
              show: false,
              lineStyle: {
                color: [
                  [1, 'rgba(0,0,0,0)']
                ],
              }
            }, //仪表盘轴线
            axisLabel: {
              show: false,
              color: '#31F3FF',
              fontSize: 20, // 动态
              distance: -20, // 动态

            }, //刻度标签。
            axisTick: {
              show: false,
            }, //刻度样式
            splitLine: {
              show: false,
            },
          },

          {
            name: "间隔条形",
            type: "gauge",
            radius: '-20%',
            z: 4,
            splitNumber: 10,
            startAngle: -225,
            endAngle: 45,
            axisLine: {

              lineStyle: {
                opacity: 0
              }
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              length:10,
              splitNumber: 1,
              lineStyle: {
                color: '#f7f7f7',
                width: 3
              }
            },
            splitLine: {
              show: false
            },
            detail: {
              show: false
            },
            title: {
              show: false,
            }
          },
          {
            show:false,
            name: "数据",
            type: "gauge",
            radius: '52.5%',
            z: 3,
            startAngle: 225,
            max: 100,
            endAngle: -45,
            axisLine: {
              lineStyle: {
                color: [
                  // 动态数据展示 回显
                  [dataArr / 100, '#1890FF'], // 动态
                  [1, '#fff']
                ],
                width: 8
              }
            },
            tooltip: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false
            },
            detail: {
              show: false,
              fontWeight: 'bold',
              fontSize: 40
            },
            pointer: {
              show: false,
              width: 3,
            },
            data: [{
              name: '',
              value: 80
            }]
          },

        ]
      };
      myChart.setOption(option);
    },
    // 提货总额
    totalPickupAmountEcharts(){
      let myChart = this.$echarts.init(this.$refs.totalPickupAmount);
      let value = ''
      if(this.takeDelivery && this.takeDelivery.rate ){
        if(this.takeDelivery.rate.split('%')[0] >= 100){
          value = 100
        } else {
          value = Number(this.takeDelivery.rate.split('%')[0]).toFixed(1)

        }
      } else {
        value = 0
      }
      let max = 100;
      let center = value > 50? ['48.5%', '81%']:value==0?['51.5%', '80%']:['51%', '81.5%'];
      let option = {
        // backgroundColor: '#F1FAFF',
        // title: {
        //   text: (value || '-') + '%',
        //   x: 'center',
        //   y: '68%',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: '28',
        //     fontWeight: 'normal'
        //   }
        // },
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value>1?value:0],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            name: '',
            roundCap: 1,
            color: '#1890FF',
            //   new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            //   offset: 0,
            //   color: 'rgba(22,255,241,.1)'
            // },
            //   {
            //     offset: 0.5,
            //     color: 'rgba(22,255,241,.5)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(22,255,241,1)'
            //   }
            // ]),
            // z: 2
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center:center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },

    num2Echarts(){
      let myChart = this.$echarts.init(this.$refs.numtwo);
      let chartData= {
        value:  this.grossMargin.grossMarginRatejd>=100?100:this.grossMargin.grossMarginRatejd,
        total: 100
      }
      let max = chartData.total;
      let value = chartData.value;
      let center = value > 50? ['48.5%', '80%']:['51%', '81%'];
      let option = {
        backgroundColor: '#FFF4F4',
        // title: {
        //   text: (value || '-') + '%',
        //   x: 'center',
        //   y: '68%',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: '28',
        //     fontWeight: 'normal'
        //   }
        // },
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 12,
            name: '',
            roundCap: 1,
            color: '#FF8A8A',
            //   new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            //   offset: 0,
            //   color: 'rgba(22,255,241,.1)'
            // },
            //   {
            //     offset: 0.5,
            //     color: 'rgba(22,255,241,.5)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(22,255,241,1)'
            //   }
            // ]),
            // z: 2
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 12,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center: center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },

    num3Echarts(){
      let myChart = this.$echarts.init(this.$refs.numthree);
      const colorDataHandle = (data, total, width = 280) => {
        let num = 0
        let option = {
          angleAxis: {
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            // min: 'dataMin',
            // max: 'dataMax',
            startAngle: 135,
          },
          radiusAxis: {
            type: 'category',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
          polar: {
            radius: '100%'
          },
          series: []
        }
        // option是对传入的数据的一个处理
        const options = data.map((item, index) => {
          num += item
          const a = {
            type: 'bar',
            data: [0, 0, 0, num],
            coordinateSystem: 'polar',
            z: 9999 - index,
            roundCap: true,
            color: colors[index],
            barGap: '-100%',
            // barWidth: '30%',
            itemStyle: {
              // 控制弧的宽,弧的宽的控制并没有做太多的情况判断,简单的区分了一下
              borderWidth: index == 0 ? 6 : index == 1 ? 3 : 0,
              // shadowBlur: 5,
              // color: 'transparent',
              borderColor: colors[index],
              // shadowColor: colors[index],
            },
          }
          return a
        })
        option.series = options
        return option
      }

      const colors =
        [
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#FAB81B', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#FAB81B', // 100% 处的颜色
              },
            ],
          },
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#FF8A8A', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#FF8A8A', // 100% 处的颜色
              },
            ],
          },
          {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: '#727EFF', // 0% 处的颜色
              },
              {
                offset: 1,
                color: '#727EFF', // 100% 处的颜色
              },
            ],
          },
        ];
      let chartData = []
      if(this.InventoryData&&this.InventoryData.inventoryList.length > 0) {
        chartData =  this.InventoryData.inventoryList.map(it=>it.count)
      } else {
        chartData = [10,10,10]
      }
      myChart.setOption(colorDataHandle(chartData,100));

    },

    getShopType(id){
      let data ={
        id:id
      }
      queryShopMessage(data).then(res=>{
        if(res.data.code == 0){
          this.shopTypeName = res.data.data.name
        } else {
          this.shopTypeName = ''
        }
      }).catch(error=>{
        console.log(error);
      })
    },
    // 专卖店tab相关
    // 获取专卖店列表
    getShopLLIst() {
      let data ={
        exclusiveShop:1
      }
      SalesShopInfoList(data).then(res=>{
        this.PxList = res.data.list
        this.orderBy = res.data.list&&res.data.list.length>0?res.data.list[0].shopCisCode:''
        this.shopTypeId = res.data.list&&res.data.list.length>0?res.data.list[0].code:''
        this.cherZhname = res.data.list&&res.data.list.length>0?res.data.list[0].name:''
        this.$forceUpdate()
        this.getShopType(this.shopTypeId)
        // if( res.data.list&&res.data.list.length > 0) {
        //   this.isHaveVal = true
        // }
        // this.drawChartsfive()
        this.getNumSixDAata()
        this.getNumsevenDAata()
        this.getNumeightDAata()
        this.getHD()
        this.getzgd()
        this.getzgj()
        this.getKucun()
      })
    },
    // 专卖店 ===== 提货额的数据
    getNumSixDAata() {
      // 先注销这个echarts 请求完数据再重新渲染
      let myChart = this.$echarts.init(this.$refs.numsix);
      myChart.dispose()
      let data  = {
        cisShopCode:this.orderBy,
        queryTime: this.queryTime,
      }
      getfindDelivery(data).then(res=>{
        if(res.data.code == 0){
          this.sixData.currentYearTotal = res.data.data.deliveryData&& res.data.data.deliveryData.currentYearTotal ?(Number(res.data.data.deliveryData.currentYearTotal)/10000).toFixed(1):0
          this.sixData.currentMonthTotal = res.data.data.deliveryData&& res.data.data.deliveryData.currentMonthTotal ?res.data.data.deliveryData.currentMonthTotal:0
          this.sixData.arrowMonth = res.data.data.deliveryData&&res.data.data.deliveryData.arrowMonth?res.data.data.deliveryData.arrowMonth:null
          this.sixData.arrowYear = res.data.data.deliveryData&&res.data.data.deliveryData.arrowYear?res.data.data.deliveryData.arrowYear:null
          this.sixData.year = res.data.data.deliveryData&&res.data.data.deliveryData.year?res.data.data.deliveryData.year:0
          this.sixData.chain = res.data.data.deliveryData&&res.data.data.deliveryData.chain? res.data.data.deliveryData.chain:0
          // 处理提货完成率 渲染echarts 如果包含% 就截取
          if(res.data.data.deliveryData&&res.data.data.deliveryData.yearCompletion && res.data.data.deliveryData.yearCompletion.indexOf('%') !== -1) {
            this.sixData.completion = res.data.data.deliveryData.yearCompletion.split('%')[0]
            this.sixData.completionjd = (res.data.data.deliveryData.yearCompletion.split('%')[0]&& Number(res.data.data.deliveryData.yearCompletion.split('%')[0]))>=100?100: res.data.data.deliveryData.yearCompletion.split('%')[0]
            if( String(this.sixData.completion).indexOf(',')!==-1) {
              this.sixData.completionjd = this.sixData.completion.replace(/,/g, '');
            }
            this.sixData.completionjd= Number(this.sixData.completionjd)>=100?100: this.sixData.completionjd
          } else if (res.data.data.deliveryData&&res.data.data.deliveryData.yearCompletion && res.data.data.deliveryData.yearCompletion.indexOf('%') == -1){
            this.sixData.completion =  (res.data.data.deliveryData.yearCompletion*100).toFixed(1)
            this.sixData.completionjd= (res.data.data.deliveryData.yearCompletion*100).toFixed(1)
          } else {
            this.sixData.completion = 0
            this.sixData.completionjd = 0
          }
          this.num6Echarts()
        } else {
          this.sixData.completion = 0
          this.sixData.completionjd = 0
          this.num6Echarts()
          this.$message.warning('获取数据失败'+res.data.msg)
        }

      }).catch(error=>{
        console.log(error);
      })
    },
    // 专卖店 ====== 毛利指标
    getNumsevenDAata(){
      // 先注销这个echarts 请求完数据再重新渲染
      let myChart = this.$echarts.init(this.$refs.numseven)
      myChart.dispose()
      let data  = {
        cisShopCode:this.orderBy,
        queryTime: this.queryTime,
      }
      getfindGrossProfit(data).then(res=>{
        if(res.data.code == 0){
          this.sevenData.currentGrossTotalAmount = res.data.data.grossProfit&& res.data.data.grossProfit.currentGrossTotalAmount? res.data.data.grossProfit.currentGrossTotalAmount:0
          this.sevenData.arrowMonth = res.data.data.grossProfit&& res.data.data.grossProfit.arrowMonth?res.data.data.grossProfit.arrowMonth:null
          this.sevenData.arrowYear = res.data.data.grossProfit&& res.data.data.grossProfit.arrowYear?res.data.data.grossProfit.arrowYear:null
          this.sevenData.year = res.data.data.grossProfit&& res.data.data.grossProfit.year?res.data.data.grossProfit.year:0
          this.sevenData.chain = res.data.data.grossProfit&& res.data.data.grossProfit.chain?res.data.data.grossProfit.chain:0
          // 处理提货完成率 渲染echarts 如果包含% 就截取
          if(res.data.data.grossProfit&&res.data.data.grossProfit.rate && res.data.data.grossProfit.rate.indexOf('%') !== -1) {
            this.sevenData.completion =  res.data.data.grossProfit.rate.split('%')[0]
            this.sevenData.completionjd =  (res.data.data.grossProfit.rate.split('%')[0]&&Number(res.data.data.grossProfit.rate.split('%')[0]))>=100?100:res.data.data.grossProfit.rate.split('%')[0]
            if( String(this.sevenData.completion).indexOf(',')!==-1) {
              this.sevenData.completionjd = this.sevenData.completion.replace(/,/g, '');
            }
            this.sevenData.completionjd= Number(this.sevenData.completionjd)>=100?100: this.sevenData.completion
          } else if (res.data.data.grossProfit&&res.data.data.grossProfit.rate && res.data.data.grossProfit.rate.indexOf('%') == -1){
            this.sevenData.completion =  res.data.data.grossProfit.rate
            this.sevenData.completionjd = res.data.data.grossProfit.rate
          } else {
            this.sevenData.completion = 0
            this.sevenData.completionjd = 0
          }
          this.$nextTick(()=>{
            this.num7Echarts()
          })

        } else {
          this.sevenData.completion = 0
          this.sevenData.completionjd = 0
          this.num7Echarts()
          this.$message.warning('获取数据失败'+res.data.msg)
        }

      }).catch(error=>{
        console.log(error);
      })
    },
    // 专卖店 ====== 零售
    getNumeightDAata() {
      let myChart = this.$echarts.init(this.$refs.numeight)
      myChart.dispose()
      let data  = {
        cisShopCode:this.orderBy,
        queryTime: this.queryTime,
      }
      this.eightData.completionjd = 0
      getfindRetail(data).then(res=>{
        if(res.data.code == 0){
          this.eightData.currentRetailTotalAmount = res.data.data.retailData&&res.data.data.retailData.currentRetailTotalAmount?res.data.data.retailData.currentRetailTotalAmount:0
          this.eightData.arrowMonth =  res.data.data.retailData&&res.data.data.retailData.arrowMonth?res.data.data.retailData.arrowMonth:null
          this.eightData.arrowYear =  res.data.data.retailData&&res.data.data.retailData.arrowYear? res.data.data.retailData.arrowYear:null
          this.eightData.year = res.data.data.retailData && res.data.data.retailData.year?res.data.data.retailData.year : 0
          this.eightData.chain =res.data.data.retailData && res.data.data.retailData.chain?res.data.data.retailData.chain:0
          this.eightData.completion = res.data.data.retailData && res.data.data.retailData.completion? res.data.data.retailData.completion: 0
          // 处理提货完成率 渲染echarts 如果包含% 就截取
          if(this.eightData.completion && this.eightData.completion.indexOf('%') !== -1) {
            this.eightData.completion = this.eightData.completion.split('%')[0]
            this.eightData.completionjd = (this.eightData.completion.split('%')[0]&& Number(this.eightData.completion.split('%')[0])) >=100?100: this.eightData.completion.split('%')[0]
            if( this.eightData.completion&&String(this.eightData.completion).indexOf(',')!==-1) {
              this.eightData.completionjd = this.eightData.completion.replace(/,/g, '');
            }
            this.eightData.completionjd= Number(this.eightData.completionjd)>=100?100: this.eightData.completion
          } else if (this.eightData.completion&&this.eightData.completion.indexOf('%') == -1){
            this.eightData.completionjd =  this.eightData.completion>=100?100:100
          } else {
            this.eightData.completion = 0
            this.eightData.completionjd=0
          }
          this.$nextTick(()=>{
            this.num8Echarts()
          })
        } else {
          this.eightData.completion = 0
          this.eightData.completionjd=0
          this.num8Echarts()
          this.$message.warning('获取数据失败'+res.data.msg)
        }

      }).catch(error=>{
        console.log(error);
      })

    },
    drawChartsfive() {
      var myChart2 = this.$echarts.init(this.$refs.fiveth);
      let dataB = this.DataZM
      let option =
        {
          color: [new echarts.graphic.LinearGradient(1, 1, 0, 0, [
            {
              offset: 0,
              color: '#8385E9'
            },
            {
              offset: 0.9,
              color: '#BFC1F7'
            }
          ]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#22C7BB'
            },
              {
                offset: 0.9,
                color: '#9DFDD5'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F69A52'
            },
              {
                offset: 0.9,
                color: '#F8CA6C'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#F36556'
            },
              {
                offset: 0.9,
                color: '#FFAF9B'
              }]),
            new echarts.graphic.LinearGradient(1, 1, 0, 0, [{
              offset: 0,
              color: '#8FA2C1'
            },
              {
                offset: 0.9,
                color: '#BAC0D1'
              }])

          ],
          legend: {
            selectedMode: false,
            icon: "circle",
            left: "53%",
            y: "center", //延Y轴居中
            // x: '30%', //居右显示
            // orient: "vertical",
            color: "#ffffff",
            right: 20, //右边距0
            top: '45%',
            // icon: "pie",
            itemWidth: 8, // 设置宽度
            itemHeight: 8, // 设置高度
            // itemGap: this.$util.setFont(12), // 设置间距
            itemGap: 16, // 设置间距
            formatter: function(name) {
              var value;
              for (let i = 0, l = dataB.length; i < l; i++) {
                if (dataB[i].name == name) {
                  value = dataB[i].value;
                }
              }
              var str = "{a|" + name + "}{b|" + value + "}";
              return str;
            },
            textStyle: {
              rich: {
                a: {
                  color: "#777",
                  fontSize: 14,
                  width: 160,
                  padding: [0, 0, 0, 2] //间距
                },
                b: {
                  color: "#262626",
                  fontSize: 14,
                  fontWeight: 'bolder'
                  // padding: [0, 0, 16, 2] //间距
                }
              }
            }
          },
          series: [
            {
              // hoverAnimation: false,
              name: '',
              type: 'pie',
              center: ['30%', '60%'],
              radius: ['49%', '62%'],
              avoidLabelOverlap: false,
              clockWise: false,
              itemStyle: {
                normal: {
                  borderColor: '#f7f7f7',
                  borderWidth: 4
                }
              },
              label: {
                show: false,
                position: 'center'
              },

              labelLine: {
                normal: {
                  show: false
                }
              },
              data: this.DataZM
            },
            // 边框的设置
            {
              hoverAnimation: false,
              radius: ['44%', '46%'],
              center: ['30%', '60%'],
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  color: "#C9EEF2",
                },
              }],
            },
            // 内圆设置
            {
              radius: [0, '45%'],
              center: ['30%', '60%'],
              hoverAnimation: false,
              type: 'pie',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              labelLine: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              animation: false,
              tooltip: {
                show: false
              },
              data: [{
                value: 1,
                itemStyle: {
                  normal: {
                    color: { // 完成的圆环的颜色
                      colorStops: [{
                        offset: 0,
                        color: "#F9FDFE" // 0% 处的颜色
                      }, {
                        offset: 0.4,
                        color: "#E0F6F8" // 100% 处的颜色
                      }]
                    },
                    label: {
                      show: false
                    },
                    labelLine: {
                      show: false
                    }
                  }
                },
              }],
            }
          ]
        }

      myChart2.setOption(option);
      this.$forceUpdate()
    },
    getHD(){
      let data  = {
        cisShopCode:this.orderBy,
        queryTime:this.queryTime,
      }
      getfindActivity(data).then(res=>{
        if(res.data.code == 0){
          this.activityData.monthActivityNum = res.data.data.activityData.monthActivityNum? res.data.data.activityData.monthActivityNum:0
          this.activityData.monthActivitySalesNum = res.data.data.activityData.monthActivitySalesNum?res.data.data.activityData.monthActivitySalesNum:0
          this.activityData.monthActivitySalesAmount = res.data.data.activityData.monthActivitySalesAmount?res.data.data.activityData.monthActivitySalesAmount:0
          this.activityData.quarterActivityNum = res.data.data.activityData.quarterActivityNum?res.data.data.activityData.quarterActivityNum:0
        } else {
          this.$message.warning('获取数据失败'+res.data.msg)
        }
      }).catch(error=>{
        console.log(error);
      })
    },
    num6Echarts(){
      let myChart = this.$echarts.init(this.$refs.numsix);
      let chartData= {
        value: this.sixData.completionjd>=100?100:this.sixData.completionjd,
        total: 100
      }
      let value = chartData.value;
      let center = value > 50? ['48.5%', '80%']:['51%', '81%'];
      let max = chartData.total;


      let option = {
        backgroundColor: '#f7f7f7',
        // title: {
        //   text: (value || '-') + '%',
        //   x: 'center',
        //   y: '68%',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: '28',
        //     fontWeight: 'normal'
        //   }
        // },
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            name: '',
            roundCap: 1,
            color: '#1890FF',
            //   new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            //   offset: 0,
            //   color: 'rgba(22,255,241,.1)'
            // },
            //   {
            //     offset: 0.5,
            //     color: 'rgba(22,255,241,.5)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(22,255,241,1)'
            //   }
            // ]),
            // z: 2
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center: center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },
    num7Echarts(){
      let myChart = this.$echarts.init(this.$refs.numseven);
      let chartData= {
        value: this.sevenData.completionjd>=100?100:this.sevenData.completionjd,
        total: 100
      }
      let max = chartData.total;
      let value = chartData.value;
      let center = value > 50? ['48.5%', '80%']:['51%', '81%'];
      let option = {
        backgroundColor: '#f7f7f7',
        // title: {
        //   text: (value || '-') + '%',
        //   x: 'center',
        //   y: '68%',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: '28',
        //     fontWeight: 'normal'
        //   }
        // },
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            name: '',
            roundCap: 1,
            color: '#FF8A8A',
            //   new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            //   offset: 0,
            //   color: 'rgba(22,255,241,.1)'
            // },
            //   {
            //     offset: 0.5,
            //     color: 'rgba(22,255,241,.5)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(22,255,241,1)'
            //   }
            // ]),
            // z: 2
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center: center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },
    num8Echarts(){
      let myChart = this.$echarts.init(this.$refs.numeight);
      let chartData= {
        value: this.eightData.completionjd>=100?100:this.eightData.completionjd,
        total: 100
      }
      let max = chartData.total;
      let value = chartData.value;
      let center =(value>50 && value<70)?['48.5%', '81.5%'] : value > 50? ['48.5%', '80%']:['51%', '81%'];
      let option = {
        backgroundColor: '#f7f7f7',
        // title: {
        //   text: (value || '-') + '%',
        //   x: 'center',
        //   y: '68%',
        //   textStyle: {
        //     color: '#fff',
        //     fontSize: '28',
        //     fontWeight: 'normal'
        //   }
        // },
        angleAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          min: 0,
          max: 200,
          startAngle: 180
        },
        radiusAxis: {
          type: 'category',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true
          },
          data: []
        },
        polar: {
          radius: '255%',
          center: ['50%', '80%']
        },
        series: [
          // 极坐标系下柱状图
          // 实际值
          {
            type: 'bar',
            data: [value],
            // z: 1,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            name: '',
            roundCap: 1,
            color: '#18D1BC',
            //   new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
            //   offset: 0,
            //   color: 'rgba(22,255,241,.1)'
            // },
            //   {
            //     offset: 0.5,
            //     color: 'rgba(22,255,241,.5)'
            //   },
            //   {
            //     offset: 1,
            //     color: 'rgba(22,255,241,1)'
            //   }
            // ]),
            // z: 2
          },
          // 背景图形
          {
            type: 'bar',
            data: [max],
            z: 0,
            silent: true,
            coordinateSystem: 'polar',
            barMaxWidth: 10,
            roundCap: true,
            color: '#E1E1E1',
            barGap: '-100%'
          },
          // 尾端小圆点 饼图
          {
            type: 'pie',
            // 饼图大小跟外层极坐标系相同，需手动调试
            radius: ['113%', '140%'],
            center: center,
            hoverAnimation: false,
            startAngle: 180,
            endAngle: 0,
            silent: 1,
            z: 10,
            data: [
              // 实际值，背景色透明
              {
                name: '',
                value: value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              },
              {
                // 画中间的图标
                value: 0,
                label: {
                  position: 'inside',
                  backgroundColor: 'rgba(255, 255, 255, 1)',
                  borderRadius: 30,
                  padding:3,
                  // borderWidth: 12,
                  // borderColor: 'rgba(255, 255, 255, 0.4)'
                }
              },
              // 透明填充 angleAxis 的max相同 max : 2
              {
                value: 2 - value / max,
                label: {
                  show: false
                },
                labelLine: {
                  show: false
                },
                itemStyle: {
                  color: 'transparent'
                }
              }
            ]
          }
        ]
      };
      myChart.setOption(option);
    },
    //综合排序展开收起
    moreClick() {
      this.isShowMoreZH = !this.isShowMoreZH;
      if (this.isShowMoreZH) {
        this.isShowMoreYH = false;
      }
    },
    // 修改综合选项
    changeZH(item) {
      this.orderBy = item.shopCisCode;
      this.shopTypeId = item.code
      this.cherZhname = item.name;
      this.$forceUpdate()
      this.getShopType(this.shopTypeId)
      if (this.orderBy) {
        this.isHaveVal = true;
      } else {
        this.isHaveVal = false;
      }
      this.isShowMoreZH = false;
      if(this.tableName == '运营商') {
        this.getReportDataNew()
        // this.getTH()
        this.getMLL()
        this.getKC()
        this.getSALE()

      }
      if(this.tableName == '专卖店'){
        this.$nextTick(()=>{
          //  this.drawChartsfive()
            this.getNumSixDAata()
            this.getNumsevenDAata()
            this.getNumeightDAata()
            this.getHD()
            this.getzgd()
            this.getzgj()
            this.getKucun()
        })
       
      }
    },
    // 删除选中项目
    delectZH() {
      this.isShowMoreZH = false;
      this.isHaveVal = false;
      this.orderBy =  this.PxList.length>0?this.PxList[0].shopCisCode:'';
      this.shopTypeId = this.PxList.length>0?this.PxList[0].code:'';
      this.cherZhname = this.PxList.length>0?this.PxList[0].name:''
      this.$forceUpdate();
      this.getShopType(this.shopTypeId)
    },
    changeIndex(index){
      this.tabIndex = index
      this.tableName = this.tablist[index].name
      if(this.tableName == '运营商') {
        this.getReportDataNew()
        // this.getTH()
        this.getMLL()
        this.getKC()
        this.getSALE()

      }
      if(this.tableName == '专卖店'){
        this.getShopLLIst()
        // this.drawChartsfive()
        this.getzgd()
        this.getzgj()
        this.getKucun()
        this.getNumSixDAata()
        this.getNumsevenDAata()
        this.getNumeightDAata()
        this.getHD()
      }
      this.$forceUpdate()
    },

  }
};
</script>
<style lang="less" scoped>

.evalutaion {
  width: 1188px;
  margin: 24px auto 0;
  .title-line {
    .btn-group {
      .tablBox {
        display: flex;
        flex-direction: row;
        li:first-child {
          margin-right: 30px;
        }
        .noActice {
          width: 48px;
          height: 32px;
          font-size: 16px;
          color: #262626;
          line-height: 32px;
          cursor: pointer;
        }
        .Actice {
          width: 48px;
          height: 32px;
          font-size: 16px;
          color: #00AAA6;
          line-height: 32px;
          border-bottom: 2px solid #00AAA6;
        }
      }

    }
  }

  .BigBox {
    width: 1188px;
    min-height: 270px;
    display: flex;
    flex-direction: row;
    margin-bottom: 26px;
    .leftBox {
      width: 551px;
      height: 374px;
      border-radius: 10px;
      margin-right: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 16px;
      background: #FFFFFF;
      .smallBox {
        width: 551px;
        height: 374px;
        border-radius: 10px;
        background: #F7F7F7;
        .topBox {
          text-align: left;
          width: 514px;
          height: 110px;
          background: rgba(255,255,255,0.8000);
          border-radius: 6px;
          margin: 24px 24px 12px 24px;
          padding-left: 24px;
          padding-top: 22px;
          .textBox {
            .one {
              height: 20px;
              font-size: 16px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
            }
            .two {
              margin-top: 8px;
              height: 20px;
              font-size: 24px;
              font-weight: 500;
              color: #00AAA6;
              line-height: 20px;
              padding-left: 18px;
            }
            .three {
              margin-top: 4px;
              color: #777;
              padding-left: 18px;
              .all {
                height: 14px;
                font-size: 14px;
                font-weight: 500;
                color: #aaa;
                line-height: 14px;
              }
            }
          }
          .imgBox {
            width: 30px;
            height: 30px;
            margin-top: 18px;
            margin-right: 24px;
            img {
              height: 30px;
            }
          }
        }
        .bottomBox1 {
          .toptitle {
            margin-left: 4px;
            display: flex;
            flex-direction: row;
            p{
              margin-left: 72px;
              width:72px;
              text-align: left;
            }
          }
          .shuju {
            margin-left: 76px;
            margin-top:12px;
            padding-bottom: 22px;
            .ee {
              display: flex;
              flex-direction: row;
              margin-top: 8px;
              p{
                margin-left: 72px;
                text-align: left;
                width: 72px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
              }
              P:first-child{
                margin-left:0px;
              }
            }
            .ee:first-child{
              margin-top: 0;
            }
            //ee:last-child{
            // padding-bottom: 10px;
            //}

          }
        }


      }
      .echartsBox {
        position: relative;
        background: #FFFFFF;
        // echarts图
        .echarts {
          z-index: 100000;
          width: 464px;
          height: 270px;
          position: relative;
          //left: -68px;
          left: 14px !important;
          top: 5px;

        }
        .paimingBox {
          position: absolute;
          left: 263px;
          top: 87px;
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #262626;
            .mingci {
              color: #FC5260;
              font-size: 24px;
            }
            .all {
              padding-left: 6px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
            }
          }
        }
        .scoreBox {
          width: 157px;
          height: 40px;
          //background: #fff;
          top: 146px;
          left: 72px;
          position: absolute;
          z-index:300000;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: end;
          .text {
            font-size: 42px;
            font-weight: 600;
            color: #262626;
            line-height: 82px;

          }
          .all {
            font-size: 20px;
            font-weight: 600;
            color: #777777;
            line-height: 28px;
            margin-left: 4px;
            margin-top: 8px;
          }
        }
      }
    }
    .topleftBox {
      width: 551px;
      height: 412px;
      border-radius: 10px;
      margin-right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 16px;
      background: #FFFFFF;
      position: relative;
      overflow:hidden;
      .smallBox {
        width: 551px;
        height: 374px;
        border-radius: 10px;
        background: #F7F7F7;
        .topBox {
          text-align: left;
          width: 514px;
          height: 110px;
          background: rgba(255,255,255,0.8000);
          border-radius: 6px;
          margin: 24px 24px 12px 24px;
          padding-left: 24px;
          padding-top: 22px;
          .textBox {
            .one {
              height: 20px;
              font-size: 16px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
            }
            .two {
              margin-top: 8px;
              height: 20px;
              font-size: 24px;
              font-weight: 500;
              color: #00AAA6;
              line-height: 20px;
              padding-left: 18px;
            }
            .three {
              margin-top: 4px;
              color: #777;
              padding-left: 18px;
              .all {
                height: 14px;
                font-size: 14px;
                font-weight: 500;
                color: #aaa;
                line-height: 14px;
              }
            }
          }
          .imgBox {
            width: 30px;
            height: 30px;
            margin-top: 18px;
            margin-right: 24px;
            img {
              height: 30px;
            }
          }
        }
        .bottomBox1 {
          .toptitle {
            margin-left: 4px;
            display: flex;
            flex-direction: row;
            p{
              margin-left: 72px;
              width:72px;
              text-align: left;
            }
          }
          .shuju {
            margin-left: 76px;
            margin-top:12px;
            padding-bottom: 22px;
            .ee {
              display: flex;
              flex-direction: row;
              margin-top: 8px;
              p{
                margin-left: 72px;
                text-align: left;
                width: 72px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
              }
              P:first-child{
                margin-left:0px;
              }
            }
            .ee:first-child{
              margin-top: 0;
            }
            //ee:last-child{
            // padding-bottom: 10px;
            //}

          }
        }


      }
      .echartsBox {
        position: relative;
        background: #FFFFFF;
        // echarts图
        .echarts {
          z-index: 500;
          width: 500px;
          height: 270px;
          position: relative;
          //left: -68px;
          left: -10px!important;
          top: 24px;

        }
        .paimingBox {
          position: absolute;
          left: 258px;
          top: 107px;
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #262626;
            .mingci {
              color: #FC5260;
              font-size: 24px;
            }
            .all {
              padding-left: 6px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
            }
          }
        }
        .scoreBox {
          width: 157px;
          height: 40px;
          //background: #fff;
          top: 166px;
          left: 60px;
          position: absolute;
          z-index:999;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: end;
          .text {
            font-size: 36px;
            font-weight: 600;
            color: #262626;
            line-height: 82px;

          }
          .all {
            font-size: 20px;
            font-weight: 600;
            color: #777777;
            line-height: 28px;
            margin-left: 4px;
            margin-top: 8px;
          }
        }
      }

      .detail-header{
        padding: 20px 24px 0 26px;
        background: #FFFFFF;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        position: relative;
        top:0;
        z-index:100000;
        .leftFilter{
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: row;
          .tab-change{
            width: 100px;
            padding: 2px;
            background: #F2F3F5;
            border-radius: 6px;
            display: flex;
            align-items: center;
            height: 32px;
            .tab-item{
              width: 48px;
              height: 28px;
              border-radius: 6px;
              font-size: 13px;
              font-weight: 400;
              color: #777777;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              &.active{
                background: #FFFFFF;
                font-weight: 500;
                color: #262626;
                cursor: pointer;
              }
            }
          }
          .date-picker{
            width:150px;
            margin-left:12px;
            .zhanbi-date{
              /deep/.el-date-editor .el-input{
                width: 150px;
                height: 32px;
              }
              /deep/.el-input__inner {
                width: 150px;
                height: 32px!important;
                border-radius: 4px;
              }
              /deep/.el-input__inner:hover{
                border-color:#00AAA6;
              }
              /deep/.el-input__inner:focus{
                border-color:#00AAA6;
              }
              /deep/.el-input__prefix, .el-input__suffix{
                top:-5px;
                height: 32px;
              }
              /deep/.el-picker-panel{
                position:absolute;
                z-index:999999!important;
                background-color: #fff;
                // border: none !important;
              }

            }
            // .el-picker-panel .el-date-picker .el-popper{
            //   border: none !important;
            //    z-index:999999!important;
            // }

          }
        }

        .time-choose{

          //display: flex;
          //align-items: flex-start;
          //flex-direction: row;
          //justify-content: flex-start;

          p {
            float:left;
            margin-left:20px;
            line-height: 38px;
          }
        }
      }
    }

    //新的样式
    .topRightBox {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 622px;
      height: 412px;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 16px 0 0 16px;
      .leftSmallBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .topEarch {
          width: 289px;
          height: 128px;
          background: #F7F7F7;
          border-radius: 10px;
          .echartsBoxone {
            height: 136px;
            margin-bottom: 12px;
            margin-right: 12px;
            position: relative;
            .bigBox {
              width: 289px;
              height: 136px;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              position: relative;
              padding: 22px 0 27px 16px;
              .leftPart{
                position: absolute;
                z-index: 20;
                text-align: left;
                p:first-child {
                  height: 20px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 20px;
                }
                p:nth-child(2) {
                  margin-top: 4px;
                  height: 24px;
                  font-size: 22px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 24px;
                }
                p:nth-child(3),p:nth-child(4) {
                  height: 14px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #777777;
                  line-height: 14px;
                  img {
                    width: 12px;
                    height: 12px;
                    margin-left:2px;
                  }
                }
                p:nth-child(3) {
                  margin-top: 16px;
                  display:flex;
                  align-items: center;
                  justify-content: flex-start;
                }
                p:nth-child(4) {
                  margin-top: 6px;
                }
              }
              .rightPart {
                .centerTxt {
                  min-width: 64px;
                  position: absolute;
                  right: 54px;
                  top: 60px;
                  z-index:1000000;
                  display:flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  p:first-child{
                    height: 34px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #262626;
                    line-height: 34px;
                    span {
                      font-size: 12px;
                      color: #262626;
                      font-weight: 500;
                    }
                  }
                  p:nth-child(2) {
                    margin-top: 2px;
                    height: 11px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #AAAAAA;
                    line-height: 11px;
                  }
                }
                .content {
                  width: 160px;
                  height: 100px;
                  position: absolute;
                  top: 22px;
                  right: 8px;
                }
              }
            }
          }
        }
        .bottomEcharts{
          width: 289px;
          height: 240px;
          background: #F7F7F7;
          border-radius: 8px;
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding:10px 29px 13px 15px;

          .tips{
            font-size: 14px;
            font-weight: 500;
            color: #262626;
            margin-bottom: 15px;
          }
          .echartsBox {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .greenEcharts {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              position: relative;
              p:first-child {
                height: 17px;
                font-size: 12px;
                font-weight: 500;
                color: #262626;
                line-height: 17px;

              }
              p:nth-child(2) {
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
                margin-top:5px;

              }
              p:nth-child(3){
                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                margin-top:10px;
              }
              .circle {
                width: 64px;
                height: 64px;
                background: #FFFFFF;
                border-radius: 50%;
              }
              .increaseTip{
                display: flex;
                flex-direction: row;
                img{
                  width:12px;
                  height: 12px;
                  margin-left:2px;
                }

              }
              .preEmployment {
                width: 370px;
                height: 190px;
                position: absolute;
                top: 42px;
                left: -134px;
              }
              .circlePrompt {
                top:60px;
                .percentage {
                  // height: 25px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 25px;
                  position: absolute;
                  top:103px;
                  left:19px;
                  width: 64px;
                  height: 64px;
                  background: #FFFFFF;
                  border-radius: 50%;
                  display:flex;
                  justify-content: center;
                  align-items: center;
                }
                .ranking {
                  //width: 66px;
                  width:100%;
                  height: 14px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #777777;
                  line-height: 14px;
                  position: absolute;
                  top:169px;
                  left:-7px;
                  .nowRanking {
                    color: #00C4A9;

                  }
                }
              }


            }
            .blueEcharts {
              margin-left:30px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              position: relative;
              p:first-child {
                height: 17px;
                font-size: 12px;
                font-weight: 500;
                color: #262626;
                line-height: 17px;
              }
              p:nth-child(2) {
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
                margin-top:5px;
              }
              p:nth-child(3){
                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                margin-top:10px;
              }
              .increaseTip{
                display: flex;
                flex-direction: row;
                img{
                  width:12px;
                  height: 12px;
                  margin-left:2px;
                }

              }

              // .preEmployment {
              //   width: 360px;
              //   height: 180px;
              //   position: absolute;
              //   top: 42px;
              //   left: -132px;
              // }
              // .circlePrompt {
              //   top:60px;
              //   .percentage {
              //     height: 25px;
              //     font-size: 18px;
              //     font-weight: 500;
              //     color: #262626;
              //     line-height: 25px;
              //     position: absolute;
              //     top:118px;
              //     left:28px;
              //   }
              //   .ranking {
              //     //width: 66px;
              //     height: 14px;
              //     font-size: 12px;
              //     font-weight: 400;
              //     color: #777777;
              //     line-height: 14px;
              //     position: absolute;
              //     top:154px;
              //     left:14px;
              //     .nowRanking {
              //       color: #00C4A9;

              //     }
              //   }
              // }
              .preEmployment {
                width: 370px;
                height: 190px;
                position: absolute;
                top: 42px;
                left: -134px;
              }
              .circlePrompt {
                top:60px;
                .percentage {
                  // height: 25px;
                  font-size: 18px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 25px;
                  position: absolute;
                  top:103px;
                  left:19px;
                  width: 64px;
                  height: 64px;
                  background: #FFFFFF;
                  border-radius: 50%;
                  display:flex;
                  justify-content: center;
                  align-items: center;
                }
                .ranking {
                  //width: 66px;
                  width:100%;
                  height: 14px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #777777;
                  line-height: 14px;
                  position: absolute;
                  top:169px;
                  left:6px;
                  .nowRanking {
                    color: #00C4A9;

                  }
                }
              }


            }

          }

        }
      }
      .rightSmallBox {
        width: 289px;
        height: 380px;
        background: #F7F7F7;
        border-radius: 10px;
        text-align: left;
        padding:10px 16px 0px 12px;
        margin-left:12px;
        .firstBox {
          margin-top:13px;
          position: relative;
          .distributiveNetwork {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #262626;
            line-height: 20px;
            margin-bottom: 15px;
          }
          .distributionNetworkRanking {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
              height: 17px;
              font-size: 12px;
              font-weight: 500;
              color: #262626;
              line-height: 17px;
            }
            .ranking{
              height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
              line-height: 14px;
              .currentRanking{
                color:#00C4A9
              }
            }
          }
          .greenProgressbar {
            width: 261px;
            min-height: 30px;
            position: relative;
            margin-top: 8px;
            //top: 15px;
            //left: 13px;
          }
          .reach {
            height: 24px;
            font-size: 12px;
            font-weight: 400;
            color: #262626;
            line-height: 24px;
            margin-top: 2px;
            .redTips {
              color: #FC5260;
              font-size: 18px;
              margin-left:4px;
            }
          }
        }
        .twoBox {
          margin-top:30px;
          position: relative;
          .distributiveNetwork {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #262626;
            line-height: 20px;
            margin-bottom: 15px;
          }
          .distributionNetworkRanking {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .title{
              height: 17px;
              font-size: 12px;
              font-weight: 500;
              color: #262626;
              line-height: 17px;
            }
            .ranking{
              height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
              line-height: 14px;
              .currentRanking{
                color:#00C4A9
              }
            }
          }
          .orgProgressbar {
            width: 261px;
            min-height: 30px;
            position: relative;
            margin-top: 8px;
            //top: 15px;
            //left: 13px;
          }
          .reachBox{
            display:flex;
            align-items: center;
            justify-content: space-between;
            .reach {
              height: 24px;
              font-size: 12px;
              font-weight: 400;
              color: #262626;
              line-height: 24px;
              margin-top: 2px;
              .redTips {
                color: #FC5260;
                font-size: 18px;
                margin-left:4px;
              }
            }
            .compare {
              // height: 18px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
              display:flex;
              align-items: center;
              justify-content: center;
              line-height: 14px;
              img {
                width: 12px;
                height: 12px;
                margin-left:2px;
              }
            }
          }

        }

      }
    }
    .rightBox {
      width: 622px;
      height: 374px;
      background: #FFFFFF;
      border-radius: 10px;

      .bigBBBox {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 16px 14px 16px 16px;
        .every {
          width: 289px;
          border-radius: 10px;
          position: relative;
        }
        .echartsBoxone {
          background: #F1FAFF;
          height: 152px;
          margin-bottom: 12px;
          margin-right: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 152px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 27px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 12px;
                  margin-left:2px;
                }
              }
              p:nth-child(3) {
                margin-top: 16px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
            }
            .rightPart {
              .centerTxt {
                min-width: 64px;
                position: absolute;
                right: 54px;
                top: 78px;
                z-index:1000000;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p:first-child{
                  height: 34px;
                  font-size: 20px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 34px;
                  span {
                    font-size: 12px;
                    color: #262626;
                    font-weight: 500;
                  }
                }
                p:nth-child(2) {
                  margin-top: 2px;
                  height: 11px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #AAAAAA;
                  line-height: 11px;
                }
              }
              .content {
                width: 160px;
                height: 100px;
                position: absolute;
                background:#F1FAFF ;
                top: 40px;
                right: 8px;
              }

            }
          }


        }
        .echartsBoxtwo {
          background: #FFF4F4;
          height: 152px;
          margin-bottom: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 152px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 27px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 12px;
                  margin-left:2px;
                }
              }
              p:nth-child(3) {
                margin-top: 16px;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
            }
            .rightPart {
              .centerTxt {
                min-width: 64px;
                position: absolute;
                right: 54px;
                top: 78px;
                z-index:1000000;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p:first-child{
                  height: 34px;
                  font-size: 20px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 34px;
                  span {
                    font-size: 12px;
                    color: #262626;
                    font-weight: 500;
                  }
                }
                p:nth-child(2) {
                  margin-top: 2px;
                  height: 11px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #AAAAAA;
                  line-height: 11px;
                }
              }
              .content {
                width: 160px;
                height: 100px;
                position: absolute;
                background:#FFF4F4 ;
                top: 40px;
                right: 8px;
              }

            }
          }
        }
        .echartsBoxthree {
          background: #FFF4E3;
          height: 178px;
          margin-right: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 178px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 17px 16px;
            .leftPart{
              position: relative;
              z-index: 300;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }
              }
              p:nth-child(3) {
                margin-top: 10px;
              }
              .iconBox {
                margin-top: 4px;
                display: flex;
                flex-direction: column;

                li {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 4px;
                  .circle {
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    margin-right: 4px;
                  }
                  .numa {
                    background: #FAB81B;
                  }
                  .numb {
                    background: #FF8A8A;
                  }
                  .numc {
                    background: #727EFF;
                  }
                  .title {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #777777;
                    line-height: 17px;
                    margin-right: 4px;
                  }
                  .num {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #262626;
                    line-height: 17px;
                  }


                }

              }

            }
            .rightPart {
              .centerTEXT33 {
                width: 86px;
                height: 32px;
                font-size: 24px;
                font-weight: 500;
                color: #262626;
                line-height: 32px;
                position: absolute;
                z-index:200;
                right: 35px;
                top: 90px;
                span{
                  height: 12px;
                  font-size: 11px;
                  font-weight: 500;
                  color: #777777;
                  line-height: 12px;
                }
              }
              .content {
                width: 120px;
                height: 135px;
                position: absolute;
                background:#FFF4E3 ;
                top: 40px;
                right: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index:100;
              }
            }
          }
        }
        .echartsBoxthree3 {
          background: #ECF8F8;
          height: 152px;
          margin-bottom: 12px;
          margin-right: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 152px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 27px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }
              }
              p:nth-child(3) {
                margin-top: 16px;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
            }
            .rightPart {

              .content {
                width: 160px;
                height: 100px;
                position: absolute;
                background:#ECF8F8 ;
                top: 40px;
                right: 8px;
              }

            }
          }
        }
        .echartsBoxfour {
          background: #F7F7F7;
          width: 289px;
          height: 178px;
          display: flex;
          flex-direction: row;
          padding: 22px 16px 0px 16px;
          .box1,.box2 {
            display: flex;
            flex-direction: column;
            text-align: left;
            p:first-child{
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
            p:nth-child(2){
              height: 24px;
              font-size: 22px;
              font-weight: 500;
              color: #262626;
              line-height: 24px;
              margin-top: 4px;
            }
            p:nth-child(3){
              margin-top: 18px;
            }
            p:nth-child(4) {
              margin-top: 6px;
            }
            p:nth-child(3),p:nth-child(4) {
              height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
              line-height: 14px;
              img{
                width: 12px;
                height: 13px;
              }
            }
          }
          .box1 {
            margin-right: 56px;
          }
        }
        .echartsBoxfour4 {
          background: #F7F7F7;
          width: 289px;
          height: 152px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 19px 12px 0px 12px;
          .box1,.box2 {
            display: flex;
            flex-direction: column;
            text-align: left;
            p:first-child{
              width: 126px;
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
            p:nth-child(2){
              height: 24px;
              font-size: 22px;
              font-weight: 500;
              color: #262626;
              line-height: 24px;
              margin-top: 4px;
            }
            p:nth-child(3){
              margin-top: 18px;
            }
            p:nth-child(4) {
              margin-top: 6px;
            }
            p:nth-child(3),p:nth-child(4) {
              height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
              line-height: 14px;
              img{
                width: 12px;
                height: 13px;
              }
            }
          }
          .box1 {
            margin-right: 12px;
          }
        }
      }



    }
    .leftBoxzm {
      width: 1188px;
      height: 384px;
      border-radius: 10px;
      margin-right: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 16px;
      background: #FFFFFF;
      .smallBox {
        // width: 551px;
        height: 348px;
        border-radius: 10px;
        background: #F7F7F7;
        .topBox {
          text-align: left;
          width: 514px;
          height: 110px;
          background: rgba(255,255,255,0.8000);
          border-radius: 6px;
          margin: 24px 24px 12px 24px;
          padding-left: 24px;
          padding-top: 22px;
          .textBox {
            .one {
              height: 20px;
              font-size: 16px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
            }
            .two {
              margin-top: 8px;
              height: 20px;
              font-size: 24px;
              font-weight: 500;
              color: #00AAA6;
              line-height: 20px;
              padding-left: 18px;
            }
            .three {
              margin-top: 4px;
              color: #777;
              padding-left: 18px;
              .all {
                height: 14px;
                font-size: 14px;
                font-weight: 500;
                color: #aaa;
                line-height: 14px;
              }
            }
          }
          .imgBox {
            width: 30px;
            height: 30px;
            margin-top: 18px;
            margin-right: 24px;
            img {
              height: 30px;
            }
          }
        }
        .bottomBox1 {
          .toptitle {
            margin-left: 4px;
            display: flex;
            flex-direction: row;
            p{
              margin-left: 72px;
              width:72px;
              text-align: left;
            }
          }
          .shuju {
            margin-left: 76px;
            margin-top:12px;
            padding-bottom: 22px;
            .ee {
              display: flex;
              flex-direction: row;
              margin-top: 8px;
              p{
                margin-left: 72px;
                text-align: left;
                width: 72px;
                height: 14px;
                font-size: 14px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
              }
              P:first-child{
                margin-left:0px;
              }
            }
            .ee:first-child{
              margin-top: 0;
            }
            //ee:last-child{
            // padding-bottom: 10px;
            //}

          }
        }
      }
      .echartsBox {
        position: relative;
        background: #FFFFFF;
        // echarts图
        .echarts {
          z-index: 100000;
          width: 464px;
          height: 270px;
          position: relative;
          //left: -68px;
          left: 0!important;
          top: 24px;

        }
        //.paimingBox {
        //  position: absolute;
        //  left: 251px;
        //  top: 120px;
        //  .text {
        //    font-size: 16px;
        //    font-weight: 500;
        //    color: #262626;
        //    .mingci {
        //      color: #FC5260;
        //      font-size: 24px;
        //    }
        //    .all {
        //      padding-left: 6px;
        //      font-size: 12px;
        //      font-weight: 400;
        //      color: #777777;
        //    }
        //  }
        //}

        .paimingBox {
          position: absolute;
          left: 251px;
          top: 107px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .pz{
            width: 58px;
            height: 18px;
            border-radius: 4px;
            border: 1px solid #FF8F3C;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            font-size: 12px;
            color:#FF8F3C ;
          }
          .text {
            font-size: 16px;
            font-weight: 500;
            color: #262626;
            .mingci {
              color: #FC5260;
              font-size: 24px;
            }
            .all {
              padding-left: 6px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
            }
          }
        }
        .scoreBox {
          width: 157px;
          height: 40px;
          //background: #fff;
          top: 166px;
          left: 58px;
          position: absolute;
          z-index:300000;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          text-align: end;
          .text {
            font-size: 42px;
            font-weight: 600;
            color: #262626;
            line-height: 82px;

          }
          .all {
            font-size: 20px;
            font-weight: 600;
            color: #777777;
            line-height: 28px;
            margin-left: 4px;
            margin-top: 8px;
          }
        }
        .selectBox {
          width: 549px;
          height: 38px;
          background: linear-gradient(270deg, #FFFFFF 0%, #ECF8F8 100%);
          border-radius: 8px 8px 0px 0px;
          position: absolute;
          left: 0;
          top: 0;
          margin:2px 0 0 2px;
          .ZH {
            margin-right: 24px;
            .ZHbox{
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 38px;
              margin-left: 16px;
              &.more{
                .icon-sanjiao{
                  font-size: 12px;
                  transform: scale(0.5) rotate(180deg);
                }
              }
              &.isHaveVal{
                .more-fil-btn{
                  color: #00AAA6;
                }
              }
              .clear-btn{
                width: 14px;
                height: 14px;
                background: #ECF8F8;
                border-radius: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 4px;
                &:hover {
                  .icon-guanbi-gouwuche {
                    // font-size: 8px;
                    // margin-left: 3px;
                    transform: rotate(180deg);
                  }
                }
                .icon-guanbi-gouwuche{
                  color: #00AAA6;
                  font-size: 12px;
                  transition: all 0.6s;
                }
              }
              .more-fil-btn{
                color: #262626;
                font-size: 14px;
                padding: 0;
                width:100%;
              }
              .icon-sanjiao{
                font-size: 12px;
                transform: scale(0.5);
                transition: .2s;
                color: #262626;
              }
            }
            .formFiltrateMorBox {
              min-width: 180px;
              background: #fff;
              position: absolute;
              z-index:1000004;
              text-align: left;
              .form {
                box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.17);
                border-radius: 4px;
                max-height: 280px;
                overflow-y: auto;
                li{
                  min-width: 180px;
                  height: 32px;
                  cursor: pointer;
                  line-height: 32px;
                  color: #262626;
                  padding-left: 16px;
                  padding-right:16px;
                }
                li:hover {
                  background: #E0F2F4;
                }
                .liCherk{
                  background: #f2f3f5;
                  color: #262626;
                }
              }
            }
          }
        }
      }
      .bottomEchartsBox{
        display:flex;
        flex-direction:column;
        margin-top:52px;
        padding:0 16px;
        .topEcharts{
          display:flex;
          flex-direction:row;
          margin-bottom:12px;
          .echartsOne{
            width: 289px;
            height: 152px;
            background: #F7F7F7;
            border-radius: 10px;
            margin-right:12px;
            .every {
              width: 289px;
              border-radius: 10px;
              position: relative;
            }
            .echartsBoxone {
              background: #F7F7F7;
              height: 152px;
              margin-bottom: 12px;
              margin-right: 12px;
              position: relative;
              .bigBox {
                width: 289px;
                height: 152px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                padding: 22px 0 27px 16px;
                .leftPart{
                  position: absolute;
                  z-index: 20;
                  text-align: left;
                  p:first-child {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    color: #262626;
                    line-height: 20px;
                  }
                  p:nth-child(2) {
                    margin-top: 4px;
                    height: 24px;
                    font-size: 22px;
                    font-weight: 500;
                    color: #262626;
                    line-height: 24px;
                  }
                  p:nth-child(3),p:nth-child(4) {

                    height: 14px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #777777;
                    line-height: 14px;
                    img {
                      width: 12px;
                      height: 13px;
                    }
                  }
                  p:nth-child(3) {
                    margin-top: 16px;
                  }
                  p:nth-child(4) {
                    margin-top: 6px;
                  }
                }
                .rightPart {
                  .centerTxt {
                    min-width: 64px;
                    position: absolute;
                    right: 54px;
                    top: 78px;
                    z-index:1000000;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p:first-child{
                      height: 34px;
                      font-size: 20px;
                      font-weight: 500;
                      color: #262626;
                      line-height: 34px;
                      span {
                        font-size: 12px;
                        color: #262626;
                        font-weight: 500;
                      }
                    }
                    p:nth-child(2) {
                      margin-top: 2px;
                      height: 11px;
                      font-size: 12px;
                      font-weight: 400;
                      color: #AAAAAA;
                      line-height: 11px;
                    }
                  }
                  .content {
                    width: 160px;
                    height: 100px;
                    position: absolute;
                    background:#f7f7f7 ;
                    top: 40px;
                    right: 8px;
                  }
                }
              }
            }
          }
          .echartsTwo{
            width: 855px;
            height: 152px;
            background: #F7F7F7;
            border-radius: 10px;
            display: flex;
            flex-direction: row;
            .every {
              width: 289px;
              border-radius: 10px;
              position: relative;
            }
            .echartsBoxthree3 {
              background: #f7f7f7;
              height: 138px;
              margin-bottom: 12px;
              // margin-right: 12px;
              position: relative;
              .bigBox {
                width: 289px;
                height: 118px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                padding: 6px 0 18px 16px;
                .leftPart{
                  position: absolute;
                  z-index: 20;
                  text-align: left;
                  p:first-child {
                    height: 20px;
                    font-size: 12px;
                    font-weight: 500;
                    color: #262626;
                    line-height: 20px;
                  }
                  p:nth-child(2) {
                    margin-top: 4px;
                    height: 24px;
                    font-size: 22px;
                    font-weight: 500;
                    color: #262626;
                    line-height: 24px;
                  }
                  p:nth-child(3),p:nth-child(4) {

                    height: 14px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #777777;
                    line-height: 14px;
                    img {
                      width: 12px;
                      height: 13px;
                    }
                  }
                  p:nth-child(3) {
                    margin-top: 10px;
                  }
                  p:nth-child(4) {
                    margin-top: 6px;
                  }
                }
                .rightPart {
                  .centerTxt {
                    min-width: 64px;
                    position: absolute;
                    right: 77px;
                    top: 50px;
                    z-index:1000000;
                    display:flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p:first-child{
                      height: 34px;
                      font-size: 20px;
                      font-weight: 500;
                      color: #262626;
                      line-height: 34px;
                      span {
                        font-size: 12px;
                        color: #262626;
                        font-weight: 500;
                      }
                    }
                    p:nth-child(2) {
                      margin-top: 2px;
                      height: 11px;
                      font-size: 12px;
                      font-weight: 400;
                      color: #AAAAAA;
                      line-height: 11px;
                    }
                  }
                  .content {
                    width: 160px;
                    height: 100px;
                    position: absolute;
                    background:#f7f7f7 ;
                    top: 18px;
                    right: 31px;
                  }

                }
              }
            }
            //  专卖店==专供占比
            .proportionExclusiveSupply{
              text-align:left;
              margin-top:38px;
              .title{
                font-size: 12px;
                font-weight: 500;
                color: #262626;
                line-height: 17px;
              }
              .radio{
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
                margin-top:5px;
              }
              .compare {
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                margin-top:10px;
                img{
                  width:12px;
                  height:12px;
                }
              }
              .year{
                margin-top: 6px;
              }

            }
            .saleBox{
              margin-left:10px;
            }
            .proportionMidHighEnd{
              margin-left:10px;
              z-index:10000;
            }
            .orgBox {
              width:235px;
              position: relative;
              right:20px;
              // background: #FFF0EE;
              width:172px;
              .lastEcharts {
                width: 90px;
                height:100px;
                position: absolute;
                margin-left: 47px;
                top:36px;
              }
              .rightText {
                position: absolute;
                display: flex;
                right: -57px;
                top: 63px;
                z-index:1000;
                flex-direction: column;
                text-align: left;
                li {
                  align-items: center;
                  justify-content: center;
                  .pink {
                    width: 8px;
                    height: 8px;
                    background: #FF8A8A;
                    border-radius: 50px;
                    margin-right: 4px;
                  }
                  .blue {
                    width: 8px;
                    height: 8px;
                    background: #727EFF;
                    border-radius: 50px;
                    margin-right: 4px;
                  }
                  .zgd {
                    height: 16px;
                    font-size: 11px;
                    font-weight: 500;
                    color: #777;
                    line-height: 16px;
                    margin-right: 4px;
                  }
                  .zdd {
                    height: 16px;
                    font-size: 11px;
                    font-weight: 500;
                    color: #777;
                    line-height: 16px;
                    margin-right: 4px;
                    margin-top: 8px;
                  }
                  .zgdPrice{
                    .zdd {
                    height: 16px;
                    font-size: 11px;
                    font-weight: 500;
                    color: #262626;
                    line-height: 16px;
                    margin-right: 4px;
                    margin-top: 8px;
                  }
                  }
                }

              }
            }

          }
        }
        .bottomEcharts{
          display:flex;
          flex-direction:row;
          .echartsOne{
            width: 289px;
            height: 152px;
            background: #F7F7F7;
            border-radius: 10px;
            margin-right:12px;

          }
          .echartsOne:last-child{
            margin-right:0px;
          }
          .echartsBoxtwo {
            background: #f7f7f7;
            height: 152px;
            margin-bottom: 12px;
            position: relative;
            .bigBox {
              width: 289px;
              height: 152px;
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              position: relative;
              padding: 22px 0 27px 16px;
              .leftPart{
                position: absolute;
                z-index: 20;
                text-align: left;
                p:first-child {
                  height: 20px;
                  font-size: 14px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 20px;
                }
                p:nth-child(2) {
                  margin-top: 4px;
                  height: 24px;
                  font-size: 22px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 24px;
                }
                p:nth-child(3),p:nth-child(4) {

                  height: 14px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #777777;
                  line-height: 14px;
                  img {
                    width: 12px;
                    height: 13px;
                  }
                }
                p:nth-child(3) {
                  margin-top: 16px;
                }
                p:nth-child(4) {
                  margin-top: 6px;
                }
              }
              .rightPart {
                .centerTxt {
                  min-width: 64px;
                  position: absolute;
                  right: 54px;
                  top: 78px;
                  z-index:1000000;
                  display:flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  p:first-child{
                    height: 34px;
                    font-size: 20px;
                    font-weight: 500;
                    color: #262626;
                    line-height: 34px;
                    span {
                      font-size: 12px;
                      color: #262626;
                      font-weight: 500;
                    }
                  }
                  p:nth-child(2) {
                    margin-top: 2px;
                    height: 11px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #AAAAAA;
                    line-height: 11px;
                  }
                }
                .content {
                  width: 160px;
                  height: 100px;
                  position: absolute;
                  background:#f7f7f7 ;
                  top: 40px;
                  right: 8px;
                }

              }
            }
          }
          .echartsBoxfour4 {
            background: #F7F7F7;
            width: 310px;
            height: 152px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 27px 16px 0px 16px;
            .box1,.box2 {
              display: flex;
              flex-direction: column;
              text-align: left;
              p:first-child{
                width: 126px;
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
                display: flex;
                align-items: center;
                img {
                  width: 16px;
                  height: 16px;
                  margin-right: 2px;
                }
              }
              p:nth-child(2){
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
                margin-top: 4px;
              }
              p:nth-child(3){
                margin-top: 18px;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
              p:nth-child(3),p:nth-child(4) {
                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img{
                  width: 12px;
                  height: 13px;
                }
              }
            }
            .box1 {
              margin-right: 12px;
            }
          }
          .twoSmall {
            background: #f7f7f7;
            padding:0px 16px 0px 16px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            .dataBox {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              .title{
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                text-align: left;
                width:130px;
                height: 22px;
                line-height: 22px;
              }
              .num{
                margin-top: 5px;
                font-size: 24px;
                font-weight: 500;
                color: #262626;
                text-align: left;
                height: 24px;
                line-height: 24px;
              }
              span {
                display: flex;
                flex-direction: row;
                text-align: left;
                height: 14px;
                line-height: 14px;
              }
              .lastM,.lastY {
                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }

              }
              .lastM {
                margin-top: 15px;

              }
              .lastY {
                margin-top: 6px;
              }
            }
            .dataBox:first-child{
              // margin-right: 60px;
            }

          }
        }

      }
    }
    .rightBoxzm {
      width: 622px;
      height: 348px;
      background: #FFFFFF;
      border-radius: 10px;

      .bigBBBox {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: 16px 14px 16px 16px;
        .every {
          width: 289px;
          border-radius: 10px;
          position: relative;
        }
        .echartsBoxone {
          background: #F1FAFF;
          height: 152px;
          margin-bottom: 12px;
          margin-right: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 152px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 27px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }
              }
              p:nth-child(3) {
                margin-top: 16px;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
            }
            .rightPart {
              .centerTxt {
                min-width: 64px;
                position: absolute;
                right: 54px;
                top: 78px;
                z-index:1000000;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p:first-child{
                  height: 34px;
                  font-size: 20px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 34px;
                  span {
                    font-size: 12px;
                    color: #262626;
                    font-weight: 500;
                  }
                }
                p:nth-child(2) {
                  margin-top: 2px;
                  height: 11px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #AAAAAA;
                  line-height: 11px;
                }
              }
              .content {
                width: 160px;
                height: 100px;
                position: absolute;
                background:#F1FAFF ;
                top: 40px;
                right: 8px;
              }
            }
          }
        }
        .echartsBoxtwo {
          background: #FFF4F4;
          height: 152px;
          margin-bottom: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 152px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 27px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }
              }
              p:nth-child(3) {
                margin-top: 16px;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
            }
            .rightPart {
              .centerTxt {
                min-width: 64px;
                position: absolute;
                right: 54px;
                top: 78px;
                z-index:1000000;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p:first-child{
                  height: 34px;
                  font-size: 20px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 34px;
                  span {
                    font-size: 12px;
                    color: #262626;
                    font-weight: 500;
                  }
                }
                p:nth-child(2) {
                  margin-top: 2px;
                  height: 11px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #AAAAAA;
                  line-height: 11px;
                }
              }
              .content {
                width: 160px;
                height: 100px;
                position: absolute;
                background:#FFF4F4 ;
                top: 40px;
                right: 8px;
              }

            }
          }
        }
        .echartsBoxthree {
          background: #FFF4E3;
          height: 178px;
          margin-right: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 178px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 17px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }
              }
              p:nth-child(3) {
                margin-top: 10px;
              }
              .iconBox {
                margin-top: 4px;
                display: flex;
                flex-direction: column;

                li {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-top: 4px;
                  .circle {
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    margin-right: 4px;
                  }
                  .numa {
                    background: #FAB81B;
                  }
                  .numb {
                    background: #FF8A8A;
                  }
                  .numc {
                    background: #727EFF;
                  }
                  .title {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #777777;
                    line-height: 17px;
                    margin-right: 4px;
                  }
                  .num {
                    height: 17px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #262626;
                    line-height: 17px;
                  }


                }

              }

            }
            .rightPart {
              .centerTEXT {
                height: 32px;
                font-size: 24px;
                font-weight: 500;
                color: #262626;
                line-height: 32px;
                position: absolute;
                z-index:200;
                right: 35px;
                top: 83px;
                span{
                  height: 12px;
                  font-size: 11px;
                  font-weight: 500;
                  color: #777777;
                  line-height: 12px;
                }
              }
              .content {
                width: 120px;
                height: 120px;
                position: absolute;
                background:#FFF4E3 ;
                top: 40px;
                right: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index:100;
              }
            }
          }
        }
        .echartsBoxthree3 {
          background: #ECF8F8;
          height: 152px;
          margin-bottom: 12px;
          margin-right: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 152px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 27px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {

                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }
              }
              p:nth-child(3) {
                margin-top: 16px;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
            }
            .rightPart {
              .centerTxt {
                min-width: 64px;
                position: absolute;
                right: 54px;
                top: 78px;
                z-index:1000000;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p:first-child{
                  height: 34px;
                  font-size: 20px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 34px;
                  span {
                    font-size: 12px;
                    color: #262626;
                    font-weight: 500;
                  }
                }
                p:nth-child(2) {
                  margin-top: 2px;
                  height: 11px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #AAAAAA;
                  line-height: 11px;
                }
              }
              .content {
                width: 160px;
                height: 100px;
                position: absolute;
                background:#ECF8F8 ;
                top: 40px;
                right: 8px;
              }

            }
          }
        }
        .echartsBoxfour {
          background: #F7F7F7;
          width: 289px;
          height: 178px;
          display: flex;
          flex-direction: row;
          padding: 22px 22px 0px 16px;
          .box1,.box2 {
            display: flex;
            flex-direction: column;
            text-align: left;
            p:first-child{
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
            p:nth-child(2){
              height: 24px;
              font-size: 22px;
              font-weight: 500;
              color: #262626;
              line-height: 24px;
              margin-top: 4px;
            }
            p:nth-child(3){
              margin-top: 18px;
            }
            p:nth-child(4) {
              margin-top: 6px;
            }
            p:nth-child(3),p:nth-child(4) {
              height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
              line-height: 14px;
              img{
                width: 12px;
                height: 13px;
              }
            }
          }
          .box1 {
            margin-right: 56px;
          }
        }
        .echartsBoxfour4 {
          background: #F7F7F7;
          width: 289px;
          height: 152px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 27px 12px 0px 12px;
          .box1,.box2 {
            display: flex;
            flex-direction: column;
            text-align: left;
            p:first-child{
              width: 126px;
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
            p:nth-child(2){
              height: 24px;
              font-size: 22px;
              font-weight: 500;
              color: #262626;
              line-height: 24px;
              margin-top: 4px;
            }
            p:nth-child(3){
              margin-top: 18px;
            }
            p:nth-child(4) {
              margin-top: 6px;
            }
            p:nth-child(3),p:nth-child(4) {
              height: 14px;
              font-size: 12px;
              font-weight: 400;
              color: #777777;
              line-height: 14px;
              img{
                width: 12px;
                height: 13px;
              }
            }
          }
          .box1 {
            margin-right: 12px;
          }
        }
      }


    }

  }
  .pickupAmountBox {
    width:1188px;
    margin:-10px auto 0;

    &-lefIndex {
      float:left;
      width: 873px;
      height: 178px;
      background: #FFFFFF;
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding:0 16px;

      &-amountBox {
        width: 288px;
        height: 144px;
        background: #F7F7F7;
        border-radius: 10px;
        background: #F7F7F7;
        border-radius: 10px;
        .echartsBoxone {
          height: 136px;
          margin-bottom: 12px;
          margin-right: 12px;
          position: relative;
          .bigBox {
            width: 289px;
            height: 136px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 22px 0 27px 16px;
            .leftPart{
              position: absolute;
              z-index: 20;
              text-align: left;
              p:first-child {
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #262626;
                line-height: 20px;
              }
              p:nth-child(2) {
                margin-top: 4px;
                height: 24px;
                font-size: 22px;
                font-weight: 500;
                color: #262626;
                line-height: 24px;
              }
              p:nth-child(3),p:nth-child(4) {
                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 13px;
                }
              }
              p:nth-child(3) {
                margin-top: 16px;
              }
              p:nth-child(4) {
                margin-top: 6px;
              }
            }
            .rightPart {
              .centerTxt {
                min-width: 64px;
                position: absolute;
                right: 54px;
                top: 60px;
                z-index:1000000;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p:first-child{
                  height: 34px;
                  font-size: 20px;
                  font-weight: 500;
                  color: #262626;
                  line-height: 34px;
                  span {
                    font-size: 12px;
                    color: #262626;
                    font-weight: 500;
                  }
                }
                p:nth-child(2) {
                  margin-top: 2px;
                  height: 11px;
                  font-size: 12px;
                  font-weight: 400;
                  color: #AAAAAA;
                  line-height: 11px;
                }
              }
              .content {
                width: 160px;
                height: 100px;
                position: absolute;
                top: 22px;
                right: 8px;
              }
            }
          }
        }
      }
      &-structureImprovement {
        width: 540px;
        height: 144px;
        background: #F7F7F7;
        border-radius: 10px;
        text-align: left;
        padding:14px 0 0 16px;
        &-title{
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #262626;
          line-height: 20px;
        }
        &-echartsBox{
          display: flex;
          flex-direction: row;
          .twoBox {
            width:240px;
            margin-top:15px;
            position: relative;
            .distributiveNetwork {
              height: 20px;
              font-size: 14px;
              font-weight: 500;
              color: #262626;
              line-height: 20px;
              margin-bottom: 15px;
            }
            .distributionNetworkRanking {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .title{
                height: 17px;
                font-size: 12px;
                font-weight: 500;
                color: #262626;
                line-height: 17px;
              }
              .ranking{
                height: 14px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                line-height: 14px;
                .currentRanking{
                  color:#00C4A9
                }
              }
            }
            .orgProgressbar {
              width: 240px;
              min-height: 30px;
              position: relative;
              margin-top: 8px;
              //top: 15px;
              //left: 13px;
            }
            .reachBox{
              display:flex;
              align-items: center;
              justify-content: space-between;
              .reach {
                height: 24px;
                font-size: 12px;
                font-weight: 400;
                color: #262626;
                line-height: 24px;
                margin-top: 2px;
                .redTips {
                  color: #FC5260;
                  font-size: 18px;
                  margin-left:4px;
                }
              }
              .compare {
                // height: 18px;
                font-size: 12px;
                font-weight: 400;
                color: #777777;
                display:flex;
                align-items: center;
                justify-content: center;
                line-height: 14px;
                img {
                  width: 12px;
                  height: 12px;
                  margin-left:2px;
                }
              }
            }

          }
          .twoBox:last-child{
            margin-left:30px
          }
        }
      }
    }
    &-rightIndex {
      width: 300px;
      height: 178px;
      background: #FFFFFF;
      border-radius: 10px;
      float:right;
      text-align:left;
      padding:22px 0 0 16px;
      position: relative;
      display:flex;
      &-leftTxt{
        text-align:left;
        width:150px;
        &-title {
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          color: #262626;
          line-height: 20px;
        }
        &-num{
          height: 24px;
          font-size: 22px;
          font-weight: 500;
          color: #262626;
          line-height: 24px;
          margin-top:4px;
        }
        &-compare {
          display:flex;
          align-items:center;
          margin:4px 0 18px 0;
          span {
            height: 14px;
            font-size: 12px;
            font-weight: 400;
            color: #777777;
            line-height: 14px;
          }
          img{
            width:12px;
            height:12px;
            margin-left:2px;
          }
        }
        &-indicatorIdentification{
          .greenCircle{
            border-radius:50px;
            width: 8px;
            height: 8px;
            background: #18D1BC;
            margin-right:4px;
          }
          .blueCircle{
            border-radius:50px;
            width: 8px;
            height: 8px;
            background: #1890FF;
            margin-right:4px;
          }
          .name{
            height: 17px;
            font-size: 12px;
            font-weight: 400;
            color: #777777;
            line-height: 17px;
            .proportion{
              height: 17px;
              font-size: 12px;
              font-weight: 500;
              color: #262626;
              line-height: 17px;
              margin-left:4px;
            }
          }
        }
      }
      .rightEcharts{
        //top:-220px;
        position: absolute;
        width: 220px;
        height: 220px;
        z-index: 1000;
        top: -16px;
        left: 107px
      }

    }
  }
}
</style>
<style lang="less" >
.date-style{
  z-index:999999!important;
  border: none !important;
}
.date-style .el-month-table td.current:not(.disabled) .cell:hover{
  color:#00AAA6 !important;
}
.date-style .el-month-table td.current:not(.disabled) .cell {
  color:#00AAA6 !important;
}
.date-style .el-month-table td.today .cell {
  color: #00AAA6;
}
.date-style .el-year-table td .cell:hover {
  color:#00AAA6 !important;
}
.date-style .el-year-table td.current:not(.disabled) .cell{
  color:#00AAA6 !important;
}

.date-style  .el-month-table td .cell:hover {
  color:#00AAA6 !important;
}
.date-style  .el-date-picker__header-label:hover{
  color:#00AAA6 !important;
}
.date-style  .el-picker-panel__icon-btn:hover{
  color:#00AAA6 !important;
}
.date-style .el-month-table td .cell{
  color:#262626 !important;
}
.date-style .el-month-table td.disabled .cell{
  background:#f7f5fa;
  font-weight:400;
}
.date-style .el-year-table td.disabled .cell{
  border-radius:20px;
  color:#C0C4CC!important;
}
.date-style .el-year-table td.disabled .cell:hover{
  color:#C0C4CC!important;
}
.date-style .el-month-table  td.disabled .cell{
  border-radius:20px;
  color:#C0C4CC!important;
}
.date-style .el-month-table  td.disabled .cell:hover{
  color:#C0C4CC!important;
}
</style>